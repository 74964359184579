import {
  styled,
  Tooltip as MUITooltip,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";
import React, { useRef, useState } from "react";
import styles from "./styles.module.scss";
import ClickAwayListener from "@mui/material/ClickAwayListener";

interface ITooltip {
  id?: string | undefined;
  place?: string;
  effect?: string;
  multiline?: boolean;
  globalEventOff?: string;
  arrowColor?: string;
  backgroundColor?: string;
  html?: boolean;
  offset?: string;
  content?: any;
  event?: string;
  children?: any;
}
const Tooltip = (props: ITooltip) => {
  const { children, content } = props;
  const [open, setOpen] = useState<boolean>(false);
  const ref = useRef<any>(null);
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <MUITooltip {...props} classes={{ popper: className }} ref={ref} />
    </ClickAwayListener>
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: "0px 2px 8px 0px #00000040 !important",
      fontSize: 16,
      padding: "16px",
    },
  }));

  return (
    <LightTooltip
      title={content}
      placement="bottom"
      className={styles.tooltip}
      PopperProps={{
        disablePortal: true,
      }}
      onClose={handleTooltipClose}
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      onClick={handleTooltipOpen}
      onKeyPress={(e: any) => {
        if (e.code === "Enter") {
          handleTooltipOpen();
        }
      }}
    >
      <span tabIndex={0} style={{ width: "fit-content" }}>
        {children}
      </span>
    </LightTooltip>
  );
};

export default Tooltip;
