import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { faFrown, IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import {
  faInfoCircle,
  faExclamationCircle,
} from "@fortawesome/pro-duotone-svg-icons";

type Props = {
  severity: "info" | "notice" | "severe";
  text: string;
};

export interface Notification {
  icon: IconDefinition;
  borderColor: string;
  primaryColor: string;
  secondaryColor: string;
}

type NotificationSeverity = "info" | "notice" | "severe";

export const notificationTypes: Record<NotificationSeverity, Notification> = {
  info: {
    icon: faInfoCircle,
    borderColor: "var(--color-info)",
    primaryColor: "var(--primary-black)",
    secondaryColor: "var(--color-info)",
  },
  notice: {
    icon: faExclamationCircle,
    borderColor: "var(--color-warning)",
    primaryColor: "var(--primary-black)",
    secondaryColor: "var(--color-warning)",
  },
  severe: {
    icon: faFrown,
    borderColor: "var(--color-error)",
    primaryColor: "var(--color-error)",
    secondaryColor: "var(--primary-black)",
  },
};

const NotificationBox = (props: Props) => {
  const notification = notificationTypes[props.severity];
  return (
    <Wrapper
      style={{ borderColor: notificationTypes[props.severity].borderColor }}
    >
      <FontAwesomeIcon
        icon={notificationTypes[props.severity].icon}
        color={notificationTypes[props.severity].primaryColor}
        style={{
          ["--fa-secondary-color" as any]: notification.secondaryColor,
          ["--fa-secondary-opacity" as any]: "1",
        }}
        size="2x"
      />
      <div>{props.text}</div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-width: 2px;
  border-style: solid;
  display: flex;
  background-color: white;
  padding: 10px;
  align-items: center;
  //gap: 10px;
  & > * + * {
    margin-left: 10px;
  }
`;

export default NotificationBox;
