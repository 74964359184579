//import { useTranslation } from 'react-i18next';
//const { t } = useTranslation()

export function formatMoney(number) {
  return formatRounded(number) + ",-";
}

export function formatRounded(number) {
  return toSpacedNumber(Math.round(number));
}

export function formatMoneyDec(number, removeDecimalIfZero, addDashIfDecZero) {
  let formattedDecimal = formatDecimal(
    toSpacedNumber(parseFloat(Math.round(number * 100) / 100).toFixed(2))
  );

  if (removeDecimalIfZero) {
    formattedDecimal = formattedDecimal.replace(/,00$/, "");
  } else if (addDashIfDecZero) {
    formattedDecimal = formattedDecimal.replace(/,00$/, ",-");
  }

  return formattedDecimal;
}

export function formatListItemPrice(number) {
  let newNumber = parseFloat(Math.round(number * 100) / 100).toFixed(2);

  let formatted = formatMoneyDec(number);
  if (parseFloat(newNumber) > 0) {
    return "+ " + formatted;
  } else {
    return formatted.replace("-", "- ");
  }
}

export function formatDecimal(number) {
  return number.toString().replace(".", ",");
}

export function formatDecimalRounded(number) {
  return formatDecimal(parseFloat(number).toFixed(2));
}

export function formatOreDec(number) {
  return formatDecimal(
    toSpacedNumber(parseFloat(Math.round(number * 10000) / 100).toFixed(2))
  );
}

export function toSpacedNumber(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function yesNo(value) {
  return value ? "Ja" : "Nei";
}

export function formatPeriod(value, period) {
  switch (period) {
    case "days":
      return value + " dager";
    case "months":
      const label = value === 1 ? "måned" : "måneder";
      return value + " " + label;
    case "years":
      return value + " år";
    default:
      return value + " måneder";
  }
}

export function formatPeriodShort(value, period) {
  if (value > 0) {
    switch (period) {
      case "days":
        return value === 30 ? 1 + "mnd" : value + " dager";
      case "months":
        return value + " mnd";
      case "years":
        return value * 12 + " mnd";
      default:
        return value + " mnd";
    }
  }
  return "0";
}

export function removeHtmlTags(html) {
  if (html != null) {
    return html.replace(/<(.|\n)*?>/g, "");
  }
  return html;
}

export function formatPeriodFrequency(value, period) {
  if (value === 0) {
    return "Uregelmessig";
  } else if (value > 0) {
    switch (period) {
      case "days":
        return value === 30 ? "Hver måned" : `Hver ${value}. dag`;
      case "months":
        switch (value) {
          case 1:
            return "Hver måned";
          case 2:
            return "Annen hver måned";
          case 3:
            return "Hver tredje måned";
          case 4:
            return "Kvartalsvis";
          case 5:
            return "Hver femte måned";
          case 6:
            return "Hvert halvår";
          case 7:
            return "Hver syvende måned";
          case 8:
            return "Hver åttende måned";
          case 9:
            return "Hver niende måned";
          case 10:
            return "Hver tidende måned";
          case 11:
            return "Hver ellevte måned";
          case 12:
            return "En gang i året";
          default:
            return `Hver ${value}. måned`;
        }
      case "years":
        switch (value) {
          case 1:
            return "En gang i året";
          default:
            return `Hvert ${value}. år`;
        }
      default:
        return "0";
    }
  }
  return "0";
}
