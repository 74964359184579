import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import styles from "./styles.module.scss";
import NotificationBox from "components/NotificationBox/NotificationBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare, faMinus, faPlus,} from "@fortawesome/pro-regular-svg-icons";
import {Paragraph} from "components/Typography/Paragraph/Paragraph";
import {FlexLayout} from "components/Layouts/FlexLayout";
import ReactTooltip from "react-tooltip";
import {displayPrice, dotToComma, kronerToOre, onlyThreeDecimals, priceEnding,} from "utils/priceUtils";
import {CalculationsTableDetails} from "./CalculationsTableDetails";
import {format} from 'date-fns'
import {nb} from "date-fns/locale";
import {PriceType, ProductType} from "shared/types";

interface Props {
  product: any;
}

export const CalculationsTable = ({ product }: Props) => {
  const { t } = useTranslation();
  const currentMonth = format(new Date(), 'MMMM', { locale: nb });

  const [showDetails, setShowDetails] = useState<boolean>(false);
  const isFixed = product.productType === ProductType.FIXED ||
      ((product.productType === ProductType.OTHER || product.productType === ProductType.PLUS) &&
          product.priceType === PriceType.KW);
  const isVariable = product.productType === ProductType.VARIABLE;
  const isSpot =
    product.productType === ProductType.HOURLY_SPOT ||
    product.productType === ProductType.SPOT ||
    (product.productType === ProductType.OTHER &&
      product.priceType === PriceType.SPOT);

  const getCorrectPriceForProduct = () => {
    if (isSpot)
      return `${dotToComma(onlyThreeDecimals(kronerToOre(product.finalAddonPrice)))} ${t("orePerKwtLarge")}`;
    else if (product.productType === ProductType.PLUS) {
      if (product.priceType === PriceType.SPOT) {
        return displayPrice(product.finalAddonPrice, '', true)
      }
      else if (product.priceType === PriceType.KW) {
        return displayPrice(product.finalKwPrice, '', true)
      }
    }
    else {
      return `${dotToComma(onlyThreeDecimals(kronerToOre(product.finalKwPrice)))} ${t("orePerKwtLarge")}`;
    }
  };

  useEffect(() => {
    if (showDetails) {
      ReactTooltip.rebuild();
    }
  }, [showDetails]);

  const productWithPriceInfo = !(
    product.priceType === PriceType.FORESPORSEL ||
    product.productType === ProductType.PURCHASE ||
    product.priceType === PriceType.ETTERSKUDDVIS);

  const displayWarning = () => {
    const severity = "severe";

    if (product.productType === ProductType.PURCHASE) {
        return <NotificationBox severity={severity} text={t("purchaseNoPriceWarning")}/>;
    } else if (product.priceType === PriceType.ETTERSKUDDVIS) {
        return <NotificationBox severity={severity} text={t("postPeriodPricedWarning")}/>;
    } else if (product.priceType === PriceType.FORESPORSEL) {
        return <NotificationBox severity={severity} text={t("priceAtRequestWarning")}/>;
    }
  };

  function displayAdditionalInfoLink() {
    return <a
        className={styles.showDetailsButton}
        tabIndex={0}
        href={
          "https://www.nve.no/reguleringsmyndigheten/kunde/stroem/spoersmaal-og-svar-om-stroemstoette-for-hoeye-stroempriser/stroemstoette-for-husholdninger/"
        }
        target="_blank"
        rel="noopener noreferrer"
    >
      <Paragraph size={12} news>
        {t("readMoreGridRental")}
      </Paragraph>
      <FontAwesomeIcon
          icon={faArrowUpRightFromSquare}
          style={{verticalAlign: "middle"}}
          transform={"grow-4"}
          color="#05426A"
      />
    </a>;
  }

  const displayCalculations = () => {
    return <>
      <div className={styles.calculationContainer}>
        <FlexLayout gap={4}>
          <Paragraph size={12}>
            {isFixed || isVariable
                ? t("priceIs")
                : t("agreementAddOn", {
                  organizationName: product.organizationName,
                })}
          </Paragraph>
          <Paragraph size={14} news>{getCorrectPriceForProduct()}</Paragraph>
          {product.priceUpdatedDate != null && <Paragraph size={12}>
            ({t("priceLastUpdated")}{" "}
            {new Date(
                product.priceUpdatedDate.replace(
                    /(\d{2})\.(\d{2})\.(\d{4})/,
                    "$3-$2-$1"
                )
            ).toLocaleDateString("nb-NO", {
              month: "long",
              day: "numeric",
              year: "numeric",
            })}
            )
          </Paragraph>}
        </FlexLayout>

        <FlexLayout gap={4}>
          <Paragraph size={12}>
            {t("monthlyPrice", {
              organizationName: product.organizationName,
            })}
          </Paragraph>
          <Paragraph news size={14}>
            {dotToComma(priceEnding(onlyThreeDecimals(product.finalMonthlyFee), t))}
          </Paragraph>
        </FlexLayout>

        {product.productType === ProductType.PLUS ?
            <>
              {product.purchasePriceType === PriceType.SPOT &&
                  <FlexLayout gap={4}>
                    <Paragraph size={12}>Du selger til <b>timespot (uten mva)</b> med fast påslag på</Paragraph>
                    <Paragraph size={14} news>
                      {displayPrice(product.currentPrice.purchaseAddonPrice, '', true)}
                    </Paragraph>
                  </FlexLayout>
              }
              {product.purchasePriceType === PriceType.KW &&
                  <FlexLayout gap={4}>
                    <Paragraph size={12}>{"Du selger til fast kilowattimepris"}</Paragraph>
                    <Paragraph size={14} news>
                      {displayPrice(product.currentPrice.purchaseKwPrice, '', true)}
                    </Paragraph>
                  </FlexLayout>
              }
            </>
            :
            <FlexLayout gap={4}>
              <Paragraph size={12}>{t("pricePerMonth", {month: currentMonth})}</Paragraph>
              <Paragraph size={16} news>
                {dotToComma(priceEnding(onlyThreeDecimals(product.finalPriceMonthly), t))}
              </Paragraph>
            </FlexLayout>
        }

        <button
            className={styles.showDetailsButton}
            onClick={() => setShowDetails(!showDetails)}
        >
          <FlexLayout direction="row" verticalAlign="center">
            <FontAwesomeIcon
                icon={showDetails ? faMinus : faPlus}
                style={{verticalAlign: "middle"}}
                transform={"grow-4"}
                color="#05426A"
            />
            <Paragraph size={12} asLink>
              {showDetails ? t("hideDetails") : t("showDetails")}
            </Paragraph>
          </FlexLayout>
        </button>

        {showDetails &&
            <CalculationsTableDetails product={product} currentMonth={currentMonth}/>
        }
      </div>

      <Paragraph size={12}>{t("gridRentInformation")}</Paragraph>
      {displayAdditionalInfoLink()}
    </>
  }

  return (
      <div className={styles.calculations}>
        <FlexLayout gap={16} customStyle={{marginTop: "16px"}}>
          {productWithPriceInfo
              ? displayCalculations()
              : displayWarning()
          }
        </FlexLayout>
      </div>
  );
};
