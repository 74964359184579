import React from "react";
import styles from "./styles.module.scss";

export const FlexLayout = (props: any) => {
  const {
    children,
    gap,
    direction,
    horizontalAlign,
    verticalAlign,
    customStyle,
    onClick,
    wrap,
  } = props;
  return (
    <div
      className={styles.flexLayout}
      style={{
        gap: gap ? `${gap}px` : "",
        flexDirection: direction === "row" ? "row" : "column",
        justifyContent: horizontalAlign ? horizontalAlign : "",
        alignItems: verticalAlign ? verticalAlign : "",
        cursor: onClick ? "pointer" : "normal",
        flexWrap: wrap ? "wrap" : "nowrap",
        ...customStyle,
      }}
      onClick={() => (onClick ? onClick() : null)}
    >
      {children}
    </div>
  );
};
