import React from "react";
import styled from "styled-components";
import { RadioButtonLabel } from "styles/Forms";

interface Props {
  changeHousingType: (index: number) => void;
  selectedHousingType: number;
}

const HouseType = (props: Props) => {
  return (
    <HouseTypes>
      <RadioButtonLabel verticalTextAlignment={true}>
        <input
          onChange={() => props.changeHousingType(0)}
          type="radio"
          name="house-type"
          id={`house-type`}
          value={0}
          checked={props.selectedHousingType === 0}
          style={{ marginBottom: "8px" }}
        />
        Leilighet
      </RadioButtonLabel>
      <RadioButtonLabel verticalTextAlignment={true}>
        <input
          onChange={() => props.changeHousingType(1)}
          type="radio"
          name="house-type"
          id={`house-type2`}
          value={1}
          checked={props.selectedHousingType === 1}
          style={{ marginBottom: "8px" }}
        />
        Rekkehus
      </RadioButtonLabel>
      <RadioButtonLabel verticalTextAlignment={true}>
        <input
          onChange={() => props.changeHousingType(2)}
          type="radio"
          name="house-type"
          id={`house-type2`}
          value={3}
          checked={props.selectedHousingType === 2}
          style={{ marginBottom: "8px" }}
        />
        Enebolig
      </RadioButtonLabel>
    </HouseTypes>
  );
};

const HouseTypes = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
`;

export default HouseType;
