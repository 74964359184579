import styled from "styled-components";

interface RadioButtonProps {
  verticalTextAlignment?: boolean;
}

export const InputWrapper = styled.div`
  position: relative;
`;

export const InputUnit = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  font-family: var(--font-family-book);
`;

export const TextInput = styled.input`
  outline-offset: 0px;
  background-color: var(--light-blue);
  font-size: 1rem;
  font-family: var(--font-family-book);
  font-weight: 400;
  color: var(--primary-black);
  line-height: 1.5;
  box-shadow: 0;
  border: 0;
  padding: 0.5em 1.4em 0.5em 0.8em;
  margin: 0;
  height: fit-content;
`;

export const InputError = styled.p`
  color: white;
  background-color: #c20b26;
  padding: 1em;
  margin-top: 0;
  text-align: justify;
  width: 0;
  min-width: 100%;
`;

export const RadioButtonLabel = styled.label<RadioButtonProps>`
  font-size: 0.875rem !important;
  font-family: var(--font-family-book) !important;
  text-align: left !important;
  display: ${(props) => (!props.verticalTextAlignment ? "" : "grid")};
  justify-items: ${(props) => (!props.verticalTextAlignment ? "" : "center")};
  flex-direction: row;
  cursor: pointer;
  input {
    cursor: inherit;
    appearance: none;
    background: #fff;
    border: 1px solid #333;
    height: 26px;
    width: 26px;
    display: inline-block;
    border-radius: 100%;
    vertical-align: middle;
    position: relative;
    margin: 0.25rem 0px !important;
    margin-right: 4px !important;
    //transform: translateY(-3px) translateX(-5px);

    &::before {
      content: "";
      position: absolute;
      margin: auto;
      height: 0;
      width: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      top: 0;
    }
    &:checked::before {
      border: 7px solid transparent;
      border-radius: 100%;
      background: #00abc4;
    }
    &:focus {
      outline-offset: 0px;
      -moz-appearance: none;
      -webkit-appearance: none;
    }
  }
  input::before {
  }
  input:checked::before {
  }
`;

export const selectTheme = (theme: any) => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    primary: "#5bc0de",
    primary50: "#5bc0de",
    primary25: "var(--light-blue)",
    neutral10: "white",
    neutral20: "transparent",
  },
});

export const selectStyles = {
  control: (provided: any, state: { isFocused: any }) => ({
    ...provided,
    outline: state.isFocused ? "3px solid var(--color-focus-outline)" : "",
    border: "none",
    backgroundColor: "var(--input-bg)",
    transition: "none",
    boxShadow: "none",
    textAlign: "left",
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    padding: "0.5em 0.75em",
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    margin: "0",
    padding: "0",
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: "var(--primary-black)",
  }),
  option: (provided: any, state: { isSelected: any; selectProps: any }) => ({
    ...provided,
    borderBottom: "1px dotted var(--light-blue)",
    color: "var(--primary-black)",
    textAlign: "left",
  }),
};
