import useStore from "store/store";
import {
  CUSTOMERS_MEMBERS,
  CUSTOMERS_NEW,
  CUSTOMERS_ALL,
  CUSTOMERS_EXISTING_AND_MEMBERS,
  CUSTOMERS_EXISTING,
  CUSTOMERS_NEW_AND_EXISTING,
  CUSTOMERS_NEW_AND_MEMBERS
} from "shared/classificators";

export default function useFilterFunction() {
  const yearlyConsumption = useStore((state) => state.yearlyConsumption);
  const showWhenMembershipRequired = useStore(
    (state) => state.filterShowMembershipRequired
  );
  const showWhenNewCustomersOnly = useStore(
    (state) => state.filterShowNewCustomersOnly
  );
  const showCabinProducts = useStore(
    (state) => state.filterShowCabinProducts
  );

  return (product, searchTerm) => {
    let filterDueToCustomerType = hideDueToCustomerType(
        product.applicableToCustomersType,
        showWhenMembershipRequired,
        showWhenNewCustomersOnly);

    let filteredDueToCabinProducts =
      !showCabinProducts && product.cabinProduct === true;

    const tooLowMaxKWYear =
      product.maxKWYear && product.maxKWYear < yearlyConsumption;

    const matchesSearchTerm =
      product.productName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.organizationName.toLowerCase().includes(searchTerm.toLowerCase());

    return (
      (matchesSearchTerm && searchTerm !== "") ||
      (searchTerm === "" &&
          !filterDueToCustomerType &&
          !tooLowMaxKWYear &&
          !filteredDueToCabinProducts &&
          !product.expired)
    );
  };
}


function hideDueToCustomerType(applicableToCustomersType, showMembershipRequired, showNewCustomersOnly) {
  switch (applicableToCustomersType) {
    case CUSTOMERS_MEMBERS:
      return !showMembershipRequired;
    case CUSTOMERS_NEW:
      return !showNewCustomersOnly;
    case CUSTOMERS_ALL:
      return !showMembershipRequired;
    case CUSTOMERS_EXISTING_AND_MEMBERS:
      return !showMembershipRequired;
    case CUSTOMERS_EXISTING:
      return false;
    case CUSTOMERS_NEW_AND_EXISTING:
      return false;
    case CUSTOMERS_NEW_AND_MEMBERS:
      return !(showMembershipRequired && showNewCustomersOnly);
    default:
      return true;
  }
}
