import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { TextInput } from "styles/Forms";

interface ISearch {
  updateSearchValue: (val: string) => void;
}

const Search = ({ updateSearchValue }: ISearch) => {
  const [value, setValue] = useState<string>("");
  const change = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      updateSearchValue(value);
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [value]); //eslint-disable-line

  const handleEnterKey = async (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  return (
    <Wrapper>
      <SearchLabel htmlFor="search">Søk</SearchLabel>
      <SearchInput
        type="text"
        id="search"
        value={value}
        onChange={change}
        placeholder="Søk i strømavtaler"
        autoComplete="off"
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            handleEnterKey(e);
          }
        }}
      />

      <SearchIcon icon={faSearch} />

      {value !== "" && (
        <ClearSearchIcon icon={faTimes} onClick={() => setValue("")} />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  position: relative;
`;

const SearchLabel = styled.label`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  font-size: 1rem;
`;

const SearchInput = styled(TextInput)`
  width: 100%;
  padding: 1em 1em 1em 3em;
  font-size: 1rem;
`;

const SearchIcon = styled(FontAwesomeIcon)`
  display: block;
  position: absolute;
  transform: translateY(-50%) translateX(30%);
  top: 50%;
  font-size: 1.5rem;
`;
const ClearSearchIcon = styled(FontAwesomeIcon)`
  display: block;
  position: absolute;
  transform: translateY(-45%) translateX(30%);
  top: 50%;
  cursor: pointer;
  right: 5%;
  font-size: 2rem;
`;

export default Search;
