export const faq = [
  {
    question: "Hvorfor må jeg legge inn kommune i oversikten hos dere?",
    answer: [
      "Norge er delt inn i fem prisområder, så for å kunne vise deg prisen på strømavtalene som selges i ditt prisområde trenger vi å vite kommunen din. Det er Statnett som er ansvarlig for inndelingen av prisområdene.",
    ],
  },
  {
    question: "Hvordan vet jeg hvor mye strøm jeg bruker i året?",
    answer: [
      "Et forbruk på 16000 kWh er gjennomsnittlig forbruk i Norge og er lagt inn som standard. Du kan også justere forbruket ved å velge en annen boligtype eller legge inn forbruket ditt selv. Du finner som regel detaljert informasjon om forbruket ditt ved å logge inn på nettselskapet og/eller strømselskapet sine nettsider. Alle med en automatisk strømmåler (AMS) kan også sjekke sitt faktiske forbruk innlogget på <a href='https://www.elhub.no/'>Elhub  <svg xmlns='http://www.w3.org/2000/svg' width='0.8rem' fill='#05426a' height='0.8rem' viewBox='0 0 512 512'><path d='M304 24c0 13.3 10.7 24 24 24H430.1L207 271c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l223-223V184c0 13.3 10.7 24 24 24s24-10.7 24-24V24c0-13.3-10.7-24-24-24H328c-13.3 0-24 10.7-24 24zM72 32C32.2 32 0 64.2 0 104V440c0 39.8 32.2 72 72 72H408c39.8 0 72-32.2 72-72V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V440c0 13.3-10.7 24-24 24H72c-13.3 0-24-10.7-24-24V104c0-13.3 10.7-24 24-24H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H72z'/></svg></a>",
    ],
  },
  {
    question:
      "Hvorfor er spotprisen forskjellig på strømpris.no og på strømselskapets nettside?",
    answer: [
      "Hvis du velger “les mer og bestill” ved siden av de enkelte avtalene på strømpris.no, vil du se at utregningen tar utgangspunkt i gjennomsnittlig spotpris forrige måned fra kraftbørsen Nord Pool inkludert merverdiavgift. Strømselskapene kan bruke en annen måte for å vise deg hva avtalen din koster. De kan for eksempel ta utgangspunkt i hva spotprisen er i dag.",

      "Om man inngår en spotprisavtale så er det viktig å være klar over at spotprisen varierer time for time. Det reguleres på kraftbørsen Nord Pool. Det er også her strømselskapene hovedsakelig kjøper strømmen de selger kundene sine. Det er derfor ikke mulig å si nøyaktig hva spotprisen vil være fremover i tid.",
    ],
  },
  {
    question: "Hvorfor er ikke nettleie med i oversikten på Strømpris?",
    answer: [
      "Forskjellen på strøm og nettleie kan forklares med at du kjøper strømmen du bruker av strømselskapet, mens nettselskapet eier strømnettet og frakter strømmen hjem til deg.",

      "Du kan ikke velge bort nettselskapet du er tilknyttet. Nettleien avhenger av hvilken prismodell selskapet har og av hvor mye strøm du bruker. Strømpris har derfor valgt å kun vise strømprisen.",

      "Hvor mye nettselskapet ditt kan ta seg betalt i nettleie reguleres av Norges vassdrags- og energidirektorat (NVE). Du finner mer <a href='https://www.nve.no/reguleringsmyndigheten/kunde/nett/nettleie/'>informasjon om nettleie på nettsidene til NVE  <svg xmlns='http://www.w3.org/2000/svg' width='0.8rem' fill='#05426a' height='0.8rem' viewBox='0 0 512 512'><path d='M304 24c0 13.3 10.7 24 24 24H430.1L207 271c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l223-223V184c0 13.3 10.7 24 24 24s24-10.7 24-24V24c0-13.3-10.7-24-24-24H328c-13.3 0-24 10.7-24 24zM72 32C32.2 32 0 64.2 0 104V440c0 39.8 32.2 72 72 72H408c39.8 0 72-32.2 72-72V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V440c0 13.3-10.7 24-24 24H72c-13.3 0-24-10.7-24-24V104c0-13.3 10.7-24 24-24H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H72z'/></svg></a>",
    ],
  },
  {
    question: "Jeg finner ikke min avtale i oversikten?",
    answer: [
      "Det finnes et utall strømavtaler med ulike prismodeller, så vi anbefaler at du bruker søkefeltet for å finne din strømavtale. Du kan søke på strømselskap eller navnet på strømavtalen. Om du fortsatt ikke finner din avtale skyldes det mest sannsynlig at avtalen ikke lengre er i salg for nye kunder.",

      "Alle avtaler som er i salg skal rapporteres inn av strømselskapene. Vi anbefaler at du sjekker din side hos strømselskapet eller tar direkte kontakt om du ikke finner pris og avtalevilkår for din avtale. Da kan du sammenligne denne med andre avtaler som er for salg.",
    ],
  },
  {
    question: "Jeg finner ikke kortvarige spotprisavtaler i oversikten deres?",
    answer: [
      "Du må inn på “Filtrer søket” og velge at du vil se disse avtalene. Det er viktig å være oppmerksom på at dette hovedsakelig er kampanjeavtaler. Siden det ikke er gitt prisgaranti i en lengre periode, må man være ekstra oppmerksom på strømavtalen du blir flyttet over på etter kampanjeperioden. Ofte settes prisen opp, og glemmer man å bytte avtale, blir det fort veldig dyrt.",
    ],
  },
  {
    question:
      "Jeg har bestilt prisbevis og varsel på SMS fra dere, men ikke hørt noe fra strømselskapet?",
    answer: [
      "Du må fullføre bestillingen ved å følge lenken til strømselskapet. Prisbeviset og varsel på SMS er tjenester levert av oss, og bestiller du kun disse har du ikke inngått en avtale med strømselskapet.",

      "Prisbeviset sender vi deg på e-post. Når du får strømregningen bør du sjekke at pris og vilkår stemmer med det du ble lovet. Varsel på SMS kan du bestille ved inngåelse av spotprisavtaler. Vi vil sende deg en SMS før prisgarantien utgår, slik at du kan finne en ny billig og varig avtale. Dette er fordi mange opplever å bli flyttet over på en dyrere avtale etter endt garantiperiode.",
    ],
  },
  {
    question:
      "Utregningen deres stemmer ikke med det jeg har lagt inn under årlig forbruk?",
    answer: [
      "Strømforbruket fordeler seg ikke likt utover året. Det brukes for eksempel mye mer strøm på vinteren enn på sommeren. Derfor bruker vi en fordeling som er mer i tråd med forbruket slik det faktisk er i løpet av et år. Beregningen er basert på statistiske tall og er gjort i samråd med tilsynsmyndighet NVE.",
    ],
  },
  {
    question:
      "Prisen på avtalen hos dere stemmer ikke med prisen oppgitt på strømselskapet sin nettside?",
    answer: [
      "Strømselskapene har rapporteringsplikt til strømpris.no, og prisene som er oppgitt skal være den samme du finner på strømselskapet sine nettsider. Vi anbefaler uansett at du dobbeltsjekker prisen med det som er oppgitt på nettsiden til strømselskapet.",

      "Det er spesielt i perioder hvor markedsprisen på strøm varierer mye at strømselskapene endrer prisen på avtalene sine. Om de har lagt inn en endring på en avtale, som ikke er oppdatert på Strømpris, kan det noen ganger skyldes at vi ikke har rukket å oppdatere prisene på tjenesten.",
    ],
  },
];
