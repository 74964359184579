import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

const GlobalStyles = createGlobalStyle`
  ${normalize}

  b, strong{
    font-weight: normal;
    font-family: var(--font-family-news);
  }
`;

export default GlobalStyles;
