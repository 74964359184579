import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import {CUSTOM_ID, FIXED_ID, PLUS_ID, SPOT_PURCHASE_ID} from "shared/constants";
import styles from "./styles.module.scss";
import HeaderText from "./headerText/HeaderText";
import {format} from "date-fns";
import {nb} from "date-fns/locale";
import {PriceType} from "shared/types";

interface Props {
  productType: string;
  priceType?: string | null;
  reversedOrder: boolean;
  toggleReversedOrder: () => void;
}

function ProductTableHeader({ productType, priceType, reversedOrder, toggleReversedOrder}: Props): ReactElement {
  const { t } = useTranslation();
  const currentMonth = format(new Date(), 'MMMM', { locale: nb });

  const getLabelForTable = () => {
    switch (productType) {
      case FIXED_ID:
        return t("tableHeaders.fixedPrice");
      case CUSTOM_ID:
        return t("tableHeaders.guaranteedAgreements");
      case PLUS_ID:
        return t("tableHeaders.plusAgreementSale");
      default:
        return t("tableHeaders.companySurcharge");
    }
  };

  const getTooltipForLabel = () => {
    switch (productType) {
      case FIXED_ID:
        return t("tableHeaders.tooltips.fixedPrice");
      case CUSTOM_ID:
        return t("tableHeaders.tooltips.guaranteedAgreements");
      case PLUS_ID:
        return t("tableHeaders.tooltips.plusAgreementSale");
      default:
        return t("tableHeaders.tooltips.companySurcharge");
    }
  };

  function getLabelForTable2() {
    if (productType === PLUS_ID) {
      return t("tableHeaders.plusAgreementPurchase");
    }
    return <>
        Beregnet <br/>
        strømutgift <br/>
        for {currentMonth}
    </>
  }

  function getTooltipForTable2() {
    if (productType === PLUS_ID) {
        if (priceType === PriceType.SPOT) {
            return t("tableHeaders.tooltips.plusAgreementPurchaseSpot");
        }
        else if (priceType === PriceType.KW) {
            return t("tableHeaders.tooltips.plusAgreementPurchaseKw");
        }
    }
    return t("tableHeaders.tooltips.calculatedAverageMonthlyPrice");
  }

  return (
    <div className={styles.gridLayout}>
      <HeaderText
        label={t("organization")}
        light={productType === SPOT_PURCHASE_ID}
      />
      <HeaderText
        label={getLabelForTable()}
        tooltip={getTooltipForLabel()}
        light={productType === SPOT_PURCHASE_ID}
      />
      <HeaderText
        label={getLabelForTable2()}
        tooltip={getTooltipForTable2()}
        tooltipTitle={t("pricePerMonth", {month: currentMonth})}
        breakAllOnPhone
        light={productType === SPOT_PURCHASE_ID}
        showSortIcon={productType === PLUS_ID}
        reversedOrder={reversedOrder}
        toggleReversedOrder={toggleReversedOrder}
      />
    </div>
  );
}

export default ProductTableHeader;
