import { ReactElement } from "react";
import { EmbeddableGridHeaderRow } from "./styles";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { TooltipIcon } from "components/Icons/TooltipIcon";

function EmbeddableProductTableHeader(): ReactElement {
  const { t } = useTranslation();

  return (
    <EmbeddableGridHeaderRow>
      <div>Strømavtale</div>
      <div style={{ textAlign: "center" }}>
        Antall måneder prisen er garantert
        <span style={{ marginLeft: "3px" }}>
          <TooltipIcon
            data-tip={t("tooltips.addonPriceMinimumFixedFor")}
            data-for="main"
          />
        </span>
      </div>
      <div style={{ textAlign: "right" }}>Beregnet månedspris</div>
      <ReactTooltip id="main" place="top" effect="solid" multiline={true} />
    </EmbeddableGridHeaderRow>
  );
}

export default EmbeddableProductTableHeader;
