import {ReactElement, useEffect, useMemo, useState} from "react";
import useStore from "store/store";
import {useProductCount} from "../productCount";
import {orderProducts} from "hooks/useProducts/productOrderer";
import useFilterFunction from "../useFilterFunction";
import ProductTableContextContainer from "./ProductTableContent/ProductTableContextContainer";
import {IProducts} from "shared/types";

interface Props {
  title?: string | null;
  productType: string;
  products: any[] | undefined;
  searchTerm: string;
  priceType?: string | null;
}

function ProductTable({
  title = null,
  productType,
  products,
  searchTerm,
  priceType
}: Props): ReactElement {
  const setProductCount = useProductCount();
  const [orderedProducts, setOrderedProducts] = useState<any>([]);
  const filterShowMembershipRequired = useStore(
    (state) => state.filterShowMembershipRequired
  );
  const filterShowNewCustomersOnly = useStore(
    (state) => state.filterShowNewCustomersOnly
  );
  const filterShowCabinProducts = useStore(
    (state) => state.filterShowCabinProducts
  );

  const filterFunction = useFilterFunction();
  const filteredProducts = useMemo(() => {
    if (products) {
      const filtered = products.filter((product: IProducts) =>
        filterFunction(product, searchTerm)
      );
      setProductCount(filtered.length);
      return filtered;
    } else {
      setProductCount(0);
      return [];
    }
  }, [products, filterFunction, setProductCount, searchTerm]); //eslint-disable-line

  useEffect(() => {
    if (filteredProducts) {
      const orderProduct: any[] = orderProducts(
        filteredProducts
      );
      setOrderedProducts(orderProduct);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchTerm,
    filterShowCabinProducts,
    filterShowNewCustomersOnly,
    filterShowMembershipRequired,
  ]);

  return (
    <>
      {orderedProducts.length !== 0 && (
        <ProductTableContextContainer
          orderedProducts={orderedProducts}
          title={title}
          productType={productType}
          priceType={priceType}
          products={products}
        />
      )}
    </>
  );
}

export default ProductTable;
