import { useState } from "react";
import Select from "react-select";
import styled from "styled-components";
import { selectStyles, selectTheme } from "styles/Forms";
import useHistoricPrices from "hooks/useHistoricPrices";
import { Spinner } from "styles/Spinner";
import styles from "./styles.module.scss";
import { Paragraph } from "components/Typography/Paragraph/Paragraph";
import PricesTable from "./PricesTable";

const today = new Date();
const firstDayOfCurrentMonth = new Date(
  today.getFullYear(),
  today.getMonth(),
  1
);
firstDayOfCurrentMonth.setHours(12);

const firstYear = 2016;
let years = Array(new Date().getFullYear() - firstYear + 1)
  .fill(firstYear)
  .map((year, index) => {
    return {
      label: `${year + index}`,
      value: `${year + index}`,
    };
  })
  .reverse();

const yearsAvailable = [
  { label: "Siste 12 måneder", value: "last12" },
  ...years,
];

const HistoricPrices = () => {
  const [year, setYear] = useState("last12");
  const [activeOption, setActiveOption] = useState(yearsAvailable[0]);

  const handleChange = (value: any) => {
    setYear(value.value);
    setActiveOption(value);
  };

  const { data, isError, isLoading } = useHistoricPrices(year);
  const dataToDisplay = data ? [...data.data] : undefined;
  if (dataToDisplay && year === "last12") {
    dataToDisplay.reverse();
  }

  return (
    <>
      <HistoricPricesHeader>
        <div style={{ marginRight: "15px", marginBottom: "15px" }}>
          <h2 style={{ marginBottom: "10px" }}>Historiske strømpriser</h2>
          <Paragraph size={16}>
            Strømprisen er oppgitt i kroner pr. kWt og er inkludert mva, utenom
            prisområde Nord som er fritatt.
          </Paragraph>
        </div>
        <div style={{ width: "100%", maxWidth: "330px" }}>
          <label htmlFor="period">Velg periode</label>
          <Select
            value={activeOption}
            options={yearsAvailable}
            isMulti={false}
            isSearchable={false}
            styles={selectStyles}
            inputId="period"
            theme={selectTheme}
            onChange={handleChange}
            isClearable={false}
            hideSelectedOptions={false}
            closeMenuOnSelect={true}
          />
        </div>
      </HistoricPricesHeader>
      <div className={styles.tableContainer}>
        {isLoading && <Spinner style={{ alignSelf: "center" }} />}
        {isError && (
          <p style={{ alignSelf: "center" }}>Beklager noe gikk galt</p>
        )}
        {dataToDisplay && (
            <PricesTable data={dataToDisplay} />
        )}
      </div>
    </>
  );
};

const HistoricPricesHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 25px;
`;

export default HistoricPrices;
