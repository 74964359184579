import styled from "styled-components";
import { InputError, TextInput } from "styles/Forms";
import { BlockLink } from "styles/Buttons";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDownToBracket } from "@fortawesome/pro-regular-svg-icons";
import { getSpotpriceMsApiHost, getSpotpriceApiToken } from "../../shared/ApiUtils";
import { Paragraph } from "components/Typography/Paragraph/Paragraph";
import { FlexLayout } from "components/Layouts/FlexLayout";
import styles from "./styles.module.scss";
import { startOfMonth } from "date-fns"
import { format } from "date-fns-tz"

const today = new Date();
const todayOsloDate = format(today, "yyyy-MM-dd", { timeZone: "Europe/Oslo" });
const startDateOfCurrentMonth = format(startOfMonth(today), "yyyy-MM-dd", { timeZone: "Europe/Oslo" });
const firstAvailableDate = "2016-01-01";

const afterTodayCheck = (currentvalue: string) => {
  const value = new Date(currentvalue);
  const today = new Date();
  return value < today;
};
const earliestAvailableCheck = (valueAsString: string) => {
  const value = new Date(valueAsString);
  const earliestAvailable = new Date(2016, 0);
  return value >= earliestAvailable;
};
const endBeforeStartCheck = (
  endDateString: string,
  startDateString: string | null
) => {
  return (
    startDateString !== null &&
    new Date(endDateString) >= new Date(startDateString)
  );
};
type FormValues = {
  priceDateRangeStart: string;
  priceDateRangeEnd: string;
};

const HistoricPrices = () => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<FormValues>({
    defaultValues: {
      priceDateRangeStart: startDateOfCurrentMonth,
      priceDateRangeEnd: todayOsloDate,
    },
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const linkStart = data.priceDateRangeStart.split("-").reverse().join("-");
    const linkEnd = data.priceDateRangeEnd.split("-").reverse().join("-");

    const url = `${getSpotpriceMsApiHost()}/spotprice/hourly?start=${linkStart}&end=${linkEnd}&format=xlsx&token=${getSpotpriceApiToken()}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <FlexFormPrices onSubmit={handleSubmit(onSubmit)}>
      <DownloadHistoricPricesHeader>
        <div style={{ marginRight: "15px" }}>
          <h2 style={{ marginBottom: "16px" }}>Last ned spotprishistorikk</h2>
        </div>
      </DownloadHistoricPricesHeader>
      <div style={{ marginBottom: "16px" }}>
        <Paragraph size={14}>
          Her kan du laste ned historiske data helt tilbake til 2016.
          Historikken er i Excel-format.
        </Paragraph>
      </div>
      <FlexLayout
        direction="row"
        horizontalAlign="space-between"
        verticalAlign="flex-end"
        wrap
      >
        <label htmlFor="from" className={styles.textInput}>
          Fra
          <TextInput
            id="from"
            type="date"
            min={firstAvailableDate}
            max={todayOsloDate}
            {...register("priceDateRangeStart", {
              required: `${t("inputError.required")}`,
              validate: {
                afterToday: (v) =>
                  afterTodayCheck(v) || `${t("inputError.afterToday")}`,
                earliestAvailable: (v) =>
                  earliestAvailableCheck(v) ||
                  `${t("inputError.earliestAvailable")}`,
              },
            })}
            className="blue-input"
          />
          {errors.priceDateRangeStart && (
            <InputError>{errors?.priceDateRangeStart?.message}</InputError>
          )}
        </label>

        <label htmlFor="to" className={styles.textInput}>
          Til
          <TextInput
            id="to"
            type="date"
            min={firstAvailableDate}
            max={todayOsloDate}
            {...register("priceDateRangeEnd", {
              required: `${t("inputError.required")}`,
              validate: {
                afterToday: (v) =>
                  afterTodayCheck(v) || `${t("inputError.afterToday")}`,
                endBeforeStart: (v) =>
                  endBeforeStartCheck(v, getValues("priceDateRangeStart")) ||
                  `${t("inputError.endBeforeStart")}`,
              },
              valueAsDate: false,
            })}
            className="blue-input"
          />
          {errors.priceDateRangeEnd && (
            <InputError>{errors?.priceDateRangeEnd?.message}</InputError>
          )}
        </label>
        <Wrapper>
          <DownloadHistoricPricesButton>
            Last ned &nbsp;
            <DownloadIcon icon={faArrowDownToBracket} />
          </DownloadHistoricPricesButton>
        </Wrapper>
      </FlexLayout>
    </FlexFormPrices>
  );
};

const Wrapper = styled.div`
  display: flex;
  @media (max-width: 830px) {
    flex-direction: column;
    align-items: stretch;
    margin: 0;
    width: 100%;
  }
`;

const FlexFormPrices = styled.form`
  display: flex;
  flex-direction: column;
  legend {
    margin-bottom: 0.2em;
  }
  fieldset {
    padding: 0;
  }
`;

const DownloadHistoricPricesHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const DownloadIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
`;

const DownloadHistoricPricesButton = styled(BlockLink)`
  min-width: fit-content;
  max-width: 200px;
  height: fit-content;
  max-height: 2.5rem;
  display: flex;
  align-self: flex-start;
  justify-content: center;
  align-items: center;
  font-size: 1.125rem;
  align-self: center;
  margin-top: 1rem;
  padding: 1em;
  margin-right: 14px;
  @media (max-width: 830px) {
    margin: 16px 0 0 0;
    width: 100%;
  }
`;

export default HistoricPrices;
