import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FlexLayout } from "components/Layouts/FlexLayout";
import { Paragraph } from "components/Typography/Paragraph/Paragraph";
import styles from "./styles.module.scss";

interface IButton {
  onClick: () => void;
  text: string | JSX.Element | JSX.Element[];
  disabled?: boolean;
  tabIndex?: number;
  useDisableStyle?: boolean;
  asBackButton?: boolean;
  customStyle?: any;
}
const Button = ({
  onClick,
  text,
  disabled,
  tabIndex,
  useDisableStyle,
  asBackButton,
  customStyle,
}: IButton) => {
  return (
    <button
      onClick={onClick}
      tabIndex={tabIndex ? tabIndex : 0}
      className={`${styles.buttonStyle} ${
        useDisableStyle ? (disabled ? styles.disabled : styles.active) : {}
      }`}
    >
      <FlexLayout verticalAlign="center" direction="row">
        {asBackButton && (
          <FontAwesomeIcon
            icon={faArrowLeft}
            fontSize={24}
            className={styles.icon}
          />
        )}
        <Paragraph tabIndex={-1}>{text}</Paragraph>
      </FlexLayout>
    </button>
  );
};

export default Button;
