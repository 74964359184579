import {faArrowLeft} from "@fortawesome/pro-thin-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NoStyleButton from "components/Button/NoStyleButton";
import {FlexLayout} from "components/Layouts/FlexLayout";
import {Paragraph} from "components/Typography/Paragraph/Paragraph";
import {Trans, useTranslation} from "react-i18next";
import {
  dotToComma,
  getPurchaseDisplayPrice,
  isSpotPrice,
  kronerToOre,
  onlyThreeDecimals,
  priceEndingWithComma
} from "utils/priceUtils";
import {ActionSuccess} from "../ActionSuccess/ActionSuccess";
import styles from "./styles.module.scss";
import {PriceType, ProductType} from "shared/types";

const OrderInformation = ({ product, goBackToCalculationPage }: any) => {
  const { t } = useTranslation();

  const hasAddon = () => {
    if (
      product.productType === ProductType.PURCHASE ||
      product.priceType === PriceType.ETTERSKUDDVIS ||
      product.activeComplaint
    ) {
      return product.finalMonthlyFee > 0
        ? t("hasNoPriceBut")
        : t("hasNoPrice");
    } else if (isSpotPrice(product)) {
      if (product.finalAddonPrice === 0) {
        return "";
      }
      return ` og har et <strong>påslag på ${priceEndingWithComma(
          onlyThreeDecimals(kronerToOre(product.finalAddonPrice)),'øre per kilowattime')}</strong>`;
    } else if (product.finalKwPrice === 0) return "";
    return t("orderInformationWithAddonNonSpot", {
      addon: dotToComma(onlyThreeDecimals(kronerToOre(product.finalKwPrice))),
    });
  };

  const hasMonthlyFee = () => {
    if (product.finalMonthlyFee > 0) {
      return ` med en fast <strong>månedspris på ${priceEndingWithComma(product.finalMonthlyFee, 'kroner')}</strong>`
    }
    return "";
  };

  function hasPurchasePrice() {
    if (product.productType === ProductType.PLUS) {
      return '. ' + t("purchasePriceInfo", {
        purchasePrice: getPurchaseDisplayPrice(product),
      });
    }
  }

  function getI18nKey(product: any) {
    if (isSpotPrice(product)) {
      return "agreementOrderInformation";
    }
    else if (product.agreementTimeInMonths > 0) {
      return "agreementOrderInformationForNonSpot";
    }
    else {
      return "agreementOrderInformationForNonSpotNoAgreementTime";
    }
  }

  function setMonths(product: any) {
    if (isSpotPrice(product)) {
      return {
        months: onlyThreeDecimals(
          product.addonPriceMinimumFixedForInMonths
        )
      }
    }
    else if (product.agreementTimeInMonths > 0) {
      return {
        months: product.agreementTimeInMonths
      }
    }
  }

  return (
    <FlexLayout gap={16} direction="row">
      <ActionSuccess isSuccess={true} />
      <FlexLayout>
        <Paragraph>{t("youHaveSelected")}</Paragraph>
        <Paragraph size={12}>
          <span className={styles.newsStyle}>{product.productName}</span>{" "}
          {t("from")} {product.organizationName}
        </Paragraph>
        <Paragraph size={12}>
          <Trans
              i18nKey={getI18nKey(product)}
              values={{
                ...(setMonths(product)),
                addon: hasAddon(),
                monthlyFee: hasMonthlyFee(),
                purchasePrice: hasPurchasePrice()
              }}
          />
        </Paragraph>
        <NoStyleButton onClick={() => goBackToCalculationPage()}>
          <FlexLayout direction="row" verticalAlign="center">
            <FontAwesomeIcon
              icon={faArrowLeft}
              style={{ verticalAlign: "middle" }}
              transform={"grow-4"}
              color="#05426A"
            />
            <Paragraph asLink news size={12}>
              {t("changeAgreement")}
            </Paragraph>
          </FlexLayout>
        </NoStyleButton>
      </FlexLayout>
    </FlexLayout>
  );
};
export default OrderInformation;
