import styled from "styled-components";
import CompareProducts from "../CompareProducts/CompareProducts";
import EmptyProductListWarning from "../EmptyProductListWarning";
import ProductTable from "../ProductTable/ProductTable";
import {useContext} from "react";
import {CompareProductsContext} from "../../../contexts/useCompareProducts";
import {PriceType} from "shared/types";

interface IProductTables {
  products: any;
  selectedProductType: string;
  searchTerm: string;
  filterShowMediumSpot: boolean;
  filterShowShortSpot: boolean;
}

const ProductTables = ({
  products,
  selectedProductType,
  searchTerm,
  filterShowMediumSpot,
  filterShowShortSpot,
}: IProductTables) => {

  const { isProductTypeComparable } = useContext(
      CompareProductsContext
  );

  return (
    <Wrapper>
      <CompareProducts showCompareWarning={!isProductTypeComparable(selectedProductType)}/>
      {(selectedProductType === "spot" || searchTerm !== "") && (
        <>
          <ProductTable
            title="Prisgaranti mer enn 12 måneder"
            productType="spot"
            products={products?.spotLong}
            searchTerm={searchTerm}
          />
          {(filterShowMediumSpot || searchTerm !== "") && (
            <ProductTable
              title="Prisgaranti 6 - 12 måneder"
              productType="spot"
              products={products?.spotMedium}
              searchTerm={searchTerm}
            />
          )}
          {(filterShowShortSpot || searchTerm !== "") && (
            <ProductTable
              title="Kortvarige spotprisavtaler"
              productType="spot"
              products={products?.spotShort}
              searchTerm={searchTerm}
            />
          )}
        </>
      )}
      {(selectedProductType === "fixed" || searchTerm !== "") && (
        <>
          <ProductTable
            title="Fastpris mindre enn 1 år"
            productType="fixed"
            products={products?.fixedShort}
            searchTerm={searchTerm}
          />
          <ProductTable
            title="Fastpris 1 år"
            productType="fixed"
            products={products?.fixedMedium}
            searchTerm={searchTerm}
          />
          <ProductTable
            title="Fastpris mer enn 1 år"
            productType="fixed"
            products={products?.fixedLong}
            searchTerm={searchTerm}
          />
        </>
      )}
      {(selectedProductType === "custom" || searchTerm !== "") && (
        <>
          <ProductTable
            title="Andre avtaler (ikke anbefalt)"
            productType="custom"
            products={products?.custom}
            searchTerm={searchTerm}
          />
          <ProductTable
            title="Variabelpris"
            productType="custom"
            products={products?.customVariable}
            searchTerm={searchTerm}
          />
          <ProductTable
            title="Avtaler med bruddgebyr"
            productType="custom"
            products={products?.customWithAgreementTime}
            searchTerm={searchTerm}
          />
        </>
      )}
      {(selectedProductType === "plus" || searchTerm !== "") && (
        <>
          <ProductTable
            title={"Langvarig plussavtale med spotpris"}
            productType="plus"
            priceType={PriceType.SPOT}
            products={products?.plusSpotLong}
            searchTerm={searchTerm}
          />
          <ProductTable
              title={"Kortvarig plussavtale med spotpris"}
              productType="plus"
              priceType={PriceType.SPOT}
              products={products?.plusSpotShort}
              searchTerm={searchTerm}
          />
          <ProductTable
              title="Plussavtaler med fastpris"
              productType="plus"
              priceType={PriceType.KW}
              products={products?.plusFixed}
              searchTerm={searchTerm}
          />
        </>
      )}
      <EmptyProductListWarning searchTerm={searchTerm} />
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  //gap: 60px;
  width: 100%;
  max-width: 700px;
  h2 {
    text-align: center;
  }
`;

export default ProductTables;
