import styles from "./styles.module.scss";
import useIsMobile from "hooks/useIsMobile";
import { convertInputSizeToCorrectFormat } from "./utils";

export const Paragraph = (props: any) => {
  const {
    children: text,
    color,
    news,
    size,
    right,
    link,
    asLink,
    error,
    onClick,
    customStyle,
    wrap,
    withPreBreak,
    dashed,
    breakAllOnPhone,
    asHeading,
    textAlign,
    type,
  } = props;

  const isMobile = useIsMobile();

  const fontSize = convertInputSizeToCorrectFormat(size, !isMobile, true);
  /*const lineHeight = Math.round(
    convertInputSizeToCorrectFormat(size, !isMobile) * 1.3
  );*/

  const Type = type ? type : "p";

  if (asHeading) {
    return (
      <h1
        className={`${styles.paragraph} ${news && styles.newsStyle} ${
          error && styles.error
        } ${withPreBreak && styles.withPreBreak} ${dashed && styles.dashed} ${
          breakAllOnPhone && styles.breakAllOnPhone
        }`}
        tabIndex={-1}
        style={{
          color: link || asLink ? "#05426A" : color ? color : "",
          fontSize: size ? `${fontSize}rem` : "",
          textAlign: right ? "right" : "left",
          textDecoration: link ? "underline" : "none",
          cursor: link ? "pointer" : "normal",
          wordBreak: wrap ? "break-all" : "inherit",
          ...customStyle,
        }}
        onClick={() => onClick && onClick()}
      >
        {text}
      </h1>
    );
  }
  return (
    <Type
      className={`${styles.paragraph} ${news && styles.newsStyle} ${
        error && styles.error
      } ${withPreBreak && styles.withPreBreak} ${dashed && styles.dashed} ${
        breakAllOnPhone && styles.breakAllOnPhone
      }`}
      tabIndex={-1}
      style={{
        color: link || asLink ? "#05426A" : color ? color : "",
        fontSize: size ? `${fontSize}rem` : "inherit",
        textAlign: textAlign ? textAlign : right ? "right" : "left",
        textDecoration: link ? "underline" : "none",
        cursor: link ? "pointer" : "normal",
        wordBreak: wrap ? "break-all" : "inherit",
        ...customStyle,
      }}
      onClick={() => onClick && onClick()}
    >
      {text}
    </Type>
  );
};
