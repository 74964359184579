import {useQuery} from "react-query";
import axios from "axios";

interface IWordpressPost {
  id: number;
  title: {
    rendered: string;
  };
  content: {
    rendered: string;
  };
  excerpt: {
    rendered: string;
  };
  link: string;
  _embedded: {
    "wp:featuredmedia": {
      alt_text: string;
      link: string;
    }[];
  };
}

const fetchPosts = async (): Promise<IWordpressPost[]> => {
  let apiHost = "https://www.forbrukerradet.no/wp-json/wp/v2";
  const { data } = await axios.get(`${apiHost}/posts?tags=500&_embed`);
  return data;
};

export default function useWordpressPosts() {
  return useQuery<IWordpressPost[], Error>(["posts"], () => fetchPosts(), {
    retry: 0,
    refetchOnWindowFocus: false,
  });
}
