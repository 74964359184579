import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FlexLayout } from "components/Layouts/FlexLayout";
import Tooltip from "components/Tooltip/Tooltip";
import TooltipView from "components/Tooltip/TooltipView";
import { Paragraph } from "components/Typography/Paragraph/Paragraph";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import {useContext} from "react";
import {CompareProductsContext} from "../../../../contexts/useCompareProducts";
import {format} from "date-fns";
import {nb} from "date-fns/locale";

const CompareCardHeader = () => {
  const { t } = useTranslation();
  const {containsPlusProducts} = useContext(CompareProductsContext);
  const currentMonth = format(new Date(), 'MMMM', { locale: nb });

  return (
    <div className={styles.card}>
      <Paragraph>{t("name")}</Paragraph>
      <FlexLayout horizontalAlign="center">
        <Paragraph>{t("productType")}</Paragraph>
      </FlexLayout>
      <FlexLayout horizontalAlign="center">
        <Tooltip
          content={
            <TooltipView
              text={t("tableHeaders.tooltips.guaranteedAgreements")}
              title={t("tableHeaders.guaranteedAgreements")}
            />
          }
        >
          <div style={{ cursor: "pointer" }}>
            <FlexLayout direction="row" verticalAlign="center">
              <Paragraph dashed link>
                {t("tableHeaders.guaranteedAgreements")}
              </Paragraph>
              <FontAwesomeIcon icon={faInfoCircle} color={"#05426A"} />
            </FlexLayout>
          </div>
        </Tooltip>
      </FlexLayout>
      <Tooltip
        content={
          <TooltipView
            title={t(`tableHeaders.${containsPlusProducts ? "plusAgreementPurchase" : "companySurcharge"}`)}
            text={t(`tableHeaders.tooltips.${containsPlusProducts ? "plusAgreementPurchaseSpot" : "companySurcharge"}`)}
          />
        }
      >
        <div style={{ cursor: "pointer" }}>
          <FlexLayout direction="row" verticalAlign="center">
            <Paragraph dashed link>
              {t(`tableHeaders.${containsPlusProducts ? "plusAgreementPurchase" : "companySurcharge"}`)}
            </Paragraph>
            <FontAwesomeIcon icon={faInfoCircle} color={"#05426A"} />
          </FlexLayout>
        </div>
      </Tooltip>
        {containsPlusProducts &&
            <Tooltip
                content={
                    <TooltipView
                        title={t(`tableHeaders.${containsPlusProducts ? "plusAgreementSale" : "companySurcharge"}`)}
                        text={t(`tableHeaders.tooltips.${containsPlusProducts ? "plusAgreementSale" : "companySurcharge"}`)}
                    />
                }
            >
                <div style={{ cursor: "pointer" }}>
                    <FlexLayout direction="row" verticalAlign="center">
                        <Paragraph dashed link>
                            {t(`tableHeaders.${containsPlusProducts ? "plusAgreementSale" : "companySurcharge"}`)}
                        </Paragraph>
                        <FontAwesomeIcon icon={faInfoCircle} color={"#05426A"} />
                    </FlexLayout>
                </div>
            </Tooltip>
        }

      <FlexLayout horizontalAlign="center">
        <Paragraph>{t("standardAlertSmall")}</Paragraph>
      </FlexLayout>
        {!containsPlusProducts &&
            <Paragraph news>
                Beregnet strømutgift <br/> for {currentMonth}
            </Paragraph>
        }
      <div />
    </div>
  );
};

export default CompareCardHeader;
