import styled from "styled-components";

export const MinimalButton = styled.button`
  font-family: var(--font-family-news);
  font-size: inherit;
  width: fit-content;
  background: none;
  border: none;
  color: var(--primary-black);
  &:focus-within {
    outline-offset: 0.5em;
  }
`;

export const BlockLink = styled.button`
  font-family: var(--font-family-news);
  padding: 0.5em 1.5em;
  width: fit-content;
  color: var(--primary-black);
  background: white;
  border: 1.5px solid var(--primary-black);
  @media (hover: hover) {
    &:hover {
      background: var(--primary-black);
      color: white;
    }
  }
  &:focus {
    outline-offset: 0.5em;
    -moz-appearance: none;
    -webkit-appearance: none;
  }
`;

export const SimpleButton = styled.button`
  font-family: var(--font-family-news);
  padding: 0.5em 1.5em;
  width: fit-content;
  color: var(--primary-black);
  background: white;
  border: 1.5px solid var(--primary-black);
`;

export const BlockLinkStyledLink = styled.a`
  font-family: var(--font-family-news);
  display: block;
  text-decoration: none;
  line-height: 1.15;
  padding: 0.5em 1.5em;
  color: var(--primary-black);
  width: fit-content;
  background: white;
  text-align: center;
  border: 1.5px solid var(--primary-black);
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      background: var(--primary-black);
      color: white;
      a {
        color: white;
      }
    }
  }
`;
