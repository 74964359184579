import React from "react";
import { useAtomValue } from "jotai";
import NotificationBox from "components/NotificationBox";
import useStore from "store/store";
import { hasResultsAtom } from "./productCount";
import { useTranslation } from "react-i18next";
import useLocalGrids from "../../hooks/useLocalGrids";

const EmptyProductListWarning = ({ searchTerm }: any) => {
  const localGrid = useStore((state) => state.selectedLocalGrid);
  const productType = useStore((state) => state.selectedProductType);
  const hasResults = useAtomValue(hasResultsAtom);
  const { t } = useTranslation();
  const { data: localGrids } = useLocalGrids();
  const localGridName = localGrids?.find(
    (el: any) => el.id === localGrid
  )?.name;

  return (
    <>
      {!hasResults && (
        <NotificationBox
          severity="notice"
          text={
            searchTerm === ""
              ? `Fant ingen ${t(
                  `noResultsWarning.${productType}`
                )} i ${localGridName} kommune. Velg en annen type avtale i fanen over.`
              : "Fant ingen resultater på søket ditt. Vennligst prøv igjen."
          }
        />
      )}
    </>
  );
};

export default EmptyProductListWarning;
