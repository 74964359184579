import { ExternalLinkIcon } from "components/Icons/ExternalLinkIcon";
import PageIntro from "components/PageIntro";
import useWordpressPosts from "hooks/useWordpressPosts";
import Skeleton from "react-loading-skeleton";
import { RouteWrapper } from "styles/General";
import styles from "./styles.module.scss";
import {removeHtmlTags} from "shared/formatter";

const News = () => {
  const { data, isLoading } = useWordpressPosts();

  return (
    <RouteWrapper>
      <PageIntro title="Nyheter" />
      <div className={styles.news}>
        {isLoading && (
          <>
            {[...Array(9)].map(() => (
              <a href="/">
                <Skeleton height={200} />
                <h3>
                  <Skeleton />
                </h3>
                <p>
                  <Skeleton count={5} />
                </p>
              </a>
            ))}
          </>
        )}
        {data &&
          data.map((post) => (
            <a
              key={post.id}
              href={post.link}
              rel="noreferrer"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <img
                src={post._embedded["wp:featuredmedia"][0].link}
                alt={post._embedded["wp:featuredmedia"][0].alt_text}
              />
              <h2 className={styles.h2}>{post.title.rendered}</h2>
              <p className={styles.p}>
                <span style={{ color: "var(--primary-black)" }}>
                  {removeHtmlTags(post.excerpt?.rendered || '')}
                </span>
                <ExternalLinkIcon />
              </p>
            </a>
          ))}
      </div>
    </RouteWrapper>
  );
};

export default News;
