import { useSearchParams } from "react-router-dom";
import useProducts from "hooks/useProducts/useProducts";
import EmbeddableProductTable from "./Products/EmbeddableProductTable";
import styled from "styled-components";
import logo from "forbrukerradet_logo.svg";
import ConsumptionCalculator from "components/ConsumptionCalculator";
import PageIntro from "components/PageIntro";
import useStore from "store/store";
import { useEffect, useState } from "react";
import { Overlay } from "styles/General";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { CompareProductsContext } from "contexts/useCompareProducts";
import { TooltipContext } from "contexts/useTooltip";
import { useCompareProducts } from "hooks/useCompareProducts";
import { useTooltip } from "hooks/useTooltip";
import useLocalGrids from "../hooks/useLocalGrids";

const Embeddable = () => {
  const { trackPageView } = useMatomo();
  useEffect(() => {
    trackPageView({});
  }, [trackPageView]);

  let [searchParams] = useSearchParams();
  let search = searchParams.get("search");
  const [submitted, setSubmitted] = useState(search == null);

  const selectedLocalGrid = useStore((state) => state.selectedLocalGrid);
  const { data: localGrids } = useLocalGrids();
  const localGridName = localGrids?.find(
    (el) => el.id === selectedLocalGrid
  )?.name;
  const yearlyConsmption = useStore((state) => state.yearlyConsumption);

  const {
    data: products,
    isLoading,
    isError,
  } = useProducts(selectedLocalGrid, yearlyConsmption);

  const compareProductsHook = useCompareProducts();
  const tooltipHook = useTooltip();

  return (
    <CompareProductsContext.Provider value={compareProductsHook}>
      <TooltipContext.Provider value={tooltipHook}>
        <EmbeddableWrapper>
          {!submitted ? (
            <div style={{ width: "390px", margin: 0 }}>
              <PageIntro title="Sjekk strømprisene" />
              <ConsumptionCalculator
                onClick={() => {}}
                submitted={submitted}
                setSubmitted={setSubmitted}
                persist={false}
              />
            </div>
          ) : (
            <>
              {(isLoading || isError) && (
                <Overlay>
                  <div>
                    {isError
                      ? "Klarte ikke laste produkter."
                      : "Laster produkter"}
                  </div>
                </Overlay>
              )}
              <h2>De ti beste spotprisavtalene i markedet</h2>
              {search === null && (
                <p>
                  Listen er basert på et årlig forbruk på {yearlyConsmption} kWt
                  i {localGridName}.
                </p>
              )}
              <EmbeddableProductTable
                productType="spot"
                products={products?.spotLong}
                searchTerm={search || ""}
              />
              <StromprisLink
                href="https://strompris.no"
                target="_blank"
                rel="noreferrer"
              >
                Gå til strømpris.no for alle priser
              </StromprisLink>
            </>
          )}
          <ServiceText>
            En tjeneste levert av
            <Logo src={logo} alt="Logo" />
          </ServiceText>{" "}
        </EmbeddableWrapper>
      </TooltipContext.Provider>
    </CompareProductsContext.Provider>
  );
};

const EmbeddableWrapper = styled.div`
  padding: 15px;
  background-color: white;
  max-width: 970px;
  overflow-y: auto;
`;
const ServiceText = styled.div`
  margin-top: 35px;
  display: flex;
  align-items: center;
`;
const Logo = styled.img`
  width: 200px;
  margin-left: 10px;
`;
export const BlockLinkStyledLink = styled.a`
  font-family: var(--font-family-news);
  display: block;
  line-height: 1.15;
  padding: 10px 30px;
  color: var(--primary-black);
  max-width: 100%;
  width: fit-content;
  background: white;
  text-align: center;
  text-decoration: none;
  border: 3px solid var(--primary-black);
  cursor: pointer;
  &:hover {
    background: var(--primary-black);
    color: white;
    a {
      color: white;
    }
  }
  &:focus-visible {
    outline-offset: 10px;
    -moz-appearance: none;
    -webkit-appearance: none;
  }
`;
const StromprisLink = styled(BlockLinkStyledLink)`
  width: 100%;
  max-width: 830px;
  padding-right: 5px;
  padding-left: 5px;
`;
export default Embeddable;
