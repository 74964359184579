import PdfDownload from "./PdfDownload/PdfDownload";
import OrderInformation from "./OrderInformation/OrderInformation";
import { FlexLayout } from "components/Layouts/FlexLayout";
import SmsWarning from "./SmsWarning/SmsWarning";
import styles from "./styles.module.scss";

type Props = { product: any; goBackToCalculationPage: () => void };

const Ordering = ({ product, goBackToCalculationPage }: Props) => {
  return (
    <div className={styles.ordering}>
      <FlexLayout gap={16} customStyle={{ width: "100%" }}>
        <OrderInformation
          product={product}
          goBackToCalculationPage={() => goBackToCalculationPage()}
        />
        <PdfDownload product={product} />
        <SmsWarning product={product} />
      </FlexLayout>
    </div>
  );
};

export default Ordering;
