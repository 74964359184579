import {useQuery} from "react-query";
import axios from "axios";
import {getProductMsApiHost} from "../shared/ApiUtils";

export interface ILocalGrid {
  id: number;
  regionId: number;
  municipalityId: number;
  countyId: number;
  enabled: boolean;
  name: String;
  vatApplicable: boolean;
  ctApplicable: boolean;
  elCertificateApplicable: boolean;
}

const getLocalGrids = async () => {
  const { data } = await axios.get(
    `${getProductMsApiHost()}/strompris/local-grids`
  );
  return data;
};

export default function useLocalGrids() {
  return useQuery<ILocalGrid[], Error>("localgrids", getLocalGrids, {
    initialData: [],
    retry: 3,
    refetchOnWindowFocus: false
  });
}
