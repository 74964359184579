import styled from "styled-components";
import { LeadParagraph } from "styles/General";

type Props = {
  title: string;
  leadParagraph?: string;
};

const PageIntro = ({ title, leadParagraph }: Props) => {
  return (
    <MainHeadingWrapper>
      <h1>{title}</h1>
      {leadParagraph && <LeadParagraph>{leadParagraph}</LeadParagraph>}
    </MainHeadingWrapper>
  );
};

const MainHeadingWrapper = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  h1 {
    font-family: var(--font-family-news);
    font-size: 3.75rem;
    margin-bottom: 0px;
    font-size: clamp(30px, 8vw, 50px);
    font-weight: 300;
  }
  p {
    max-width: 700px;
  }
`;

export default PageIntro;
