import NoStyleButton from "components/Button/NoStyleButton";
import { FlexLayout } from "components/Layouts/FlexLayout";
import { Paragraph } from "components/Typography/Paragraph/Paragraph";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionSuccess } from "../ActionSuccess/ActionSuccess";
import PDFDownloadButton from "./PDFDownloadButton";
import styles from "./styles.module.scss";

interface IPdfDownload {
  product: any;
}

const PdfDownload = ({ product }: IPdfDownload) => {
  const { t } = useTranslation();
  const [skipped, setSkipped] = useState<boolean>(false);
  const [downloadSuccess, setDownloadSuccess] = useState<boolean>(false);

  return (
    <FlexLayout gap={16} direction="row">
      <ActionSuccess isSuccess={downloadSuccess} />
      <div className={styles.border}>
        {!skipped && !downloadSuccess && (
          <FlexLayout horizontalAlign={"flex-end"} direction="row">
            <NoStyleButton onClick={() => setSkipped(true)} tabIndex={0}>
              <Paragraph asLink news size={12}>
                {t("skip")}
              </Paragraph>
            </NoStyleButton>
          </FlexLayout>
        )}
        {skipped || downloadSuccess ? (
          <FlexLayout>
            {downloadSuccess
              ? t("priceProofDownloaded")
              : t("youHaveSkippedProof")}
            {!downloadSuccess && (
              <FlexLayout horizontalAlign={"flex-start"} direction="row">
                <NoStyleButton onClick={() => setSkipped(false)} tabIndex={0}>
                  <Paragraph asLink news size={12}>
                    {t("regret")}
                  </Paragraph>
                </NoStyleButton>
              </FlexLayout>
            )}
          </FlexLayout>
        ) : (
          <FlexLayout>
            <Paragraph size={16}>{t("getProofOfPrice")}</Paragraph>
            <FlexLayout>
              <Paragraph size={12}>{t("proofImportantMessage1")}</Paragraph>
              <Paragraph size={12}>{t("proofImportantMessage2")}</Paragraph>
            </FlexLayout>
            <PDFDownloadButton
              product={product}
              downloadSuccess={() => setDownloadSuccess(true)}
            />
          </FlexLayout>
        )}
      </div>
    </FlexLayout>
  );
};

export default PdfDownload;
