import {useQuery} from "react-query";
import axios from "axios";

import {classifyProducts} from "./classify";
import {IProducts} from "shared/types";
import {getProductMsApiHost} from "../../shared/ApiUtils"

export const getProductsByLocalGridId = async (
  localGridId: number, yearlyKwhConsumption?: number): Promise<IProducts> => {

  let url = `${getProductMsApiHost()}/strompris/local-grids/${localGridId}/products`;
  if (yearlyKwhConsumption) {
    url = `${url}?yearlyKwhConsumption=${yearlyKwhConsumption}`;
  }

  const { data } = await axios.get(url);

  return  classifyProducts(data);
};

export default function useProducts(selectedLocalGrid: number, yearlyKwhConsumption: number) {
  return useQuery<IProducts, Error>(
    ["products", selectedLocalGrid, yearlyKwhConsumption],
    () => getProductsByLocalGridId(selectedLocalGrid, yearlyKwhConsumption),
    {
      retry: 3,
      refetchOnWindowFocus: false,
    }
  );
}
