import React from "react";
import styles from "./styles.module.scss";
import TooltipView from "../../../Tooltip/TooltipView";
import Tooltip from "components/Tooltip/Tooltip";
import {Paragraph} from "components/Typography/Paragraph/Paragraph";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/pro-regular-svg-icons";
import {faSort} from "@fortawesome/pro-duotone-svg-icons";
import NoStyleButton from "../../../Button/NoStyleButton";

interface IHeaderText {
  label: string;
  tooltip?: string;
  tooltipTitle?: string;
  breakAllOnPhone?: boolean;
  light: boolean;
  showSortIcon?: boolean;
  reversedOrder?: boolean;
  toggleReversedOrder?: () => void;
}
const HeaderText = ({
  label,
  tooltip,
  tooltipTitle,
  breakAllOnPhone,
  light,
  showSortIcon,
  reversedOrder,
  toggleReversedOrder,
}: IHeaderText) => {
  const getLabelText = () => {
    if (!tooltip) {
      return (
          <div>
            <Paragraph news color={light ? "#fff" : "inherit"}>
              <span>{label}</span>
            </Paragraph>
          </div>
      );
    }

    return (
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <Tooltip content={<TooltipView title={tooltipTitle ? tooltipTitle : label} text={tooltip}/>}>
            <div className={styles.headerTextContainer}>
              <div className={styles.headerText}>
                <Paragraph
                    name={label}
                    withPreBreak
                    breakAllOnPhone={breakAllOnPhone}
                    color={light ? "#fff" : "inherit"}
                >
                  <span className={styles.labelSpan}>{label}</span>
                </Paragraph>
              </div>
              <div className={styles.headerText}>
                <FontAwesomeIcon
                    icon={faCircleInfo}
                    className={styles.icon}
                    color={light ? "#fff" : "#05426a"}
                />
              </div>
            </div>
          </Tooltip>
          {showSortIcon &&
              <NoStyleButton noPadding={true} onClick={toggleReversedOrder} ariaLabel={"Bytt rekkefølge på sortering"}>
                {reversedOrder
                    ? <FontAwesomeIcon
                        className={styles.icon}
                        icon={faSort}
                        fontSize={24}/>
                    : <FontAwesomeIcon
                        className={styles.icon}
                        icon={faSort}
                        fontSize={24}
                        rotation={180}/>
                }
              </NoStyleButton>
          }
        </div>
    );
  };

  return getLabelText();
};

export default HeaderText;
