import QuestionAnswer from "./QuestionAnswer";
import { useState } from "react";
import { faq } from "data/faq.js";
import { RouteWrapper, TextSection } from "styles/General";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useEffect } from "react";
import { Paragraph } from "components/Typography/Paragraph/Paragraph";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import { FlexLayout } from "components/Layouts/FlexLayout";

const FAQ = () => {
  const { t } = useTranslation();
  const { trackPageView } = useMatomo();
  useEffect(() => {
    trackPageView({});
  }, [trackPageView]);

  const [data] = useState(faq);
  return (
    <RouteWrapper style={{ maxWidth: "700px" }}>
      <FlexLayout
        horizontalAlign="center"
        verticalAlign="center"
        customStyle={{ marginTop: "1.8rem" }}
      >
        <Paragraph news size={32} asHeading>
          Ofte stilte spørsmål
        </Paragraph>
        <Paragraph textAlign="center" size={18}>
          {t("faqDescription")}{" "}
          <a
            href="https://www.forbrukerradet.no/forside/bolig/strom/"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            Forbrukerrådets nettsider <FontAwesomeIcon icon={faExternalLink} />
          </a>
        </Paragraph>
        <TextSection style={{ marginTop: "28px" }}>
          {data.map((x, index) => (
            <>
              <QuestionAnswer
                question={x.question}
                answer={x.answer}
                index={index}
                key={index}
              />
            </>
          ))}
        </TextSection>
      </FlexLayout>
    </RouteWrapper>
  );
};

export default FAQ;
