import { faFrown, faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NoStyleButton from "components/Button/NoStyleButton";
import { FlexLayout } from "components/Layouts/FlexLayout";
import Tooltip from "components/Tooltip/Tooltip";
import TooltipView from "components/Tooltip/TooltipView";
import { Paragraph } from "components/Typography/Paragraph/Paragraph";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

interface IInformationChip {
  information: string;
  tipTitle?: string;
  severe?: boolean;
  danger?: boolean;
  tipId?: string;
  tipText?: string;
  withWarning?: boolean;
  canTab?: boolean;
}
const InformationChip = ({
  information,
  tipTitle,
  severe,
  danger,
  tipId,
  tipText,
  withWarning,
  canTab,
}: IInformationChip) => {
  const { t } = useTranslation();

  const customTooltip = (
    <div>
      <FlexLayout direction="row" verticalAlign="center">
        {withWarning && (
          <FontAwesomeIcon
            icon={faInfoCircle}
            color={`var(--color-error)`}
            fontSize={24}
          />
        )}
        <Paragraph>{tipText}</Paragraph>
      </FlexLayout>
    </div>
  );

  return (
    <>
      {tipId ? (
        <Tooltip
          content={
            <TooltipView title={tipTitle} customContent={customTooltip} />
          }
        >
          <NoStyleButton noTab={true} noPadding>
            <div
              className={`${styles.informationChip} ${severe && styles.severe}`}
            >
              {danger && (
                <FontAwesomeIcon
                  icon={faFrown}
                  style={{ verticalAlign: "middle" }}
                  transform={"grow-4"}
                  color="var(--color-error)"
                  fill="var(--color-error)"
                  title={t("purchaseNoPriceWarning")}
                />
              )}
              <Paragraph size={12}>{information}</Paragraph>
            </div>
          </NoStyleButton>
        </Tooltip>
      ) : (
        <NoStyleButton noTab={!canTab} noPadding>
          <div
            className={`${styles.informationChip} ${severe && styles.severe}`}
          >
            {danger && (
              <FontAwesomeIcon
                icon={faFrown}
                style={{ verticalAlign: "middle" }}
                transform={"grow-4"}
                color="var(--color-error)"
                fill="var(--color-error)"
                title={t("purchaseNoPriceWarning")}
              />
            )}
            <Paragraph size={12}>{information}</Paragraph>
          </div>
        </NoStyleButton>
      )}
    </>
  );
};

export default InformationChip;
