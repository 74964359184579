export const convertInputSizeToCorrectFormat = (
  size: number,
  isDesktop: boolean,
  asRem?: boolean
) => {
  let fontSize = size;
  
  switch (size) {
    case 12:
      if (isDesktop) fontSize = 14;
      break;
    case 14:
      if (isDesktop) fontSize = 16;
      break;
    case 16:
      if (isDesktop) fontSize = 18;
      break;
    case 18:
      if (isDesktop) fontSize = 22;
      break;
    case 20:
      if (isDesktop) fontSize = 28;
      break;
    case 22:
      if (isDesktop) fontSize = 32;
      break;
    case 32:
      if (isDesktop) fontSize = 42;
      break;
    default:
      fontSize = isDesktop ? 16 : 14;
      break;
  }

  if (asRem) {
    return fontSize * 0.0625;
  }
  return fontSize;
};
