import {
  faCheckCircle,
  faXmarkCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NoStyleButton from "components/Button/NoStyleButton";
import { FlexLayout } from "components/Layouts/FlexLayout";
import { Paragraph } from "components/Typography/Paragraph/Paragraph";
import { CompareProductsContext } from "contexts/useCompareProducts";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

interface ICompareProductsHeader {
  product: any;
  scrollTarget: any;
}

const CompareProductsHeader = ({
  product,
  scrollTarget,
}: ICompareProductsHeader) => {
  const { t } = useTranslation();
  const {
    productsToCompare,
    addProductToCompare,
    deleteProductToCompare,
    limitReach,
  } = useContext(CompareProductsContext);

  const isAdded = () => {
    if (
      productsToCompare.find(
        (productToFind: any) =>
          productToFind.product.productId === product.productId
      )
    )
      return true;
    return false;
  };

  const addOrRemoveProduct = () => {
    if (isAdded()) {
      deleteProductToCompare(product.productId);
    } else {
      if (!limitReach()) {
        addProductToCompare(product, scrollTarget);
      }
    }
  };

  return (
    <div
      className={`${styles.compareProductsHeader} ${
        isAdded() && styles.addedProduct
      } ${limitReach() && !isAdded() && styles.limitReach}`}
    >
      <FlexLayout>
        <NoStyleButton
          onClick={() => addOrRemoveProduct()}
          disabled={limitReach() && !isAdded()}
        >
          <FlexLayout
            direction="row"
            verticalAlign="center"
            horizontalAlign="space-between"
          >
            <FlexLayout direction="row" verticalAlign="center">
              {limitReach() && !isAdded() ? (
                <FontAwesomeIcon
                  icon={faXmarkCircle}
                  className={styles.icon}
                  color={isAdded() ? "#00650A" : "#333"}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className={styles.icon}
                  color={isAdded() ? "#00650A" : "#333"}
                />
              )}
              <Paragraph color={isAdded() ? "#00650A" : null}>
                {limitReach() && !isAdded()
                  ? t("limitIsReached")
                  : isAdded()
                  ? t("addedToCompare")
                  : t("addToCompare")}
              </Paragraph>
            </FlexLayout>
            {isAdded() && (
              <div>
                <FlexLayout
                  horizontalAlign="flex-end"
                  direction="row"
                  verticalAlign="center"
                >
                  <Paragraph>{t("remove")}</Paragraph>
                  <FontAwesomeIcon
                    icon={faXmarkCircle}
                    className={styles.icon}
                    color={"#333"}
                  />
                </FlexLayout>
              </div>
            )}
          </FlexLayout>
        </NoStyleButton>
      </FlexLayout>
    </div>
  );
};

export default CompareProductsHeader;
