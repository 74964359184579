import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import styled from "styled-components";
import useIsMobile from "hooks/useIsMobile";
import { withDynamicTag } from "shared/DynamicTag";
import { Paragraph } from "./Typography/Paragraph/Paragraph";

type Props = {
  color: string;
  destination: string;
  title: string;
  description?: string;
  longDescription?: string;
  buttonText?: string;
  image?: string;
  desktop?: boolean;
  headerLevel?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
};

const NavCard = (props: Props) => {
  const isMobile = useIsMobile();

  if (isMobile || !props.desktop) {
    return (
      <NavCardWrapper to={props.destination} color={props.color}>
        <div>
          <Title tag={props.headerLevel}>{props.title}</Title>
          {props.description && (
            <Paragraph
              customStyle={{ marginTop: "10px" }}
              color="var(--primary-black)"
            >
              {props.description}
            </Paragraph>
          )}
        </div>
        <div>
          <FontAwesomeIcon
            spacing={2}
            fixedWidth
            size="2x"
            icon={faChevronRight}
            transform="right-4"
          />
        </div>
      </NavCardWrapper>
    );
  }
  return (
    <DesktopNavCardWrapper to={props.destination} color={props.color}>
      <Grid>
        {props.image ? (
          <img
            style={{
              height: "250px",
              width: "100%",
              objectFit: "cover",
              objectPosition: "100% 0%",
            }}
            src={props.image}
            alt="Strømmast"
          />
        ) : (
          <div></div>
        )}

        <BottomSection>
          <div>
            <Title>{props.title}</Title>
            <svg width="100px" height="6px">
              <Line x1="0" x2="54" y1="5" y2="5" />
            </svg>
            <Paragraph customStyle={{ marginTop: "1rem" }}>
              {props.longDescription}
            </Paragraph>
          </div>

          <div>
            <NavCardButton>
              <Paragraph>{props.buttonText}</Paragraph>
              <FontAwesomeIcon
                spacing={2}
                fixedWidth
                size="2x"
                icon={faChevronRight}
                transform="right-4"
              />
            </NavCardButton>
          </div>
        </BottomSection>
      </Grid>
    </DesktopNavCardWrapper>
  );
};

const Grid = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: min-content 1fr min-content;
`;

interface NavCardWrapperProps {
  readonly color: string;
}
const Line = styled.line`
  stroke: var(--primary-black);
  stroke-width: 4;
`;

const BottomSection = styled.div`
  padding: 19px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--primary-black);
`;
const TitleBase = styled.h2`
  margin: 0;
  font-family: var(--font-family-news);
`;

const Title = withDynamicTag(TitleBase);

const DesktopNavCardWrapper = styled(Link)<NavCardWrapperProps>`
  width: 100%;
  text-decoration: none;
  align-items: center;
  background-color: ${(props) => `var(--secondary-${props.color}-20)`};
`;

const NavCardButton = styled.div`
  background: transparent;
  width: fit-content;
  padding: 10px;
  display: flex;
  align-items: center;
  border: 4px solid white;
  cursor: pointer;
  &:hover {
    background: #ebfafb;
  }
`;

const NavCardWrapper = styled(Link)<NavCardWrapperProps>`
  width: 100%;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px;
  background-color: ${(props) => `var(--secondary-${props.color}-20)`};
  &:hover {
    background-color: ${(props) => `var(--secondary-${props.color}-10)`};
  }
`;

export default NavCard;
