export const consumptions = [
    {
      month: "1",
      percentage: "12,51",
    },
    {
      month: "2",
      percentage: "11,02",
    },
    {
      month: "3",
      percentage: "10,77",
    },
    {
      month: "4",
      percentage: "8,58",
    },
    {
      month: "5",
      percentage: "6,66",
    },
    {
      month: "6",
      percentage: "4,97",
    },
    {
      month: "7",
      percentage: "4,63",
    },
    {
      month: "8",
      percentage: "4,96",
    },
    {
      month: "9",
      percentage: "5,98",
    },
    {
      month: "10",
      percentage: "7,85",
    },
    {
      month: "11",
      percentage: "9,91",
    },
    {
      month: "12",
      percentage: "12,15",
    },
  ];