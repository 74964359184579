import { periodToMonths } from "shared/util";
import {IProducts, PriceType, ProductType} from "shared/types";
export const classifyProducts = (data: any): IProducts => {
  let classifiedProducts: IProducts = {
    spotLong: [],
    spotMedium: [],
    spotShort: [],
    fixed: [],
    fixedShort: [],
    fixedMedium: [],
    fixedLong: [],
    custom: [],
    customWithAgreementTime: [],
    customVariable: [],
    plusSpotShort: [],
    plusSpotLong: [],
    plusFixed: []
  };

  data.forEach((product: any) => {
    if (
      product.productType === ProductType.SPOT ||
      product.productType === ProductType.HOURLY_SPOT
    ) {
      let productTermsFixedFor = periodToMonths(
        product.addonPriceMinimumFixedFor,
        product.addonPriceMinimumFixedForUnits
      );
      if (productTermsFixedFor < 6) {
        classifiedProducts.spotShort.push(product);
      } else if (productTermsFixedFor >= 6 && productTermsFixedFor < 12) {
        classifiedProducts.spotMedium.push(product);
      } else {
        classifiedProducts.spotLong.push(product);
      }
    } else if (product.productType === ProductType.FIXED) {
      let agreementTime = periodToMonths(
        product.agreementTime,
        product.agreementTimeUnits
      );
      if (agreementTime < 12) {
        classifiedProducts.fixedShort.push(product);
      } else if (agreementTime === 12) {
        classifiedProducts.fixedMedium.push(product);
      } else {
        classifiedProducts.fixedLong.push(product);
      }
    } else if (
      product.productType === ProductType.PURCHASE ||
      product.productType === ProductType.OTHER
    ) {
      if (product.agreementTime && product.agreementTime !== 0) {
        classifiedProducts.customWithAgreementTime.push(product);
      } else {
        classifiedProducts.custom.push(product);
      }
    } else if (product.productType === ProductType.VARIABLE) {
      classifiedProducts.customVariable.push(product);
    }
    else if (product.productType === ProductType.PLUS) {
      if (product.priceType === PriceType.SPOT) {
        let productTermsFixedFor = periodToMonths(
            product.addonPriceMinimumFixedFor,
            product.addonPriceMinimumFixedForUnits
        );
        if (productTermsFixedFor < 12) {
          classifiedProducts.plusSpotShort.push(product);
        }
        else {
            classifiedProducts.plusSpotLong.push(product);
        }
      }
      else if (product.priceType === PriceType.KW) {
        classifiedProducts.plusFixed.push(product);
      }
    }
  });
  return classifiedProducts;
};
