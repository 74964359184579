import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {isProductSpotprice} from "shared/classificators";
import {formatMoneyDec, formatOreDec, formatRounded,} from "shared/formatter";
import styles from "./styles.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight,} from "@fortawesome/pro-regular-svg-icons";
import {DetailsTable} from "../styles";
import {Paragraph} from "components/Typography/Paragraph/Paragraph";
import {FlexLayout} from "components/Layouts/FlexLayout";
import {consumptions} from "./utils";
import Tooltip from "components/Tooltip/Tooltip";
import TooltipView from "components/Tooltip/TooltipView";
import NoStyleButton from "components/Button/NoStyleButton";
import {ProductType} from "shared/types";

interface Props {
  product: any;
  currentMonth: string;
}

export const CalculationsTableDetails = ({ product, currentMonth }: Props) => {
  const { t } = useTranslation();
  const customTip = () => {
    return (
      <>
        <FlexLayout>
          <Paragraph news>
            {t("tooltips.consumptionCalculations.title")}
          </Paragraph>
          <Paragraph size={12}>
            {t("tooltips.consumptionCalculations.text1")}
          </Paragraph>
          <Paragraph size={12}>
            {t("tooltips.consumptionCalculations.text2")}
          </Paragraph>
          <div className={styles.monthLayout}>
            <FlexLayout
              direction="row"
              gap={16}
              customStyle={{ width: "100%" }}
            >
              <FlexLayout customStyle={{ width: "fit-content" }}>
                <Paragraph size={12} news>
                  {t("monthsLarge")}
                </Paragraph>
                <Paragraph
                  size={12}
                  news
                  customStyle={{ whiteSpace: "nowrap" }}
                >
                  {t("consumptionInPercentage")}
                </Paragraph>
              </FlexLayout>
              {consumptions.map((month: any, index: number) => {
                return (
                  <div key={month}>
                    <div className={styles.verticalLine} aria-label="hidden" />
                    <FlexLayout>
                      <Paragraph size={12}>
                        {t(`monthNumber.${month.month}`)}
                      </Paragraph>
                      <Paragraph size={12}>{`${t(
                        month.percentage
                      )}%`}</Paragraph>
                    </FlexLayout>
                    {index === consumptions.length - 1 && (
                      <div className={styles.invisible} />
                    )}
                  </div>
                );
              })}
            </FlexLayout>
          </div>
          <FlexLayout
            direction="row"
            verticalAlign="center"
            horizontalAlign="flex-end"
          >
            {t("canScroll")} <FontAwesomeIcon icon={faChevronRight} />
          </FlexLayout>
        </FlexLayout>
      </>
    );
  };

  function spotPriceRows() {
    return <>
      <tr>
        <th>
          <Paragraph size={12}>
            {t("detailsSpotPriceEstimate")}
          </Paragraph>
        </th>
        <td>
          <FlexLayout horizontalAlign="flex-end">
            <Paragraph size={12} right>
              {formatOreDec(product.finalKwPrice)}{" "}
              {t("orePerKwt")}
            </Paragraph>
          </FlexLayout>
        </td>
      </tr>

      <BoldRow>
        <th>
          <Paragraph size={12} news>
            + Påslag til {product.organizationName}
          </Paragraph>
        </th>
        <td>
          <Paragraph size={12} right news>
            {formatOreDec(product.finalAddonPrice)}{" "}
            {t("orePerKwt")}
          </Paragraph>
        </td>
      </BoldRow>

      <tr>
        <th>
          <Paragraph size={12}>
            {t("totalSpotPricePlusAddon")}
          </Paragraph>
        </th>
        <td>
          <Paragraph size={12} right>
            {formatOreDec(product.electricityPerKwh)}{" "}
            {t("orePerKwt")}
          </Paragraph>
        </td>
      </tr>
    </>;
  }

  function fixedPriceRows() {
    return <BoldRow>
      <th>
        <Paragraph size={12} news>
          {t("detailsElectricityPrice")}
        </Paragraph>
      </th>
      <td>
        <Paragraph size={12} right news>
          {formatOreDec(product.finalKwPrice)}{" "}
          {t("orePerKwt")}
        </Paragraph>
      </td>
    </BoldRow>;
  }

  function monthlyElectricityCostRow() {
    return <tr>
      <th>
        <Paragraph size={12}>
          {t("caConsumptionPerMonth")}{" "}
          <Tooltip
              content={
                <TooltipView customContent={customTip()}/>
              }
          >
            <NoStyleButton noPadding noTab>
                              <span className={styles.linkStyle}>
                                {formatRounded(product.monthlyConsumption)} kWt
                              </span>
            </NoStyleButton>
          </Tooltip>{" "}
          * {formatOreDec(product.electricityPerKwh)} øre
        </Paragraph>
      </th>
      <td>
        <Paragraph size={12} right>
          {formatMoneyDec(
              product.electricityMonthlyConsumptionPrice
          )}{" "}
          {t("nokSmall")}
        </Paragraph>
      </td>
    </tr>;
  }

  function whitespaceRow() {
    return <tr>
      <td>&nbsp;</td>
    </tr>;
  }

  function fixedMonthlyCostRow() {
    return <UnderlinedBoldRow>
      <th>
        <Paragraph size={12} news>
          {t("amountPerMonth", {
            organizationName: product.organizationName,
          })}
        </Paragraph>
      </th>
      <td>
        <Paragraph size={12} right>
          {formatMoneyDec(product.fixedMonthlyElectricityPrice)}{" "}
          {t("nokSmall")}
        </Paragraph>
      </td>
    </UnderlinedBoldRow>;
  }

  function estimatedTotalRow() {
    return <ResultRow>
      <th>
        <Paragraph size={14} news>
          {t("pricePerMonth", { month: currentMonth })}
        </Paragraph>
      </th>
      <td
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
      >
        <Paragraph size={14} news right>
          {formatMoneyDec(product.fullMonthlyElectricityPrice)}{" "}
          {t("nokSmall")}
        </Paragraph>
      </td>
    </ResultRow>;
  }

  function doubleUnderlineRow() {
    return <tr>
      <th className={styles.horizontalLine}></th>
      <td className={styles.horizontalLine}></td>
    </tr>;
  }

  function showPlusDisclaimer() {
    return <tr>
      <th>
        <Paragraph size={12} customStyle={{marginTop: "10px", fontWeight: "bold"}}>
          {t("plusCalculationDisclaimer")}
        </Paragraph>
      </th>
    </tr>;
  }

  return <Calculations>
    <tbody>
    {isProductSpotprice(product)
        ? spotPriceRows()
        : fixedPriceRows()
    }
    {whitespaceRow()}
    {monthlyElectricityCostRow()}
    {fixedMonthlyCostRow()}
    {estimatedTotalRow()}
    {doubleUnderlineRow()}
    {product.productType === ProductType.PLUS &&
      showPlusDisclaimer()
    }
    </tbody>
  </Calculations>
};

const Calculations = styled(DetailsTable)`
  th {
    font-family: var(--font-family-book);
    font-weight: 400;
    font-size: 0.75rem;
  }
  td {
    text-align: right;
    font-family: var(--font-family-book);
    white-space: nowrap;
    font-weight: 100;
    font-size: 0.75rem;
  }
`;

const Underlined = styled.tr`
  border-bottom: 1px solid var(--primary-black);
`;

const BoldRow = styled.tr`
  td,
  th {
    font-family: var(--font-family-news);
  }
`;

const UnderlinedBoldRow = styled.tr`
  border-bottom: 1px solid var(--primary-black);
  td,
  th {
    font-family: var(--font-family-news);
  }
`;

const ResultRow = styled(Underlined)`
  td,
  th {
    font-family: var(--font-family-news);
    padding-top: 0.7rem;
  }
`;
