import {PriceType, ProductType} from "shared/types";

export const isSpotPrice = (product: any) => {
  return product.productType === ProductType.HOURLY_SPOT ||
  product.productType === ProductType.SPOT ||
  ((product.productType === ProductType.OTHER || product.productType === ProductType.PLUS) &&
      product.priceType === PriceType.SPOT);
};

export const isNoPrice = (product: any) => {
  return product.productType === ProductType.PURCHASE ||
      product.priceType === PriceType.ETTERSKUDDVIS ||
      product.priceType === PriceType.FORESPORSEL;
};

export const effectivePriceOnFixed = (
  product: any,
  yearlyConsumption: number
) => {
  return (
    product.finalPriceAddon + (product.finalMonthlyFee * 12) / yearlyConsumption
  );
};

export const onlyThreeDecimals = (number: number) => {
  return Math.round((number + Number.EPSILON) * 1000) / 1000;
};

export const oreToKroner = (price: number) => {
  return price / 100;
};

export const kronerToOre = (price: number) => {
  return price * 100;
};

export const getPricePrefix = (price: number) => {
  if (price < 0) {
    return "-";
  }
  else {
    return "+";
  }
}

function getKrSuffix(detailedSuffix: boolean, priceInKr: number) {
  if (!detailedSuffix) {
    return 'kr/kWt';
  }
  else if (priceInKr === 1) {
    return 'krone per kilowattime';
  }
  else {
    return 'kroner per kilowattime';
  }
}

export function displayPrice(priceInKr: number, prefix = '', detailedSuffix = false) {
  const krSuffix = getKrSuffix(detailedSuffix, priceInKr);
  const oreSuffix = detailedSuffix ? 'øre per kilowattime' : 'øre/kWt';

  if (priceInKr >= 1) {
    return `${prefix}${dotToComma(onlyThreeDecimals(priceInKr))} ${krSuffix}`;
  } else {
    return `${prefix}${dotToComma(onlyThreeDecimals(kronerToOre(priceInKr)))} ${oreSuffix}`;
  }
}

export function displaySpotPrice(priceInKr: number) {
  const pricePrefix = getPricePrefix(priceInKr)

  if (priceInKr < 0) {
    priceInKr = priceInKr * -1;
  }

  return displayPrice(priceInKr, `timespot ${pricePrefix} `);
}

export function getPurchaseDisplayPrice(product: any) {
  if (product.purchasePriceType === PriceType.SPOT) {
    return displaySpotPrice(product.currentPrice.purchaseAddonPrice)
  }
  else if (product.purchasePriceType === PriceType.KW) {
    return displayPrice(product.currentPrice.purchaseKwPrice)
  }
}

export const priceEnding = (price: number, t: any) => {
  if (price % 1 !== 0) {
    return `${price} ${t("nokSmall")}`;
  }
  return `${price}${t("nokLarge")}`;
};

export const priceEndingWithComma = (price: number, priceSuffix: string) => {
  const priceWithComma = dotToComma(price);
  if (price % 1 !== 0) {
    return `${priceWithComma} ${priceSuffix}`;
  }
  return `${priceWithComma},- ${priceSuffix}`;
};

export const dotToComma = (price: number | string) => {
  const asString = price.toString();
  return asString.replace(".", ",");
};
