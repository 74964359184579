export const terms = [
  {
    term: "Akonto-fakturering",
    description: [
      "Akonto er det samme som delvis “forskuddsbetaling”. Dette betyr at leverandøren fakturerer halvparten basert på faktisk pris og forbruk, mens den andre halvdelen vil være basert på estimert pris og forbruk. Har du betalt for mye, får du penger igjen. Og omvendt må du betale om du har betalt for lite. Akonto/forskuddsbetaling anbefales ikke, da det ofte beregnes et for høyt forskudd. Går strømselskapet konkurs, taper du pengene som er forskuddsbetalt.",
    ],
  },
  {
    term: "AMS – Avanserte måle- og styringssystemer",
    description: [
      "AMS er en forkortelse for avanserte måle- og styringssystemer. Smarte strømmålere inngår i AMS, og gir strømkunder bedre informasjon om strømforbruket, mer nøyaktig avregning, automatisk avlesning av måler og mulighet for automatisk styring av forbruket. Alle strømmålere skal leses av minimum én gang i året, men med AMS-måler trenger du ikke lenger lese av måleren.",
    ],
  },
  {
    term: "Avregning",
    description: [
      "Når nettselskapet eller strømsalgselskapet beregner hva du må betale for nettleie og strømforbruk, lager de en avregning, enten basert på faktisk forbruk eller estimert forbruk.",
    ],
  },
  {
    term: "Betaling i perioden",
    description: [
      "Ved betaling i perioden vil én del av fakturabeløpet være basert på faktisk pris og forbruk, mens den andre delen vil være basert på estimert pris og forbruk (se akonto). Dette anbefales ikke, da det ofte beregnes et for høyt forskudd. Går strømselskapet konkurs, taper du pengene som er forskuddsbetalt.",
    ],
  },
  {
    term: "Det lokale eltilsyn (DLE)",
    description: [
      "E-verk og nettselskaper er pålagt å føre tilsyn innenfor sitt område. Trenger du informasjon om strømnettet der du bor i forbindelse med bygging e.l. eller oppstår det strømbrudd, vil du få informasjon ved å kontakte det e-verket eller nettselskapet du tilhører.",
    ],
  },
  {
    term: "eFaktura",
    description: [
      "Ved å inngå avtale om eFaktura får du fakturaen forhåndsutfylt direkte i nettbanken, men du kan fortsatt endre på forfallsdato eller kontonummer hvor regningen skal belastes. Med eFaktura slipper du fakturagebyr.",
    ],
  },
  {
    term: "Elhub",
    description: [
      "Elhub er en tjeneste utviklet av statnett som skal sørge for effektiv utveksling av måleverdier og kundeinformasjon. Den vil gjøre det lettere å bytte strømsalgselskap, avregne og fakturere nettleie og strømsalg. Med Elhub får du oversikt over strømforbruket ditt og mulighet til å bruke strøm på en mer fleksibel, effektiv og miljøbesparende måte. Det vil også gjøre det mulig å bli plusskunde ved å produsere egen strøm. Du kan logge inn i Elhub via ditt strømselskap.",
    ],
  },
  {
    term: "Elsertifikat",
    description: [
      "Elsertifikater er en støtteordning med hensikt å stimulere til økt produksjon av fornybar strøm. Forbrukerne betaler for denne ordningen ved at strømselskapene legger kostnaden inn i strømprisen. Les mer om elsertifikater på Norges vassdrags- og energidirektorats nettsider.",
    ],
  },
  {
    term: "Energiattest",
    description: [
      "Skal du selge eller leie ut boligen din, trenger du en energiattest. Energiattesten skaffer du deg ved å logge inn på energimerking.no. Energimerkekalkulatoren gir deg samme informasjonen samtidig som du har mulighet til å sjekke hvor mye du kan forbedre energimerket for boligen din ved å installere varmepumpe, forbedre ventilasjonsanlegget eller etterisolere loftet.",
    ],
  },
  {
    term: "E-postfaktura",
    description: [
      "Har du sagt ja takk til å få fakturaen tilsendt på e-post, mottar du en e-postfaktura. Den inneholder all betalingsinformasjon du trenger for å få betalt regningen, men du må selv legge inn betalingsinformasjonen i nettbanken eller betale regningen i banken.",
    ],
  },
  {
    term: "Etterskuddsbetaling",
    description: [
      "Ved etterskuddsbetaling blir du fakturert av leverandøren for foregående periode, basert på faktisk pris og forbruk. Forbrukerrådet anbefaler etterskuddsbetaling.",
    ],
  },
  {
    term: "Fastbeløp",
    description: [
      "For noen strømavtaler må du betale et fastbeløp uavhengig av hvor stort forbruk du har. Fastbeløpet kan påløpe som gebyr per år, per måned eller per dag. Fastbeløpet er inkludert i beregningen av strømkostnaden du får presentert på strømpris.no.",
    ],
  },
  {
    term: "Fast månedspris",
    description: [
      "Kalles også mobilprising av strøm. Dette betyr at du betaler for en viss mengde strøm om året, f.eks. 10.000 kWt/år, og for dette betaler du en avtalt pris hver måned. Bruker du mindre strøm enn avtalt, blir strømmen veldig dyr. Det samme gjelder om du bruker mer.",
    ],
  },
  {
    term: "Fastpris",
    description: [
      "Fastprisavtale er en avtale om en fast pris på strømmen over en periode, for eksempel et år. Strømregningen din vil være månedsforbruk multiplisert med fastprisen, så regningen vil være større i vintermånedene. Fastpris må ikke forveksles med “fast månedspris” eller “mobilprising av strøm”. En fastprisavtale gir deg fordelen av å ha en forutsigbar strømregning. På strømpris.no vil du finne en egen liste med fastprisavtaler de ulike strømselskapene tilbyr.",
    ],
    ref: "fixed",
    mostUsed: true
  },
  {
    term: "Felles faktura",
    description: [
      "Norges vassdrags- og energidirektorat (NVE) har innført regler som gjør at alle strømselgere skal tilby én felles faktura for strøm og nettleie. Ordningen kalles gjennomfakturering, og betyr at strømkundene enkelt kan bytte strømsalgselskapet – uten å måtte gå fra en til to fakturaer.",
    ],
  },
  {
    term: "Forbruksavgift",
    description: [
      "Det er nettselskapet som fakturerer deg for forbruksavgiften/elavgift. Avgiften er inkludert i fakturaen du får fra nettselskapet og nettselskapet har ansvar for at avgiften innbetales til Tollvesenet. Avgiftssatsen fremkommer i Stortingets årlige vedtak om forbruksavgift på elektrisk kraft § 1. Alle husholdninger i Finnmark og Karlsøy, Kvænangen, Kåfjord, Lyngen, Nordreisa, Skjervøy og Storfjord (tiltakssonen) i Nord-Troms er fritatt for denne avgiften.",
    ],
  },
  {
    term: "Forbruksprofil blant nettselskap / Justert innmatningsprofil (JIP)",
    description: [
      "Ved avregning for kunder som ikke timesmåles, stiller NVE krav om at nettselskapet skal benytte justert innmatningsprofil (JIP), hvor JIP representerer gjennomsnittsprofilen for alle kunder som er tilknyttet nettet i ditt nettområde, og som ikke måles time for time. Ved fakturering av nettleiens variable ledd er faktureringen basert på forbruksprofilen (JIP) som nettselskapet har laget for ditt nettområde. Har nettselskapet ikke mottatt melding om målerstand fra deg, bruker de denne profilen, samt historiske data for anlegget ditt for å beregne hvor mye du skal betale.",
    ],
  },
  {
    term: "Forbruksprofil blant strømselskap",
    description: [
      "Strømselskapene står fritt til å bestemme om de vil bruke den samme profilen som nettselskapet, eller om de vil lage sin egen forbruksprofil basert på et gjennomsnitt av nettselskapenes forskjellige forbruksprofiler i de områdene strømselskapene har kunder. Men dersom strømsalgselskapet ikke vil benytte den samme profilen som nettselskapet ditt, har de plikt til å opplyse om hvilken profil de bruker for å beregne fordelingen av forbruket (§ 6 i forskrift av 7. juni 1996 nr 666 om prisopplysning ved transport og salg av elektrisk kraft).",
    ],
  },
  {
    term: "Forskuddsvis fakturering",
    description: [
      "Forskuddsvis fakturering innebærer at leverandøren fakturerer forskuddsvis basert på estimert pris og forbruk for kommende fakturaperiode. Du betaler med andre ord før du har brukt strømmen, og kan tape penger dersom selskapet går konkurs. Husk at selv om du betaler på forskudd, skal du få tilbakebetalt forskjellen mellom estimert forbruk og faktisk forbruk ved at leverandør justerer neste regning eller ved årsavregningen.",
    ],
  },
  {
    term: "Gjennomfakturering",
    description: [
      "Norges vassdrags- og energidirektorat (NVE) har innført regler som gjør at alle strømselgere kan tilby én felles faktura for strøm og nettleie. Det betyr at strømkundene enkelt kan bytte strømsalgselskap – uten å måtte gå fra en til to fakturaer.",
    ],
  },
  {
    term: "Innkjøpspris",
    description: [
      "Innkjøpsprisavtaler er en avtaleform Forbrukerrådet fraråder. Avtaletypen ligner til forveksling på spotavtaler, men inneholder skjulte priselementer som ikke opplyses til kundene. Hemmelighold av priselementer gjør at avtalene framstår som billigere enn de er.",
      "Hemmeligholdet gjør det også umulig å beregne korrekt pris på avtalene og å sammenligne prisen opp mot andre strømavtaler. Innkjøpsprisavtalene er av denne grunn plassert under fanen «Andre avtaler» på Strømpris, hvor de listes nederst og er merket med tydelig advarsel og vises uten pris.",
      "Forbrukertilsynet har konkludert med at slike strømavtaler med hemmelige priselementer er ulovlige. ",
    ],
    mostUsed: true
  },
  {
    term: "Leveringsplikt",
    description: [
      "Nettselskapet ditt er pliktig til å levere strøm til deg hvis du ikke har en ordinær strømavtale. Dette kan for eksempel skje ved flytting eller hvis du har fått kuttet strømmen ved manglende betaling. Prisen de seks første ukene er relativt lav, men stiger betraktelig etter det. OBS! Frem til 1. juli 2023 skal prisen ikke overstige spotpris + 6,25 øre per kWh i påslag.",
    ],
  },
  {
    term: "Mobilprising av strøm",
    description: [
      "Kalles også “fast månedspris”. Dette betyr at du betaler for en viss mengde strøm om året, f.eks. 10.000 kWt/år, og for dette betaler du en avtalt pris hver måned. Bruker du mindre strøm enn avtalt, blir strømmen veldig dyr.",
    ],
  },
  {
    term: "Målerstand",
    description: [
      "Med målerstand menes sifferet du finner på din strømmåler.",
      "Nettselskapet har ansvar for at strømmålerne kontrolleres. Har du mistanke om at måleren ikke er korrekt, kan du når som helst be nettselskapet om å kontrollere måleren. Vær oppmerksom på at du må betale for kontrollen med mindre den viser et måleavvik større enn det som tillates i henhold til forskrift om krav til elektrisitetsmålere. Påvises et større avvik skal nemlig nettselskapet betale for kontrollen (§ 5-3 i standard nettleieavtale).",
    ],
  },
  {
    term: "Målepunkt ID",
    description: [
      "Med målepunkt ID menes det unike identifikasjonsnummeret alle strømmålere i Norge har. Nummeret finner du ikke på den fysiske strømmåleren, men du skal få det opplyst på strømregningen, eller ved å kontakte nettselskapet. Ditt målepunkt ID er på 18 siffer, men de 10 første er like for alle målere i Norge. Noen nettselskaper oppgir derfor kun de siste 8 sifrene, mens andre opplyser alle 18. Skal du skifte strømsalgselskap må du opplyse målepunkt ID – eller gi ny leverandør fullmakt til å innhente målepunkt ID.",
    ],
  },
  {
    term: "Nettleie",
    description: [
      "For at du skal få strøm i boligen din, må strømmen transporteres til boligen. Dette skjer over strømnettet, som eies og driftes av det lokale nettselskapet, mot at du betaler nettleie. Nettleien har vanligvis et fastledd og et variabelt ledd avhengig av ditt forbruk. Nettselskapet fakturerer også forbruksavgiften du må betale til staten. Hvor mye nettselskapet kan ta seg betalt i nettleie reguleres av Norges vassdrags- og energidirektorat. Les mer om nettleie på hjemmesiden til <a href='https://www.nve.no/stromkunde/nettleie/'>Norges vassdrags- og energidirektorat (NVE)</a>.",
    ],
    mostUsed: true
  },
  {
    term: "Nettleverandør",
    description: [
      "Strømnettet eies av nettleverandørene, også kalt nettselskaper. Etablering og vedlikehold av strømnettet er kostbart, og det er samfunnsøkonomisk ulønnsomt med utbygging av parallelle strømnett og konkurrerende selskaper. Derfor har myndighetene gitt nettselskaper monopol på å transportere sine tjenester innenfor sitt geografiske område. Du kan ikke velge nettselskap, men prisen for nettleie er regulert og nettselskapet har plikt til å levere deg elektrisk kraft. Nettselskapet fakturerer også forbruksavgiften du må betale til staten. For at nettselskapet ikke skal ta urimelig betalt i nettleie av deg reguleres grensene for hvor mye nettselskapene kan ta i nettleie av Norges vassdrags- og energidirektorat.",
    ],
    mostUsed: true
  },
  {
    term: "Nord Pool",
    description: [
      "I Norden er det ett felles marked for kraft, og Nord Pool er markedsplassen for kraftomsetning i Norden. Kraftprodusenter, kraftleverandører og store industrikunder kjøper og selger kraft på den nordiske kraftbørsen Nord Pool. På strømpris viser vi gjennomsnittlig spotpris foregående måned inkludert merverdiavgift.",
    ],
    mostUsed: true
  },
  {
    term: "Opprinnelsesgaranti",
    description: [
      "Merkeordning for at strømmen er produsert fra fornybare kilder, som vann, vind, sol eller biobrensel. Strømprodusenter selger opprinnelsesgarantier for sin fornybare produksjon til strømselgere, som dermed kan garantere deg og meg at strømmen vi kjøper er fornybar.",
    ],
  },
  {
    term: "Prisområde",
    description: [
      "Norge er delt inn i fem prisområder, hvor spotprisene fra Nord Pool kan variere. Derfor kan det være prisforskjeller mellom de ulike landsdelene. Du kan se kart over prisområdene på Statnetts nettsider.",
    ],
  },
  {
    term: "Påslag",
    description: [
      "Påslag er et pristillegg som kraftleverandørene tar i tillegg til spotprisen. Påslag kan oppgis som et fastbeløp og/eller som et beløp per kWt, og er tatt med i strømkostnaden du ser hos oss.",
    ],
    mostUsed: true
  },
  {
    term: "Spotpris",
    description: [
      "Over tid er dette den billigste avtalen. Regningen din vil bestemmes av prisen på kraftbørsen. I tillegg må du betale et påslag per kilowattime, og mange tar også en fast månedlig sum.",
      "Med en timespotavtale betaler du prisen på kraftbørsen time for time, og dette multipliseres med ditt faktiske timesforbruk. Disse avtalene er merket med et timeglass.",
      "Med en vanlig spotavtale betaler du prisen på kraftbørsen, multiplisert med ditt totale forbruk og en gitt forbruksprofil. Disse avtalene er merket med en kalender.",
      "I tillegg må du altså for både spot og timespot betale et påslag per kilowattime, og mange tar også en fast månedlig sum.",
    ],
    ref: "spot",
    mostUsed: true
  },
  {
    term: "Timespot",
    description: [
      "Med en timespotavtale faktureres du for ditt faktiske strømforbruk time for time. Prisen du betaler bestemmes av børsen Nord Pool time for time, i tillegg til et påslag og eventuelt månedlig fastbeløp til strømsalgselskapet. Med timespotavtaler har forbruker selv mulighet til å påvirke strømregningen sin ved å skifte forbruket sitt til deler av dagen hvor strømprisen normalt er billigere (f.eks. midt på dagen eller sent på kvelden/natten).",
    ],
  },
  {
    term: "Variabel pris",
    description: [
      "Ofte kalt “standard variabel pris”. Med denne avtalen følger du utviklingen på strømmarkedet, som regel med to ukers forsinkelse. Strømselskapet skal informere deg om prisendringer 30 dager i forkant. Det vil si at prisen kan endre seg hver trettiende dag.",
    ],
    mostUsed: true
  },
];
