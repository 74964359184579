import { useContext } from "react";
import { faArrowRight, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FlexLayout } from "components/Layouts/FlexLayout";
import { Paragraph } from "components/Typography/Paragraph/Paragraph";
import { CompareProductsContext } from "contexts/useCompareProducts";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import NoStyleButton from "components/Button/NoStyleButton";
import {
  displayPrice,
  dotToComma,
  effectivePriceOnFixed,
  getPurchaseDisplayPrice,
  isNoPrice,
  isSpotPrice,
  onlyThreeDecimals,
  oreToKroner
} from "utils/priceUtils";
import useStore from "store/store";
import {ProductType} from "../../../../shared/types";

interface ICompareCard {
  product?: any;
  currentProduct?: boolean;
  firstCard?: boolean;
}
const CompareCard = ({ product, currentProduct, firstCard }: ICompareCard) => {
  const { t } = useTranslation();

  const {
    setShowCompareView,
    setToggled,
    setSelectedProductId,
    clearProductsToCompare,
    deleteProductToCompare,
    containsPlusProducts
  } = useContext(CompareProductsContext);

  const selectProduct = () => {
    setShowCompareView(false);
    setToggled(false);
    setSelectedProductId(product.productId);
    clearProductsToCompare();
  };

  const yearlyConsumption = useStore((state) => state.yearlyConsumption);

  function getSaleDisplayPrice(product: any) {
    if (isNoPrice(product)) {
        return t("noPrice");
    }
    else if (isSpotPrice(product)) {
        return displayPrice(oreToKroner(product.effectiveAddon));
    }
    else {
        return displayPrice(oreToKroner(effectivePriceOnFixed(product, yearlyConsumption)));
    }
  }

  return (
    <div
      className={`${styles.card} ${
        currentProduct && styles.currentProductCard
      } ${firstCard && styles.marginLeft}`}
    >
      <FlexLayout gap={1}>
        <FlexLayout
          direction="row"
          verticalAlign="flex-start"
          horizontalAlign="space-between"
        >
          <Paragraph news wrap>
            {product.productName}
          </Paragraph>
        </FlexLayout>
        <Paragraph>{product.organizationName}</Paragraph>
      </FlexLayout>
      <FlexLayout horizontalAlign="center">
        <Paragraph>
          {product.productType === ProductType.OTHER
            ? t("otherPriceModel")
            : product.productType}
        </Paragraph>
      </FlexLayout>
      <FlexLayout horizontalAlign="center">
        <Paragraph>
          {product.addonPriceMinimumFixedForInMonths > 0
            ? `${product.addonPriceMinimumFixedForInMonths} ${t("months")}`
            : t("noAgreementTime")}
        </Paragraph>
      </FlexLayout>
      <FlexLayout horizontalAlign="center">
        <Paragraph>
          {getSaleDisplayPrice(product)}
        </Paragraph>
      </FlexLayout>

      {containsPlusProducts &&
          <FlexLayout horizontalAlign="center">
            <Paragraph>
              {getPurchaseDisplayPrice(product)}
            </Paragraph>
          </FlexLayout>
      }

      <FlexLayout horizontalAlign="center">
        <Paragraph>
          {product.standardAlert
            ? t(`standardAlert_${product.standardAlert}`)
            : t(`none`)}
        </Paragraph>
      </FlexLayout>

      {!containsPlusProducts &&
      <FlexLayout horizontalAlign="center">
        <Paragraph news>
          {isNoPrice(product)
            ? t("cannotEstimatePrice")
            : dotToComma(onlyThreeDecimals(product.finalPriceMonthly)) +
              " " +
              t("nok")}
        </Paragraph>
      </FlexLayout>
      }

      {!currentProduct && (
        <FlexLayout direction="row" horizontalAlign="space-between">
          <NoStyleButton
            onClick={() => deleteProductToCompare(product.productId)}
            noPadding
          >
            <div className={styles.cardButton}>
              <FlexLayout
                direction="row"
                verticalAlign="center"
                horizontalAlign="flex-start"
              >
                <Paragraph>{t("remove")}</Paragraph>
                <FontAwesomeIcon icon={faXmark} className={styles.icon} />
              </FlexLayout>
            </div>
            <Paragraph
              size={14}
              link
              customStyle={{
                fontWeight: "bold",
                textDecoration: "none",
                color: currentProduct && "#fff",
                marginTop: "2px",
              }}
            ></Paragraph>
          </NoStyleButton>
          <NoStyleButton
            onClick={() => selectProduct()}
            name={t("select")}
            noPadding
          >
            <div className={styles.cardButton}>
              <FlexLayout
                direction="row"
                verticalAlign="center"
                horizontalAlign="flex-end"
              >
                <Paragraph>{t("select")}</Paragraph>
                <FontAwesomeIcon icon={faArrowRight} className={styles.icon} />
              </FlexLayout>
            </div>
          </NoStyleButton>
        </FlexLayout>
      )}
    </div>
  );
};

export default CompareCard;
