import { atom, useSetAtom } from "jotai";
import { useState, useEffect } from "react";

const productCountsAtom = atom([]);
const addProductCountAtom = atom(null, (_get, set, update) => {
  set(productCountsAtom, (prev) => [...prev, update]);
});

const removeProductCountAtom = atom(null, (_get, set, update) => {
  set(productCountsAtom, (prev) => prev.filter((x) => x !== update));
});

export const hasResultsAtom = atom((get) =>
  get(productCountsAtom).some((x) => get(x) !== 0)
);

export const useProductCount = () => {
  const [productCountAtom] = useState(atom(0));
  const addProductCount = useSetAtom(addProductCountAtom);
  const removeProductCount = useSetAtom(removeProductCountAtom);

  useEffect(() => {
    addProductCount(productCountAtom);
    return () => {
      removeProductCount(productCountAtom);
    };
  }, [productCountAtom, addProductCount, removeProductCount]);

  const setProductCount = useSetAtom(productCountAtom);

  return setProductCount;
};
