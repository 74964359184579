import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import GlobalStyles from "styles/GlobalStyles";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { MatomoProvider, createInstance } from "@jonkoops/matomo-tracker-react";
import ScrollToTop from "components/ScrollToTop";
import {isLocal} from "./shared/ApiUtils";

const instance = createInstance({
  urlBase: "https://forbrukerradet.matomo.cloud/",
  siteId: 3,
  disabled: isLocal(),
  heartBeat: {
    active: true, // optional, default value: true
    seconds: 10, // optional, default value: `15
  },
  linkTracking: true, // optional, default value: true
  configurations: {
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: "POST",
  },
});

ReactDOM.render(
  <React.StrictMode>
    <MatomoProvider value={instance}>
      <BrowserRouter>
        <Suspense fallback={<div></div>}>
          <GlobalStyles />
          <ScrollToTop />
          <App />
        </Suspense>
      </BrowserRouter>
    </MatomoProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
