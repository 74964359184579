import React, { useState } from "react";
import { IHistoricPricesMonth } from "shared/types";
import styles from "./styles.module.scss";
import useIsMobile from "hooks/useIsMobile";

type ColumnKey = "month" | "NO1" | "NO2" | "NO3" | "NO4" | "NO5";

type HeaderKey = "" | ColumnKey;

interface Props {
  data: IHistoricPricesMonth[];
}

const PricesTable: React.FC<Props> = ({ data }) => {
  const [activeRowIndex, setActiveRowIndex] = useState(-1);
  const [activeColumnIndex, setActiveColumnIndex] = useState<HeaderKey | -1>(
    -1
  );

  const isMobile = useIsMobile();

  const columns: HeaderKey[] = ["", "NO1", "NO2", "NO3", "NO4", "NO5"];

  const getHeaderName = (key: HeaderKey) => {
    switch (key) {
      case "month":
        return "";
      case "NO1":
        return "Øst";
      case "NO2":
        return "Sør";
      case "NO3":
        return "Midt";
      case "NO4":
        return "Nord";
      case "NO5":
        return "Vest";
      default:
        return "";
    }
  };

  const setActive = (rowIndex: number, columnIndex: HeaderKey) => {
    setActiveRowIndex(rowIndex);
    setActiveColumnIndex(columnIndex);
  };

  const resetActive = () => {
    setActiveRowIndex(-1);
    setActiveColumnIndex(-1);
  };

  return (
    <table className={styles.priceTable}>
      <caption className={styles.caption}>
        {data.some((month: IHistoricPricesMonth) => !month.complete) &&
          "* Beløpet for denne måneden er basert på foreløpig data."}
      </caption>
      <thead>
        <tr>
          {columns.map((column: HeaderKey, index: number) => (
            <React.Fragment key={index}>
              {index === 0 ? (
                <td
                  key={index}
                  className={styles.tableEmptySquare}
                  onMouseEnter={() => setActive(-1, column)}
                  onMouseLeave={resetActive}
                >
                  {column}
                </td>
              ) : (
                <th
                  key={index}
                  scope="col"
                  className={`${
                    activeColumnIndex === column ? styles.active : ""
                  } ${column === "" ? styles.tableEmptySquare : ""}`}
                  onMouseEnter={() => setActive(-1, column)}
                  onMouseLeave={resetActive}
                >
                  {getHeaderName(column)}
                  {!isMobile ? "-Norge" : ""}
                </th>
              )}
            </React.Fragment>
          ))}
        </tr>
      </thead>

      <tbody>
        {data.map((row: IHistoricPricesMonth, rowIndex: number) => {
          return (
            <tr
              key={rowIndex}
              className={`${rowIndex === activeRowIndex ? styles.active : ""}`}
              onMouseEnter={() => setActive(rowIndex, "")}
              onMouseLeave={resetActive}
            >
              {columns.map((column: HeaderKey, columnIndex: number) => {
                if (columnIndex === 0)
                  return (
                    <th
                      key={columnIndex}
                      scope="row"
                      className={`${!row.complete ? styles.activeMonth : ""}
                      ${rowIndex === activeRowIndex ? styles.active : ""}`}
                      onMouseEnter={() => setActive(rowIndex, "")}
                      onMouseLeave={resetActive}
                    >
                      {isMobile ? row.month.substring(0, 3) : row.month}
                      {row.complete ? "" : "*"}
                    </th>
                  );

                return (
                  <td
                    key={columnIndex}
                    className={`${
                      column === activeColumnIndex ? styles.active : ""
                    } ${rowIndex === activeRowIndex ? styles.active : ""} ${
                      !row.complete ? styles.activeMonth : ""
                    }
                    `}
                    onMouseEnter={() => setActive(rowIndex, column)}
                    onMouseLeave={resetActive}
                  >
                    {column === ""
                      ? isMobile
                        ? row.month.substring(0, 3)
                        : row.month
                      : Number(row[column as ColumnKey])
                          .toFixed(3)
                          .replace(".", ",")}
                    {row.complete ? "" : "*"}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default PricesTable;
