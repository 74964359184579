import styled from "styled-components";
import { useState } from "react";
import "./stylesOwn.css";
import { BlockLink } from "styles/Buttons";
import { TextInput } from "styles/Forms";
import useStore from "store/store";
import HouseType from "./HouseType";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { Paragraph } from "components/Typography/Paragraph/Paragraph";
import { FlexLayout } from "components/Layouts/FlexLayout";
import ToggleButton from "components/Products/CompareProducts/ToggleButton/ToggleButton";
import { faBolt, faHouse } from "@fortawesome/pro-regular-svg-icons";
import ReactSelect from "react-select";
import { selectStyles, selectTheme } from "styles/Forms";
import useLocalGrids from "../../hooks/useLocalGrids";

export const housingTypes = [
  {
    id: 1,
    label: "Leilighet",
    kwhPerSquareMeter: 145,
  },
  {
    id: 2,
    label: "Rekkehus",
    kwhPerSquareMeter: 156,
  },
  {
    id: 3,
    label: "Enebolig",
    kwhPerSquareMeter: 140,
  },
];

function ConsumptionCalculator({ persist, submitted, setSubmitted, onClick }) {
  const { trackEvent } = useMatomo();

  const setLocalGrid = useStore((state) => state.setLocalGrid);
  const setYearlyConsumption = useStore((state) => state.setYearlyConsumption);
  const selectedLocalGrid = useStore((state) => state.selectedLocalGrid);
  const yearlyConsumption = useStore((state) => state.yearlyConsumption);

  const [localGrid, selectLocalGrid] = useState(selectedLocalGrid);

  const [consumption, setConsumption] = useState(yearlyConsumption);
  const [area, setArea] = useState(69);
  const [houseType, setHouseType] = useState(0);

  const [calculateByArea, setCalculateByArea] = useState(false);

  function areaToConsumption(area, houseTypeIndex) {
    return area * housingTypes[houseTypeIndex].kwhPerSquareMeter;
  }

  function consumptionToArea(consumption, houseTypeIndex) {
    return Math.round(
      consumption / housingTypes[houseTypeIndex].kwhPerSquareMeter
    );
  }

  function toggleCalculationType() {
    if (calculateByArea) {
      const newConsumption = areaToConsumption(area, houseType);
      setConsumption(newConsumption);
    } else {
      const newArea = consumptionToArea(consumption, houseType);
      setArea(newArea);
    }
    setCalculateByArea(!calculateByArea);
  }

  const onSubmit = () => {
    setLocalGrid(localGrid, persist);
    setYearlyConsumption(parseInt(consumption), persist);
    setSubmitted(true);
  };

  const { data: localGrids } = useLocalGrids();

  function validateNumber(value) {
    let parsed = parseInt(value) | "";
    if (parsed > 999999999) {
      parsed = 999999999;
    } else if (parsed < 0) parsed = 0;
    return parsed;
  }

  const onConsumptionChangeHandler = (event) => {
    const newConsumption = validateNumber(event.target.value);
    setConsumption(newConsumption);
    setArea(consumptionToArea(newConsumption, houseType));
  };

  const onAreaChangeHandler = (event) => {
    const newArea = validateNumber(event.target.value);
    setArea(newArea);
    setConsumption(areaToConsumption(newArea, houseType));
  };

  const onHouseTypeChangeHandler = (houseTypeIndex) => {
    setHouseType(houseTypeIndex);
    setConsumption(areaToConsumption(area, houseTypeIndex));
  };

  const localGridName = localGrids?.find(
    (el) => el.id === localGrid
  )?.name;

  return (
    <StyledConsumptionCalculator>
      {!submitted ? (
        <>
          <MarginWrapper>
            <fieldset>
              <label htmlFor="municipality">Hvilken kommune bor du i?</label>
              <ReactSelect
                options={localGrids}
                defaultValue={localGrids.find(
                  (el) => el.id === localGrid
                )}
                onChange={(val) => {
                  selectLocalGrid(val.id);
                }}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                placeholder="Skriv inn din kommune"
                isSearchable
                backspaceRemovesValue
                closeMenuOnSelect
                className="blue-select"
                styles={selectStyles}
                theme={selectTheme}
                noOptionsMessage={() => "Ingen treff"}
              />
            </fieldset>
            <FlexLayout
              direction="row"
              verticalAlign="center"
              horizontalAlign="space-between"
            >
              <Paragraph news>
                {calculateByArea === true
                  ? "Beregn basert på bolig"
                  : "Beregn basert på forbruk"}
              </Paragraph>
              <ToggleButton
                onClick={() => toggleCalculationType()}
                isToggled={calculateByArea}
                icon={calculateByArea ? faHouse : faBolt}
              />
            </FlexLayout>
            {calculateByArea ? (
              <>
                <div>
                  <label htmlFor="area">Hvor stor er boligen din?</label>
                  <ConsumptionWrapper>
                    <TextInput
                      type="text"
                      id="area"
                      title="Skriv inn størrelse på din boenhet i kvadratmeter"
                      value={area}
                      onChange={onAreaChangeHandler}
                      className="blue-input"
                      autoComplete="off"
                      pattern="[0-9]*"
                    />
                    <ConsumptionUnit>
                      m<sup>2</sup>
                    </ConsumptionUnit>
                  </ConsumptionWrapper>
                </div>
                <fieldset>
                  <LegendWrapper>
                    <legend>Velg din boligtype</legend>
                  </LegendWrapper>

                  <HouseType
                    changeHousingType={onHouseTypeChangeHandler}
                    selectedHousingType={houseType}
                  />
                </fieldset>
              </>
            ) : (
              <fieldset>
                <label htmlFor="consumption">
                  <div>Hva er ditt årlige forbruk?</div>
                  <div className="sub-label">(Sjekk strømregningen din)</div>
                </label>
                <ConsumptionWrapper>
                  <TextInput
                    type="text"
                    value={consumption}
                    onChange={onConsumptionChangeHandler}
                    id="consumption"
                    className="blue-input"
                    autoComplete="off"
                    pattern="[0-9]*"
                  />
                  <ConsumptionUnit>kWt</ConsumptionUnit>
                </ConsumptionWrapper>
              </fieldset>
            )}
          </MarginWrapper>
          <SubmitButton
            onClick={() => {
              onSubmit();
              onClick();
              trackEvent({
                category: "compare-products",
                action: "check-prices",
                name: `${
                  localGrids.find((el) => el.id === localGrid)?.name
                } - ${consumption}`,
              });
            }}
            type="submit"
          >
            Sjekk prisene
          </SubmitButton>
        </>
      ) : (
        <>
          <p style={{ fontFamily: "var(--font-family-book)" }}>
            Basert på at du bor i <b>{localGridName} kommune </b>
            <br />
            og at ditt årlige forbruk er <b>{yearlyConsumption} kWt</b>
          </p>
          <ChangeSubmitButton onClick={() => setSubmitted(false)}>
            Endre søk
          </ChangeSubmitButton>
        </>
      )}
    </StyledConsumptionCalculator>
  );
}

/*const StyledSelect = styled(SelectSearch)`
    display: block;
    width: 100%;
    height: 42px;
    padding: 8px 12px;
    font-size: 16px;
    line-height: 1.4;
    color: #000;
    background-color: #d0eff4;
    background-image: none;
    border: none;
    border-radius: 0;
`
*/

const MarginWrapper = styled.div`
  margin: 0 32px 0 32px;
  & > * {
    margin-top: 16px;
  }
`;

const LegendWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledConsumptionCalculator = styled.div`
  text-align: center;
  display: flex;
  //gap: 16px;
  & > * + * {
    margin-top: 16px;
  }
  flex-direction: column;
  margin: 0 auto;
  max-width: 100vw;
  width: 360px;
  @media (max-width: 374px) {
    width: 320px;
  }
  fieldset {
    border: none;
    padding: 0;
  }
  label,
  fieldset {
    font-family: var(--font-family-news);
    font-size: 1.125rem;
    text-align: center;
  }
  .sub-label {
    font-family: var(--font-family-book);
    font-size: 1rem;
  }
`;

const SubmitButton = styled(BlockLink)`
  font-size: 1.125rem;
  width: 100%;
  max-width: 344px;
  align-self: center;
  margin-top: 16px;
  padding: 1rem 0px;
  color: #333;
  &:hover {
    background: #404040;
    color: white;
  }
`;

const ChangeSubmitButton = styled(BlockLink)`
  font-size: 1.125rem;
  width: 100%;
  max-width: 344px;
  align-self: center;
  margin-top: 0px;
  padding: 1rem 0px;
  cursor: pointer;
`;

const ConsumptionWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const ConsumptionUnit = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  font-family: var(--font-family-book);
  font-size: 1rem;
`;

export default ConsumptionCalculator;
