import { useQuery } from "react-query";
import axios from "axios";
import { IHistoricPrices } from "shared/types";
import { getSpotpriceMsApiHost, getSpotpriceApiToken } from "../shared/ApiUtils";

const fetchHistoricPrices = async (
  period: string
): Promise<IHistoricPrices> => {
  let start;
  let end = "";
  if (period === "last12") {
    let now = new Date();
    now.setDate(1);
    now.setMonth(now.getMonth() - 11);
    const ymd = now.toISOString().split("T")[0].split("-");
    start = `${ymd[0]}-${ymd[1]}`;
  } else {
    let startDate = new Date(period);
    const ymdStart = startDate.toISOString().split("T")[0].split("-");
    start = `${ymdStart[0]}-${ymdStart[1]}`;

    let endDate = new Date(startDate);
    endDate.setMonth(11);
    const ymdEnd = endDate.toISOString().split("T")[0].split("-");
    end = `&end=${ymdEnd[0]}-${ymdEnd[1]}`;
  }

  const { data } = await axios.get(
    `${getSpotpriceMsApiHost()}/spotprice/monthly-average?start=${start}${end}&token=${getSpotpriceApiToken()}`
  );
  return data;
};

export default function useHistoricPrices(period: string) {
  return useQuery<IHistoricPrices, Error>(
    [`${period}`],
    () => fetchHistoricPrices(period),
    {
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );
}
