import { useEffect } from "react";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import PageIntro from "components/PageIntro";
import NavCard from "components/NavCard";
import { ExternalLinkIcon } from "components/Icons/ExternalLinkIcon";
import { RouteWrapper } from "styles/General";
import useWordpressPosts from "hooks/useWordpressPosts";
import Button from "../Button/Button";
import { FlexLayout } from "../Layouts/FlexLayout";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import {removeHtmlTags} from "shared/formatter";

const Home = () => {
  const { trackPageView } = useMatomo();
  useEffect(() => {
    trackPageView({});
  }, [trackPageView]);

  const { data, isLoading } = useWordpressPosts();
  const postsToShow = 3;
  const latestPosts = data ? data.slice(0, postsToShow) : [];
  const navigate = useNavigate();

  return (
    <RouteWrapper>
      <PageIntro
        title="Sjekk strømprisene"
        leadParagraph="Vi overvåker markedet og viser de beste strømavtalene for deg."
      />
      <Section>
        <NavCards>
          <NavCard
            destination="/sammenlign-avtaler"
            color="light-blue"
            title="Sammenlign avtaler"
            description="Finn og sammenlign de beste strømavtalene på markedet. Få en bedre pris og bytt i dag."
            longDescription="Finn og sammenlign de beste strømavtalene på markedet. Få en bedre pris og bytt i dag."
            buttonText="Jeg vil sammenligne priser"
            //image={calculatorImage}
            desktop={true}
          />
          {/*         <NavCard
          destination="/fakturasjekk"
          color="var(--light-blue)"
          title="Sjekk din avtale"
          description="Finn frem strømregningen og sjekk om du har en god avtale"
        /> */}
          <NavCard
            destination="/spotpriser"
            color="light-blue"
            title="Spotpriser"
            description="Vi viser deg spotprisen time for time fra Strømbørsen Nordpool."
            longDescription="Vi viser deg spotprisen time for time fra Strømbørsen Nordpool."
            buttonText="Jeg vil se spotprisene"
            //image={spotpriserImage}
            desktop={true}
          />
        </NavCards>
      </Section>

      <Section>
        <h2>Hjelp og råd</h2>
        <NavCards>
          <NavCard
            destination="/om-strompris"
            color="orange"
            title="Om Strømpris.no"
            headerLevel="h3"
          />
          <NavCard
            destination="/ofte-stilte-sporsmal"
            color="orange"
            title="Ofte stilte spørsmål"
            headerLevel="h3"
          />
          <NavCard
            destination="/ordliste"
            color="orange"
            title="Ordliste"
            headerLevel="h3"
          />
        </NavCards>
      </Section>

      <Section>
        <h2>Siste nytt</h2>
        <News>
          {isLoading && (
            <>
              {Array.from(Array(postsToShow).keys()).map((i) => (
                <a href="/" key={i}>
                  <Skeleton height={200} />
                  <h3>
                    <Skeleton />
                  </h3>
                  <p>
                    <Skeleton count={5} />
                  </p>
                </a>
              ))}
            </>
          )}
          {data &&
            latestPosts.map((post) => (
              <a
                key={post.id}
                href={post.link}
                rel="noreferrer"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <img
                  src={post._embedded["wp:featuredmedia"][0].link}
                  alt={post._embedded["wp:featuredmedia"][0].alt_text}
                />
                <h3 className={styles.h2}>{post.title.rendered}</h3>
                <p className={styles.p}>
                  <span style={{ color: "var(--primary-black)" }}>
                    {removeHtmlTags(post.excerpt?.rendered || '')}
                  </span>
                  <ExternalLinkIcon />
                </p>
              </a>
            ))}
        </News>
        <FlexLayout verticalAlign="center" customStyle={{ marginTop: "1rem" }}>
          <Button onClick={() => navigate("nyheter")} text="Se all nyheter" />
        </FlexLayout>
      </Section>
    </RouteWrapper>
  );
};

const Section = styled.section`
  margin-top: 50px;
`;

const NavCards = styled.div`
  display: flex;
  flex-direction: row;

  & > * + * {
    margin: 0 0 0 20px;
  }
  @media screen and (max-width: 770px) {
    & > * + * {
      margin: 20px 0 0 0;
    }
    flex-direction: column;
  }
`;

const News = styled.div`
  display: flex;
  & > * + * {
    margin: 0 0 0 20px;
  }
  @media screen and (max-width: 770px) {
    & > * + * {
      margin: 20px 0 0 0;
    }
    flex-direction: column;
  }
  > * {
    flex: 1 1 0;
  }
  img {
    object-fit: cover;
    width: 100%;
  }
`;
export default Home;
