import { Paragraph } from "components/Typography/Paragraph/Paragraph";
import useIsMobile from "hooks/useIsMobile";
import styled from "styled-components";

interface TabLinkProps extends React.HTMLAttributes<HTMLElement> {
  text: string;
  color: string;
  selected?: boolean;
  textColor?: string;
}

interface WrapperProps {
  bgcolor: string;
  selected?: boolean;
  buttonText: string;
  isMobile: boolean;
}

function TabLink({ text, color, textColor, selected, onClick }: TabLinkProps) {
  const isMobile = useIsMobile();
  return (
    <Wrapper
      onClick={onClick}
      bgcolor={color}
      selected={selected}
      buttonText={text}
      isMobile={isMobile}
    >
      <TabLinkButton>
        <Paragraph
          news
          size={14}
          center
          color={textColor && selected ? textColor : "inherit"}
        >
          {text}
        </Paragraph>
      </TabLinkButton>
      {selected}
    </Wrapper>
  );
}

/* const BoxShadow = styled.div`
  box-shadow: 0 0px 3px 1px rgba(0, 0, 0, 0.2);
  clip-path: polygon(
    -10% -10%,
    110% -10%,
    110% 100%,
    100% 100%,
    50% 50%,
    0% 100%,
    -10% 100%
  );
  z-index: 2;
  position: absolute;
  height: 100%;
  width: 100%;
`; */

const Wrapper = styled.div<WrapperProps>`
  display: inline-flex;
  position: relative;
  background-color: var(--${(props) => props.bgcolor});
  justify-content: center;
  width: 100%;
  flex: ${(props) =>
    props.isMobile ? (props.buttonText === "Andre avtaler" ? "3" : "2") : "1"};
  height: 4em;
  z-index: ${(props) => (props.selected ? 3 : 1)};
  border-radius: 5px 5px 0 0;
  button {
    border-radius: 5px 5px 0 0;
    width: 100%;
    padding: 11px 4px;
    font-family: ${(props) => (props.selected ? "ClanOT-News" : "ClanOT-Book")};
    color: var(--primary-black);
    border: none;
    background: ${(props) =>
      props.selected ? "inherit" : "var(--background-light-gray)"};
    font-size: 1.125rem;
    height: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    z-index: 1;
    outline-offset: 0px;
  }
`;

const TabLinkButton = styled.button`
  display: flex;
  cursor: pointer;
  &:focus-visible {
    outline-offset: 0px !important;
  }
`;

export default TabLink;
