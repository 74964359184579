import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";

interface Props {
  color?: string;
  "data-tip": string;
  "data-for": string;
}

export const TooltipIcon = ({
  color = "var(--primary-white)",
  "data-tip": dataTip,
  "data-for": dataFor,
}: Props) => {
  return (
    <FontAwesomeIcon
      color={color}
      icon={faQuestionCircle}
      data-tip={dataTip}
      data-for={dataFor}
    />
  );
};
