import { faCircleCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./styles.module.scss";

interface IActionSuccess {
  isSuccess: boolean;
}

export const ActionSuccess = ({ isSuccess }: IActionSuccess) => {
  return (
    <div className={styles.actionSuccess}>
      <FontAwesomeIcon
        icon={faCircleCheck}
        style={{ verticalAlign: "middle" }}
        transform={"grow-4"}
        color={isSuccess ? "#00650A" : "#E2E7E9"}
      />
      <div
        className={styles.verticalLine}
        style={{ backgroundColor: isSuccess ? "#00650A" : "#E2E7E9" }}
      />
    </div>
  );
};
