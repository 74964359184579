import { useState } from "react";

export const useTooltip = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [tooltipRef, setTooltipRef] = useState<any>(null);

  return {
    open,
    setOpen,
    tooltipRef,
    setTooltipRef,
  };
};
