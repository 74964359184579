export interface IProducts {
  spotLong: any[];
  spotMedium: any[];
  spotShort: any[];
  fixed: any[];
  fixedShort: any[];
  fixedMedium: any[];
  fixedLong: any[];
  custom: any[];
  customWithAgreementTime: any[];
  customVariable: any[];
  plusSpotShort: any[];
  plusSpotLong: any[];
  plusFixed: any[];
}

type RatingKeys = "good" | "ok" | "bad";
export interface IInvoiceAnalysis {
  area: string;
  rating: RatingKeys;
  hourlyPrice: number;
  expectedAverageHourlyPrice: number;
  expectedPeriodPrice: number;
  diffHourly: number;
  diffPeriod: number;
  consumption: number;
  municipalityName: string;
}

export interface IInvoiceForm {
  invoiceStart: string | null;
  invoiceEnd: string | null;
  invoiceAmount: number | null;
  consumption: number | null;
  postalCode: string | null;
}

export interface IHistoricPrices {
  start: string;
  end: string;
  data: IHistoricPricesMonth[];
}

export interface IHistoricPricesMonth {
  month: string;
  NO1: number;
  NO2: number;
  NO3: number;
  NO4: number;
  NO5: number;
  complete: boolean;
}

export interface IDailyPricesAverage {
  NO1: number;
  NO2: number;
  NO3: number;
  NO4: number;
  NO5: number;
}
export interface IDailyPricesDay {
  average: IDailyPricesAverage;
  hourly: [
    {
      fromHour: string;
      toHour: string;
      NO1: number;
      NO2: number;
      NO3: number;
      NO4: number;
      NO5: number;
    }
  ];
}

export interface IDailyPrices {
  today: IDailyPricesDay;
  tomorrow: IDailyPricesDay | null;
}

export enum PriceType {
    KW = "KW",
    SPOT = "SPOT",
    ETTERSKUDDVIS = "ETTERSKUDDVIS",
    FORESPORSEL = "FORESPORSEL"
}

export enum ProductType {
    SPOT = "Spotpris",
    FIXED = "Fastpris",
    PURCHASE = "Innkjøpspris",
    VARIABLE = "Variabel pris",
    OTHER = "Andre prismodeller",
    HOURLY_SPOT = "Timespot",
    PLUS = "Plussavtale"
}
