import {useContext, useEffect, useRef, useState} from "react";
import Button from "components/Button/Button";
import {FlexLayout} from "components/Layouts/FlexLayout";
import Products from "components/Products";
import CompareCard from "components/CompareProducts/ResultView/CompareCard/CompareCard";
import CompareCardHeader from "components/CompareProducts/ResultView/CompareCardHeader/CompareCardHeader";
import ProductTabs from "components/ProductTabs";
import {Paragraph} from "components/Typography/Paragraph/Paragraph";
import SelectSearch, {fuzzySearch} from "react-select-search";
import {CompareProductsContext} from "contexts/useCompareProducts";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import "./styles.css";
import Filters from "../../Filters";
import useStore from "store/store";
import useProducts from "hooks/useProducts/useProducts";
import styles from "./styles.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faPlus, faXmark} from "@fortawesome/pro-regular-svg-icons";
import {compareNames} from "./utils";
import Search from "components/Search";
import NoStyleButton from "components/Button/NoStyleButton";

interface IResultView {
  scrollTarget: any;
}

const ResultView = ({ scrollTarget }: IResultView) => {
  const {
    showCompareView,
    setShowCompareView,
    productsToCompare,
    addCurrentProductToCompare,
    hasCurrentProduct,
    removeCurrentProductToCompare,
    isProductTypeComparable
  } = useContext(CompareProductsContext);

  const selectedLocalGrid = useStore((state) => state.selectedLocalGrid);
  const yearlyConsumption = useStore((state) => state.yearlyConsumption);
  const { data: products } = useProducts(selectedLocalGrid, yearlyConsumption);
  const scrollToTopRef = useRef<any>(null);
  const searchFieldRef = useRef<any>(null);
  const [openCurrentProductDropdown, setOpenCurrentProductDropdown] =
    useState<boolean>(false);
  const [organizationNames, setOrganizationNames] = useState<any>([]);
  const [productNames, setProductNames] = useState<any>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<any>();

  const [searchValue, setSearchValue] = useState<string>("");

  const { t } = useTranslation();
  useEffect(() => {
    if (products && showCompareView) {
      const temp: any = [];
      Object.values(products).forEach((product: any) => {
        const mappedProduct = product.map((p: any) => {
          return {
            name: `${p.organizationName}`,
            value: p.organizationId,
          };
        });
        mappedProduct.forEach((product: any) => {
          const found = temp.some((obj: any) => obj.name === product.name);
          if (!found) temp.push(product);
        });
      });
      setOrganizationNames(temp.sort(compareNames));
    }
  }, [products, showCompareView]);

  useEffect(() => {
    if (!hasCurrentProduct() && showCompareView) setSelectedOrganization(null);
  }, [hasCurrentProduct, showCompareView]);

  useEffect(() => {
    if (products && selectedOrganization && showCompareView) {
      const temp: any = [];
      Object.values(products).forEach((product: any) => {
        const mappedProduct = product.map((p: any, index: number) => {
          return {
            name: `${p.productName}`,
            value: p.productId,
            orgId: p.organizationId,
            productType: p.productType
          };
        });
        mappedProduct.forEach((product: any) => {
          if (product.orgId === selectedOrganization && isProductTypeComparable(product.productType)) {
            temp.push(product);
          }
        });
      });
      setProductNames(temp.sort(compareNames));
    }
  }, [products, selectedOrganization, showCompareView]); //eslint-disable-line

  const handleSelectOrganization = (organizationId: any) => {
    setSelectedOrganization(organizationId);
  };

  const handleChange = (productId: any) => {
    if (products) {
      const mappedProducts = Object.values(products).map((allProducts: any) => {
        return [...allProducts];
      });
      const flatten = mappedProducts.flat();
      const product = flatten.find((p: any) => {
        return p.productId === productId;
      });
      addCurrentProductToCompare(product, true);
      setOpenCurrentProductDropdown(false);
    }
  };

  useEffect(() => {
    if (scrollToTopRef && showCompareView) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [scrollToTopRef, showCompareView]);

  if (showCompareView) {
    return (
      <div className={styles.compareView} ref={scrollToTopRef}>
        <FlexLayout
          gap={24}
          horizontalAlign="center"
          verticalAlign="center"
          customStyle={{ width: "100%" }}
        >
          <FlexLayout
            direction="row"
            horizontalAlign="center"
            customStyle={{ marginTop: "8px" }}
          >
            <Paragraph size={32} news asHeading>
              {t("compareAgreements")}
            </Paragraph>
          </FlexLayout>
          <FlexLayout customStyle={{ maxWidth: "1100px", width: "100%" }}>
            <Button
              onClick={() => {
                setShowCompareView(false);
                setOpenCurrentProductDropdown(false);
              }}
              text="Tilbake"
              asBackButton
              useDisableStyle
              disabled={false}
            />
            <FlexLayout direction="row" gap={24} wrap>
              <Paragraph news customStyle={{ alignSelf: "center" }}>
                {t("productsAdded", {
                  numberOfProducts: productsToCompare.length,
                })}
              </Paragraph>
              <NoStyleButton
                onClick={() => {
                  if (hasCurrentProduct()) {
                    removeCurrentProductToCompare();
                    setSelectedOrganization(null);
                  } else {
                    setOpenCurrentProductDropdown(true);
                  }
                }}
              >
                <FlexLayout direction="row" verticalAlign="center" gap={8}>
                  {hasCurrentProduct() ? (
                    <FontAwesomeIcon icon={faXmark} color={"#05426a"} />
                  ) : (
                    <FontAwesomeIcon icon={faPlus} color={"#05426a"} />
                  )}
                  <Paragraph asLink>
                    {hasCurrentProduct()
                      ? t("removeCurrentProduct")
                      : t("addCurrentProduct")}
                  </Paragraph>
                </FlexLayout>
              </NoStyleButton>
            </FlexLayout>
            {openCurrentProductDropdown && (
              <FlexLayout direction="row" gap={16}>
                <SelectSearch
                  options={organizationNames}
                  search={true}
                  closeOnSelect={true}
                  filterOptions={fuzzySearch}
                  autoFocus
                  placeholder="Velg strømselskap"
                  printOptions="always"
                  ref={searchFieldRef}
                  autoComplete="off"
                  onChange={handleSelectOrganization}
                  renderValue={(valueProps: any) => (
                    <div className={styles.inputField}>
                      <input
                        {...valueProps}
                        name="product"
                        id="product"
                        title="Velg strømselskap"
                        style={{ margin: "0.25rem 0px" }}
                      />
                      <div className={styles.addBtn}>
                        <FlexLayout direction="row">
                          <FontAwesomeIcon icon={faArrowRight} fontSize={24} />
                        </FlexLayout>
                      </div>
                    </div>
                  )}
                />
                {selectedOrganization && (
                  <SelectSearch
                    options={productNames}
                    search={true}
                    closeOnSelect={true}
                    filterOptions={fuzzySearch}
                    autoFocus
                    placeholder="Finn din strømavtale"
                    printOptions="always"
                    ref={searchFieldRef}
                    autoComplete="off"
                    onChange={handleChange}
                    renderValue={(valueProps: any) => (
                      <div className={styles.inputField}>
                        <input
                          {...valueProps}
                          name="product"
                          id="product"
                          title="Finn din strømavtale"
                          style={{ margin: "0.25rem 0px" }}
                        />
                        <div className={styles.addBtn}>
                          <FlexLayout direction="row">
                            <FontAwesomeIcon
                              icon={faArrowRight}
                              fontSize={24}
                            />
                          </FlexLayout>
                        </div>
                      </div>
                    )}
                  />
                )}
              </FlexLayout>
            )}
          </FlexLayout>
          <div className={styles.cardGridView}>
            <div className={styles.headerView}>
              <CompareCardHeader />
            </div>
            <div className={styles.scrollView}>
              {productsToCompare.map((compareObject: any) => {
                return (
                  <CompareCard
                    product={compareObject.product}
                    currentProduct={compareObject.isCurrent}
                  />
                );
              })}
            </div>
          </div>
        </FlexLayout>
      </div>
    );
  } else {
    return (
      <>
        <ProductTabs ref={scrollTarget} />
        <Wrapper>
          <FlexLayout gap={16}>
            <Filters />
            <Search
              updateSearchValue={(value: string) => setSearchValue(value)}
            />
          </FlexLayout>
        </Wrapper>
        <Products searchValue={searchValue} />
      </>
    );
  }
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 700px;
  @media screen and (max-width: 830px) {
    & > * + * {
      margin: 8px 0 0 0;
    }
  }
`;

export default ResultView;
