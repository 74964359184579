/* eslint-disable */
import { useQueryClient } from "react-query";
import { Navigate } from "react-router-dom";
import useStore from "store/store";

/*
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { useEffect, useState } from "react";

import { RouteWrapper } from "styles/General";
import { Spinner } from "styles/Spinner";
import { OverprisForm, SubmitButton } from "./styles";
*/
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useEffect } from "react";
import { RouteWrapper, TextSection } from "styles/General";
import PageIntro from "components/PageIntro";
import styled from "styled-components";

const Start = () => {
  const { trackPageView } = useMatomo();
  useEffect(() => {
    trackPageView({});
  }, [trackPageView]);

  return (
    <RouteWrapper>
      <Box>
        <h1>Fakturasjekken er midlertidig utilgjengelig</h1>
        <p>
          Forbrukerrådet har mottatt flere henvendelser knyttet til
          «fakturasjekken» som ble lansert onsdag 15. juni. Løsningen gjør det
          mulig å legge inn strømforbruk og kostnad for en viss periode, med mål
          om å vise forbrukeren om de har en dyr eller billig avtale.
        </p>
        <p>
          Vi ser at prismodellen som er lagt til grunn ikke tar tilstrekkelig
          høyde for variasjonene i spotprisene vi har sett det siste halvåret,
          slik at resultatet ikke blir like presist som i våre tester som ble
          gjort med mer stabile priser.
        </p>
        <p>
          På bakgrunn av dette har vi bestemt oss for å midlertidig trekke
          tilbake tjenesten, for å kvalitetssikre resultatene. Du kan fortsatt
          finne oversikt over rimelige strømavtaler og lett foreta et bytte på
          strømpris.no.
        </p>
      </Box>
    </RouteWrapper>
  );

  /*
  const queryClient = useQueryClient();
  const restartOverpris  = useStore((state) => state.restartOverpris);
  restartOverpris();
  queryClient.removeQueries("overpris", { exact: true });
  return <Navigate replace to={`/fakturasjekk/steg/1`} />;
  */

  /*
  const navigate = useNavigate();
  const location = useLocation();

  const setInvoiceAmount = useStore((state) => state.setInvoiceAmount);
  const setConsumption = useStore((state) => state.setConsumption);
  const setInvoiceStart = useStore((state) => state.setInvoiceStart);
  const setInvoiceEnd = useStore((state) => state.setInvoiceEnd);
  const setZipCode = useStore((state) => state.setZipCode);
  cons restartOverpris = useStore((state) => state.restartOverpris);

  const setFunctions = [
    setInvoiceAmount,
    setConsumption,
    setInvoiceStart,
    setInvoiceEnd,
    setZipCode,
  ];

  const [analyzing, setAnalyzing] = useState(false);

  useLayoutEffect(() => {
    if (location.pathname === "/fakturasjekk") {
      console.log("Restarting form");
      queryClient.removeQueries("overpris", { exact: true });

      restartOverpris();
      navigate("/fakturasjekk/steg/1");
      //return <Navigate replace to={`/fakturasjekk/steg/${maxStepAllowed}`} />;
    }
  }, [location.pathname, restartOverpris]);
  */

  /*   const uploadHandler = async (event: any) => {
    console.log(event.target.files[0].name);
    let apiHost = "https://strompris.no";
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      apiHost = "http://localhost:8180";
    }
    var formData = new FormData();
    formData.append("pdfFile", event.target.files[0]);
    setAnalyzing(true);
    const response = await axios.post(`${apiHost}/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log(response.data);
    if (response.data.invoiceAmount.confidence > 0.95) {
      setInvoiceAmount(response.data.invoiceAmount.text);
    }
    if (response.data.postalNumber.confidence > 0.95) {
      setZipCode(response.data.postalNumber.text);
    }
    if (response.data.consumption.confidence > 0.95) {
      setConsumption(parseFloat(response.data.consumption.text));
    }
    if (response.data.invoiceStart.confidence > 0.95) {
      //const start = new Date(response.data.invoiceStart.text);
      //setInvoiceStart(start);
    }
    if (response.data.invoiceEnd.confidence > 0.95) {
      //const end = new Date(response.data.invoiceEnd.text);
      //setInvoiceEnd(end);
    }
    navigate("/fakturasjekk/form/upload");
    setAnalyzing(false);
    //const { data } = await axios.post(`${apiHost}`, event.target.files[0]);
  };

  return (
    <RouteWrapper>
      <MainHeading title="Sjekk om du har en god strømavtale" />
      <OverprisForm>
        <InstructionsCard>
          <p>
            Finn frem strømregningen din og fyll inn opplysningene i de neste
            stegene
          </p>
        </InstructionsCard>
        {analyzing ? (
          <div
            style={{
              marginInline: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h2>Analyserer faktura</h2>
            <Spinner />
          </div>
        ) : (
          <>
            <FileUploadWrapper>
              eller last den opp
              <input onChange={uploadHandler} id="invoice-upload" type="file" />
            </FileUploadWrapper>
            <SubmitButton onClick={() => navigate("/fakturasjekk/steg/1")}>
              Neste
            </SubmitButton>
          </>
        )}
      </OverprisForm> 
    </RouteWrapper>
  );
    */
};

/*
const InstructionsCard = styled.div`
  background-color: #ebf0f3;
  padding: 5px 11px;
  text-align: center;
`;

const FileUploadWrapper = styled.label`
  input[type="file"] {
    display: none;
  }

  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
`;
*/
const Box = styled.div`
  background-color: #f4f4f4;
  margin: 0 auto;
  margin-top: 20px;
  padding: 20px;
  max-width: 800px;
  border-radius: 5px;
`;

export default Start;
