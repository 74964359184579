import styled from "styled-components";
import useStore from "store/store";
import { useEffect } from "react";
import useProducts from "hooks/useProducts/useProducts";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { Overlay } from "styles/General";
import { CompareProductsContext } from "contexts/useCompareProducts";
import { useCompareProducts } from "hooks/useCompareProducts";
import CalculatorAndResult from "./CalculatorAndResult/CalculatorAndResult";
import { TooltipContext } from "contexts/useTooltip";
import { useTooltip } from "hooks/useTooltip";

const CompareProducts = () => {
  const { trackPageView } = useMatomo();
  useEffect(() => {
    trackPageView({});
  }, [trackPageView]);

  const selectedLocalGrid = useStore((state) => state.selectedLocalGrid);
  const yearlyConsumption = useStore((state) => state.yearlyConsumption);
  const submitted = useStore((state) => state.consumptionCalculatorSubmitted);
  const { isLoading, isError, isSuccess } = useProducts(selectedLocalGrid, yearlyConsumption);

  const compareProductsHook = useCompareProducts();
  const tooltipHook = useTooltip();

  if (isError) {
    return (
      <StyledCompareProducts>
        <Overlay>
          <div>Klarte ikke laste produkter</div>
        </Overlay>
      </StyledCompareProducts>
    );
  }

  return (
    <CompareProductsContext.Provider value={compareProductsHook}>
      <TooltipContext.Provider value={tooltipHook}>
        <StyledCompareProducts>
          {isLoading && submitted && (
            <Overlay>
              <div>Laster produkter</div>
            </Overlay>
          )}
          <CalculatorAndResult isSuccess={isSuccess} submitted={submitted} />
        </StyledCompareProducts>
      </TooltipContext.Provider>
    </CompareProductsContext.Provider>
  );
};

const StyledCompareProducts = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 8px;
  //gap: 20px;
  & > * + * {
    margin-top: 20px;
  }
  align-items: center;
`;

export default CompareProducts;
