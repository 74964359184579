import { BlockLink } from "styles/Buttons";
import { usePDF } from "@react-pdf/renderer";
import PDFReceipt from "../../../../PDFReceipt/PDFReceipt";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FlexLayout } from "components/Layouts/FlexLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDownToBracket } from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import { CompareProductsContext } from "contexts/useCompareProducts";

type Props = { product: any; downloadSuccess: () => void };

const PDFDownloadButton = ({ product, downloadSuccess }: Props) => {
  const { trackEvent } = useMatomo();
  const { t } = useTranslation();
  const [instance] = usePDF({
    document: <PDFReceipt product={product} />,
  });
  const { canFocusPdfButton, setCanFocusPdfButton } = useContext(
    CompareProductsContext
  );
  const ref = useRef<any>(null);
  const eventName = `${product.productId} - ${product.productName}`;

  useEffect(() => {
    if (ref && canFocusPdfButton) {
      ref.current.focus();
      setCanFocusPdfButton(false);
    }
  }, [ref, canFocusPdfButton]); //eslint-disable-line

  return (
    <a
      href={instance.url ? instance.url : ""}
      download={`Prisbevis - ${product.productName}.pdf`}
    >
      <BlockLink
        autoFocus={true}
        ref={ref}
        style={{
          cursor: instance.loading ? "not-allowed" : "pointer",
          pointerEvents: instance.loading ? "none" : "auto",
        }}
        className={styles.downloadButton}
        onClick={() => {
          trackEvent({
            category: "ordering",
            action: "download-price-guarantee",
            name: eventName,
          });
          downloadSuccess();
        }}
      >
        <FlexLayout
          direction="row"
          horizontalAlign="space-between"
          verticalAlign="center"
          gap={12}
        >
          {t("downloadProof")}
          <FontAwesomeIcon
            icon={faArrowDownToBracket}
            style={{ verticalAlign: "middle" }}
            transform={"grow-4"}
            color="#333333"
          />
        </FlexLayout>
      </BlockLink>
    </a>
  );
};

export default PDFDownloadButton;
