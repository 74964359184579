import { Link } from "react-router-dom";
import { Centered } from "styles/General";
import { useEffect } from "react";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

const NoMatch = () => {
  const { trackPageView } = useMatomo();
  useEffect(() => {
    trackPageView({});
  }, [trackPageView]);

  return (
    <Centered>
      <h1>Siden finnes ikke!</h1>
      <p>Beklager, vi finner ikke siden du lette etter. Vil du tilbake?</p>
      <p>
        <Link to="/">Tilbake</Link>
      </p>
    </Centered>
  );
};

export default NoMatch;
