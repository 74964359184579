import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../../forbrukerradet_logo.svg";
import { useState, useRef } from "react";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import useIsMobile from "hooks/useIsMobile";
import CustomLink from "./CustomLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/pro-regular-svg-icons";

const Header = () => {
  const [showMenu, setMenu] = useState(false);
  const toggleMenu = () => {
    setMenu(!showMenu);
  };

  const hideMenu = () => {
    setMenu(false);
  };

  const showHamburger = useIsMobile();

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, hideMenu);
  const menuItems = [
    {
      name: "Sammenlign avtaler",
      route: "/sammenlign-avtaler",
    },
    /*     {
      name: "Sjekk din avtale",
      route: "/fakturasjekk",
    }, */
    {
      name: "Spotpriser",
      route: "spotpriser",
    },
    {
      name: "Ordliste",
      route: "ordliste",
    },
    {
      name: "Ofte stilte spørsmål",
      route: "ofte-stilte-sporsmal",
    },
    {
      name: "Om Strømpris",
      route: "om-strompris",
    },
  ];

  return (
    <Wrapper>
      <HeaderSection>
        <HeaderContainer>
          <StyledHeader>
            <div>
              <LogoLink to="/">
                <LogoWrapper>
                  <Logo src={logo} alt="Forbrukerrådet logo" />
                </LogoWrapper>
              </LogoLink>
            </div>

            {showHamburger && (
              <HamburgerNav ref={wrapperRef}>
                <button onClick={toggleMenu}>
                  <span>MENY</span>
                  <FontAwesomeIcon
                    spacing={2}
                    fixedWidth
                    icon={faBars}
                    transform="right-4"
                  />
                </button>
                <MenuContent>
                  <SlideDown closed={!showMenu}>
                    <Content>
                      {menuItems.map((item, index) => (
                        <CustomLink
                          key={index}
                          onClick={hideMenu}
                          to={item.route}
                        >
                          {item.name}
                        </CustomLink>
                      ))}
                    </Content>
                  </SlideDown>
                </MenuContent>
              </HamburgerNav>
            )}
          </StyledHeader>
        </HeaderContainer>
      </HeaderSection>

      {!showHamburger && (
        <HeaderSection>
          <div>
            <Nav>
              {menuItems.map((item, index) => (
                <CustomLink className="custom-link" key={index} to={item.route}>
                  {item.name}
                </CustomLink>
              ))}
            </Nav>
          </div>
        </HeaderSection>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.header`
  width: 100%;
`;
const HeaderSection = styled.div`
  border-bottom: 1px solid #ddd;
  padding-left: 1em;
  padding-right: 1em;
`;
const HeaderContainer = styled.div`
  max-width: 1100px;
  margin: 0 auto;
`;

const StyledHeader = styled.div`
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  margin-bottom: 1em;
  //padding: 25px 55px 25px;
  align-items: center;
`;
const HamburgerNav = styled.nav`
  position: initial;
  z-index: 1000;
  color: var(--primary-black);
  button {
    font-weight: 300;
    border: none;
    background: none;
    font-size: 1rem;
    color: var(--primary-black);
    span {
      color: var(--primary-black);
    }
  }
  .react-slidedown {
    transition-duration: 0.15s;
    transition-timing-function: ease-in-out;
  }
`;

const Nav = styled.nav`
  position: relative;
  display: flex;
  max-width: 1150px;
  width: 100%;
  margin: 1em auto;
  justify-content: space-between;
  .custom-link {
    display: flex;
    text-align: center;
    justify-content: center;
    flex: 1 1 0px;
    width: fit-content;
  }
  button {
    font-family: var(--font-family-book);
    font-weight: 300;
    border: none;
    background: none;
    font-size: 1rem;
  }
  .react-slidedown {
    transition-duration: 0.15s;
    transition-timing-function: ease-in-out;
  }
`;

const MenuContent = styled.div`
  position: absolute;
  background-color: #f3f3f3;
  top: 105px;
  @media screen and (max-width: 650px) {
    top: 70px;
  }
  left: 0;
`;

const Content = styled.div`
  margin: 1rem;
  width: 240px;
  display: flex;
  //gap: 20px;
  & > * + * {
    margin-top: 1rem;
  }
  flex-direction: column;
  @media screen and (max-width: 830px) {
    width: 100vw;
    left: 0;
  }
`;

const LogoWrapper = styled.div`
  height: 65px;
  @media screen and (max-width: 650px) {
    height: 30px;
  }
`;
const Logo = styled.img`
  height: 100%;
`;

const LogoLink = styled(Link)`
  &:focus img {
    outline: 5px solid var(--color-focus-outline);
    outline-offset: 10px;
    -moz-appearance: none;
    -webkit-appearance: none;
  }
`;

export default Header;
