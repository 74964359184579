export const periodToMonths = (period, units) => {
  if (period && period > 0) {
    switch (units) {
      case "days":
        return period / 30;
      case "months":
        return period || 1;
      case "years":
        return period * 12;
      default:
        return period;
    }
  }
  return 0;
};
