import {forwardRef, ReactElement, useState} from "react";
import styled from "styled-components";

import {useTranslation} from "react-i18next";
import useStore from "store/store";

import TabLink from "./TabLink";
import ProductDescription from "./ProductDescription";
import useIsMobile from "hooks/useIsMobile";
import Select, {components, GroupBase} from "react-select";
import {DropdownIndicatorProps} from "react-select/dist/declarations/src/components/indicators";
import {useMatomo} from "@jonkoops/matomo-tracker-react";

// Redecalare forwardRef
declare module "react" {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

interface ProductTabsProps {}

interface LinkWrapperProps {
  isMobile: boolean;
}

function ProductTabs(
  props: ProductTabsProps,
  ref: React.ForwardedRef<HTMLDivElement | null>
): ReactElement {
  const selectedProductType = useStore((state) => state.selectedProductType);
  const selectType = useStore((state) => state.selectType);
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { trackEvent } = useMatomo();
  const [selectMenuIsOpen, setSelectMenuIsOpen] = useState<boolean>(false);

  const options = [
    { value: "spot", label: "Spotpris" },
    { value: "fixed", label: "Fastpris" },
    { value: "custom", label: "Andre avtaler" },
    { value: "plus", label: "Plussavtaler" }
  ];

  const gotoTab = (productType : string) => {
    selectType(productType);
    trackEvent({
      category: "compare-products",
      action: "select-product-tab",
      name: productType,
    });
  }

  const CustomCaret = (
    props: JSX.IntrinsicAttributes &
      DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>
  ) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          fontSize: "0.875rem",
          pointerEvents: "none",
          color:
            selectedProductType !== "spot" ? "var(--primary-black)" : "#fff",
        }}
      >
        <components.DropdownIndicator {...props} />
        Endre type
      </div>
    );
  };

  return (
    <StyledProductTabs ref={ref}>
      {!isMobile && (
        <LinkWrapper isMobile={isMobile}>
          <TabLink
            text={t("spot-tablink")}
            color="spot-color"
            textColor="#fff"
            selected={selectedProductType === "spot"}
            onClick={() => gotoTab("spot")}
          />
          <TabLink
            text={t("fixed-tablink")}
            color="fixed-color"
            selected={selectedProductType === "fixed"}
            onClick={() => gotoTab("fixed")}
          />
          <TabLink
            text={t("plus-tablink")}
            color="plus-color"
            selected={selectedProductType === "plus"}
            onClick={() => gotoTab("plus")}
          />
          <TabLink
            text={t("custom-tablink")}
            color="custom-color"
            selected={selectedProductType === "custom"}
            onClick={() => gotoTab("custom")}
          />
        </LinkWrapper>
      )}
      {isMobile && (
        <SelectElement
          {...{ onchange, options }}
          defaultValue={{ value: options[0].value, label: options[0].label }}
          options={options}
          closeMenuOnSelect
          hideSelectedOptions
          isSearchable={false}
          onMenuOpen={() => setSelectMenuIsOpen(true)}
          onMenuClose={() => setSelectMenuIsOpen(false)}
          menuIsOpen={selectMenuIsOpen}
          onChange={(option : any) => {
            if (typeof option.value === "string") {
              gotoTab(option.value);
            }
          }}
          components={{ DropdownIndicator: CustomCaret }}
          styles={{
            option: (baseStyles, state) => ({
              ...baseStyles,
              minHeight: "82px",
              display: "flex",
              alignItems: "center",
              border: "1.5px solid var(--background-light-gray)",
              borderWidth: "2px",
              backgroundColor: "var(--primary-white)",
            }),
            indicatorsContainer: (baseStyles) => ({
              ...baseStyles,
              border: "1.5px solid",
              borderColor:
                selectedProductType === "spot"
                  ? "#fff"
                  : "var(--primary-black)",
              borderRadius: "5px",
              margin: "16px",
              width: "136px",
              height: "52px",
              display: "flex",
              alignItems: "center",
            }),
            indicatorSeparator: (baseStyles) => ({
              ...baseStyles,
              backgroundColor: "transparent",
            }),
            dropdownIndicator: (baseStyles, state) => ({
              ...baseStyles,
              transition: "all .2s ease",
              transform: state.selectProps.menuIsOpen
                ? "rotate(180deg)"
                : "null",
              color:
                selectedProductType === "spot"
                  ? "#fff"
                  : "var(--primary-black)",
            }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              border: "1.5px solid var(--primary-black)",
              borderBottom: state.selectProps.menuIsOpen ? "none" : "",
              borderRadius: "5px 5px 0 0",
              backgroundColor: `var(--${selectedProductType}-color)`,
              boxShadow: "none",
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              margin: "0",
              fontSize: "1.125rem",
            }),
            menuList: (baseStyles, state) => ({
              ...baseStyles,
              padding: "0",
              border: "1.5px solid var(--primary-black)",
              borderRadius: "0 0 5px 5px",
              boxShadow: state.selectProps.menuIsOpen
                ? "0px 4px 8px 0px #00000040"
                : "none",
            }),
            valueContainer: (baseStyles) => ({
              ...baseStyles,
              margin: "8px",
              fontSize: "1.125rem",
              fontFamily: "var(--font-family-news)",
              color: "#fff",
            }),
            singleValue: (baseStyles) => ({
              ...baseStyles,
              color:
                selectedProductType === "spot"
                  ? "#fff"
                  : "var(--primary-black)",
            }),
          }}
        />
      )}
      <Description style={{ zIndex: selectMenuIsOpen ? "0" : "99" }}>
        <ProductDescription selectedProductType={selectedProductType} />
      </Description>
    </StyledProductTabs>
  );
}

const SelectElement = styled(Select)`
  min-height: 80px;
  width: 100%;
  z-index: 10;
  & > * + * {
    min-height: 80px !important;
  }
`;

const LinkWrapper = styled.div<LinkWrapperProps>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  //height: ${(props) => (props.isMobile ? "52px" : "72px")};
  max-height: 4em;
  height: 100%;
  //gap: 5px;
  & > * + * {
    margin: 0 0 0 8px;
  }
`;

const Description = styled.div`
  display: flex;
  background-color: white;
  width: 100%;
  justify-content: center;
`;

const StyledProductTabs = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  max-width: 700px;
`;

export default forwardRef<HTMLDivElement, ProductTabsProps>(ProductTabs);
