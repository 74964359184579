import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  color?: string;
}

export const InfoIcon = ({ color = "var(--color-warning)" }: Props) => {
  return <FontAwesomeIcon color={color} icon={faInfoCircle} />;
};
