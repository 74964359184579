import styled from "styled-components";

interface QuestionAnswerProps {
  term: string;
  explanation: string[];
  ref?: string;
  id: string;
}

const GlossaryTerm = ({ term, explanation, ref, id }: QuestionAnswerProps) => {
  return (
    <>
      <div>
        <Term id={id}>{term}</Term>
        {explanation.map((p, index) => (
          <Explanation key={index} dangerouslySetInnerHTML={{ __html: p }} />
        ))}
      </div>
    </>
  );
};

const Term = styled.h2`
  margin: 0;
  font-size: 1.375rem !important;
`;

const Explanation = styled.p`
  margin: 0rem;
`;

export default GlossaryTerm;
