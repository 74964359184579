import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FlexLayout } from "../Layouts/FlexLayout";
import { Paragraph } from "../Typography/Paragraph/Paragraph";
import styles from "./styles.module.scss";

type Props = {
  severity: "info" | "notice" | "severe";
  text: string;
};

const NotificationBox = ({ text, severity }: Props) => {
  return (
    <div
      className={styles.notificationBox}
      style={{
        borderColor: severity === "severe" ? "var(--color-error)" : "#F6A624",
      }}
    >
      <FlexLayout direction="row" verticalAlign="center">
        <FontAwesomeIcon
          icon={faInfoCircle}
          color={severity === "severe" ? "var(--color-error)" : "#F6A624"}
          className={styles.icon}
        />
        <Paragraph size={12}>{text}</Paragraph>
      </FlexLayout>
    </div>
  );
};

export default NotificationBox;
