import useStore from "store/store";
import useProducts from "hooks/useProducts/useProducts";
import ProductTables from "./ProductTables/ProductTables";

interface IProducts {
  searchValue: string;
}

const Products = ({ searchValue }: IProducts) => {
  const filterShowShortSpot = useStore((state) => state.filterShowShortSpot);
  const filterShowMediumSpot = useStore((state) => state.filterShowMediumSpot);
  const selectedProductType = useStore((state) => state.selectedProductType);
  const selectedLocalGrid = useStore((state) => state.selectedLocalGrid);
  const yearlyConsumption = useStore((state) => state.yearlyConsumption);
  const { data: products } = useProducts(selectedLocalGrid, yearlyConsumption);

  return (
    <>
      <ProductTables
        products={products}
        selectedProductType={selectedProductType}
        searchTerm={searchValue}
        filterShowMediumSpot={filterShowMediumSpot}
        filterShowShortSpot={filterShowShortSpot}
      />
    </>
  );
};

export default Products;
