import {PriceType, ProductType} from "shared/types";

export const orderProducts = (products) => {

  function isBothPlusProductsWithPriceTypeSpot(a, b) {
    return a.productType === ProductType.PLUS &&
      a.priceType === PriceType.SPOT &&
      b.productType === ProductType.PLUS &&
      b.priceType === PriceType.SPOT
  }

  function isBothPlusProductsWithPriceTypeKW(a, b) {
    return a.productType === ProductType.PLUS &&
        a.priceType === PriceType.KW &&
        b.productType === ProductType.PLUS &&
        b.priceType === PriceType.KW
  }

  return [...products].sort((a, b) => {
    if (
      a.productType === ProductType.PURCHASE ||
      a.priceType === PriceType.ETTERSKUDDVIS ||
      a.priceType === PriceType.FORESPORSEL ||
      a.activeComplaint
    ) {
      return 1;
    } else if (
      b.productType === ProductType.PURCHASE ||
      b.priceType === PriceType.ETTERSKUDDVIS ||
      b.priceType === PriceType.FORESPORSEL ||
      b.activeComplaint
    ) {
      return -1;
    }

    let priceDiff;
    if (isBothPlusProductsWithPriceTypeSpot(a,b)) {
      priceDiff = a.effectiveAddon - b.effectiveAddon;
    }
    else if (isBothPlusProductsWithPriceTypeKW(a,b)) {
      priceDiff = a.finalPriceAddon - b.finalPriceAddon;
    }
    else {
      priceDiff = a.finalPriceMonthly - b.finalPriceMonthly;
    }

    if (priceDiff === 0) {
        return Math.random() < 0.5 ? -1 : 1;
    }
    return priceDiff;
  });
};
