import { useEffect } from "react";

function useOutsideAlerter(ref, onOutside) {
  useEffect(() => {
    /**
     * Call function if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutside();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onOutside]);
}

export default useOutsideAlerter;
