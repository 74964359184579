import i18n from "i18next";
import {PriceType, ProductType} from "./types";

export const CUSTOMERS_NEW_AND_EXISTING = "newAndExistingCustomers";
export const CUSTOMERS_NEW = "newCustomers";
export const CUSTOMERS_EXISTING = "existingCustomers";
export const CUSTOMERS_MEMBERS = "membersOnly";
export const CUSTOMERS_NEW_AND_MEMBERS = "newAndMembers";
export const CUSTOMERS_EXISTING_AND_MEMBERS = "existingAndMembers";
export const CUSTOMERS_ALL = "allCustomers";

export const FEE_TYPE_BEFORE = "forskudd";
export const FEE_TYPE_AFTER = "etterskudd";
export const FEE_TYPE_PERIOD = "i perioden";

export const NOTIFY_CHANNEL_EMAIL = "email";
export const NOTIFY_CHANNEL_SMS = "sms";
export const NOTIFY_CHANNEL_LETTER = "postal letter";
export const NOTIFY_CHANNEL_WEBSITE = "website";
export const NOTIFY_CHANNEL_LOGIN = "login website";
export const NOTIFY_CHANNEL_PHONE = "phone";

export const STANDARD_ALERT_SMS = "sms";
export const STANDARD_ALERT_EMAIL = "email";
export const STANDARD_ALERT_MY_SIDE = "myside";
export const STANDARD_ALERT_WEBSITE = "website";
export const STANDARD_ALERT_TELEPHONE = "telephone";
export const STANDARD_ALERT_MAIL = "mail";

export const FEE_MANDATORY_NONE = "feeMandatoryNone";
export const FEE_MANDATORY_NOT_UPDATED = "feeMandatoryNotUpdated";
export const FEE_MANDATORY_EFAKTURA = "feeMandatoryEfaktura";
export const FEE_MANDATORY_AVTALEGIRO = "feeMandatoryAvtalegiro";
export const FEE_MANDATORY_BOTH = "feeMandatoryBoth";
export const FEE_MANDATORY_APPLIED = [
  FEE_MANDATORY_BOTH,
  FEE_MANDATORY_AVTALEGIRO,
  FEE_MANDATORY_EFAKTURA,
];

export const feeMandatoryApplied = (product) => {
  return FEE_MANDATORY_APPLIED.indexOf(getFeeMandatory(product)) !== -1;
};

export function getFeeMandatoryWarningText(product) {
  let feeMandatoryWarningText = "";
  switch (getFeeMandatory(product)) {
    case FEE_MANDATORY_EFAKTURA:
      feeMandatoryWarningText = i18n.t("feeMandatoryEfakturaWarning");
      break;
    case FEE_MANDATORY_AVTALEGIRO:
      feeMandatoryWarningText = i18n.t("feeMandatoryAvtalegiroWarning");
      break;
    case FEE_MANDATORY_BOTH:
      feeMandatoryWarningText = i18n.t("feeMandatoryBothWarning");
      break;
    default:
      break;
  }
  return feeMandatoryWarningText;
}

export function isProductSpotprice(product) {
  return (
    product.productType === ProductType.SPOT ||
    product.productType === ProductType.PURCHASE ||
    product.productType === ProductType.HOURLY_SPOT ||
    ((product.productType === ProductType.OTHER || product.productType === ProductType.PLUS) &&
      product.priceType === PriceType.SPOT)
  );
}
export function isProductKwPrice(product) {
  return (
    product.productType === ProductType.FIXED ||
    product.productType === ProductType.VARIABLE ||
    ((product.productType === ProductType.OTHER | product.productType === ProductType.PLUS) &&
      product.priceType === PriceType.KW)
  );
}

export function getFeeMandatory(product) {
  if (product) {
    if (product.feeEFakturaMandatory && product.feeAvtalegiroMandatory) {
      return FEE_MANDATORY_BOTH;
    } else if (product.feeEFakturaMandatory) {
      return FEE_MANDATORY_EFAKTURA;
    } else if (product.feeAvtalegiroMandatory) {
      return FEE_MANDATORY_AVTALEGIRO;
    } else {
      return FEE_MANDATORY_NONE;
    }
  }
  return FEE_MANDATORY_NOT_UPDATED;
}
