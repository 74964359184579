import NoStyleButton from "components/Button/NoStyleButton";
import { Paragraph } from "components/Typography/Paragraph/Paragraph";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

interface ITooltipView {
  text?: string | null;
  title?: string | null;
  customContent?: JSX.Element | null;
}
const TooltipView = ({ text, title, customContent }: ITooltipView) => {
  const { t } = useTranslation();
  return (
    <div className={styles.tooltipText}>
      {title && <span className={styles.title}>{title}</span>}
      {text && <span className={styles.info}>{text}</span>}
      {customContent && customContent}
      <div style={{ width: "fit-content" }}>
        <NoStyleButton noPadding>
          <Paragraph size={12} link>
            {t("close")}
          </Paragraph>
        </NoStyleButton>
      </div>
    </div>
  );
};

export default TooltipView;
