import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Paragraph } from "components/Typography/Paragraph/Paragraph";

import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { BlockLink } from "styles/Buttons";
import styles from "./styles.module.scss";

interface IGoNextButton {
  product: any | undefined;
  onBackClick: () => void;
  withBackButton: boolean;
  goNext: () => void;
  scrollTarget: any;
  scrollTargetOnScreen: boolean;
  trackEvent: ({
    category,
    action,
    name,
  }: {
    category: string;
    action: string;
    name: string;
  }) => void;
  eventName: string;
  buttonText: string;
  withIcon: boolean;
  customIcon: any;
}

export const GoNextButton = ({
  product,
  goNext,
  withBackButton,
  onBackClick,
  scrollTarget,
  scrollTargetOnScreen,
  trackEvent,
  eventName,
  buttonText,
  withIcon,
  customIcon,
}: IGoNextButton) => {
  const { t } = useTranslation();
  return (
    <div className={styles.buttonView}>
      {withBackButton ? (
        <button className={styles.goBackButton} onClick={() => onBackClick()}>
          <Paragraph asLink>{t("back")}</Paragraph>
        </button>
      ) : (
        <div />
      )}
      {!product.expired && (
        <OrderButton
          onClick={() => {
            goNext();
            trackEvent({
              category: "ordering",
              action: "open-ordering-component",
              name: eventName,
            });
            if (scrollTarget.current && !scrollTargetOnScreen) {
              scrollTarget.current.scrollIntoView({
                behavior: "auto",
              });
            }
          }}
        >
          <div className={styles.buttonWithTextAndArrow}>
            {buttonText}
            {withIcon && (
              <FontAwesomeIcon
                icon={customIcon || faArrowRight}
                className={styles.arrowIcon}
                style={{ verticalAlign: "middle" }}
                transform={"grow-4"}
              />
            )}
          </div>
        </OrderButton>
      )}
    </div>
  );
};

const OrderButton = styled(BlockLink)`
  width: fit-content;
  padding: 8px 24px;
`;
