import { Link, useMatch, useResolvedPath } from "react-router-dom";
import type { LinkProps } from "react-router-dom";
import styled from "styled-components";

function CustomLink({ children, to, ...props }: LinkProps) {
  let resolved = useResolvedPath(to);
  let match = useMatch({
    path: resolved.pathname,
    end: resolved.pathname === "/",
  });

  return (
    <StyledLink $active={Boolean(match)} to={to} {...props}>
      {children}
    </StyledLink>
  );
}

interface StyledLinkProps {
  $active: boolean;
}

const StyledLink = styled(Link)<StyledLinkProps>`
  color: var(--primary-black);
  text-decoration-color: var(--underline-color);
  text-underline-position: under;
  text-decoration: none;
  font-family: ${(props) =>
    props.$active ? "var(--font-family-news)" : "var(--font-family-book)"};
`;

export default CustomLink;
