import {ReactElement, useRef} from "react";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFrown} from "@fortawesome/pro-solid-svg-icons";

import {
  FEE_TYPE_BEFORE,
  FEE_TYPE_PERIOD,
} from "shared/classificators";

import {EmbeddableGridRow, RowWrapper} from "./styles";

import {FIXED_ID} from "../../shared/constants";

import {useTranslation} from "react-i18next";
import {formatMoney, formatPeriodShort,} from "../../shared/formatter";
import {PriceType, ProductType} from "shared/types";

interface Props {
  product: any | undefined;
  productType: string;
  searchTerm: string;
}

function EmbeddableProduct({ product, productType }: Props): ReactElement {
  const { t } = useTranslation();

  const scrollTarget = useRef<HTMLDivElement | null>(null);

  const showPrice = (product: any) => {
    if (product.activeComplaint) {
      return (
        <FontAwesomeIcon
          icon={faFrown}
          size="2x"
          style={{ verticalAlign: "middle" }}
          transform={"grow-4"}
          color="var(--color-error)"
          title={t("activeComplaintWarning")}
        />
      );
    }

    if (product.productType === ProductType.PURCHASE) {
      return (
        <FontAwesomeIcon
          icon={faFrown}
          size="2x"
          style={{ verticalAlign: "middle" }}
          transform={"grow-4"}
          color="var(--color-error)"
          title={t("purchaseNoPriceWarning")}
        />
      );
    }

    if (product.priceType === PriceType.ETTERSKUDDVIS) {
      return "Ukjent";
    }

    const price = formatMoney(product.finalPriceMonthly)

    if (
      product.feeType === FEE_TYPE_BEFORE ||
      product.feeType === FEE_TYPE_PERIOD
    ) {
      return (
        <>
          {price}
          <FontAwesomeIcon
            icon={faFrown}
            size="2x"
            style={{ verticalAlign: "middle" }}
            transform={"right-8 grow-4"}
            color="var(--color-error)"
            title={t("advancePaymentWarning")}
          />
        </>
      );
    }

    return price;
  };

  const agreementTime = formatPeriodShort(
    product.agreementTime,
    product.agreementTimeUnits
  );
  const agreementTimeText = (
    <span>{agreementTime === "0" ? "Ingen bindingstid" : agreementTime}</span>
  );

  const addonPriceMinimumFixedTime =
    product.addonPriceMinimumFixedFor !== null
      ? formatPeriodShort(
          product.addonPriceMinimumFixedFor,
          product.addonPriceMinimumFixedForUnits
        )
      : null;

  const showAddonPriceMinimumFixedTime =
    addonPriceMinimumFixedTime === null ||
    addonPriceMinimumFixedTime === "0" ? (
      <span title={t("productListAddonFixedTimeBelow1Tooltip")}>
        {t("under1Month")}
      </span>
    ) : (
      <span
        title={
          product.addonPriceMinimumFixedForInMonths < 6
            ? t("productListAddonFixedTimeBelow6Tooltip")
            : t("productListAddonFixedTime6OrMoreTooltip")
        }
      >
        {addonPriceMinimumFixedTime}
      </span>
    );

  return (
    <RowWrapper expanded={false}>
      <EmbeddableGridRow ref={scrollTarget}>
        <div>
          <div style={{ wordBreak: "break-word" }}>{product.productName}</div>
          <SupplierName>{product.organizationName}</SupplierName>
        </div>
        <div style={{ textAlign: "center" }}>
          {productType === FIXED_ID
            ? agreementTimeText
            : showAddonPriceMinimumFixedTime}
        </div>
        <div style={{ textAlign: "right" }}>
          {showPrice(product)}
        </div>
      </EmbeddableGridRow>
    </RowWrapper>
  );
}
const SupplierName = styled.div`
  font-size: 0.9em;
  font-family: var(--font-family-book);
`;

export default EmbeddableProduct;
