import { faWindowRestore } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CompareProductsContext } from "contexts/useCompareProducts";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

const ToggleButton = (props: any) => {
  const { onClick, isToggled, icon } = props;
  const { toggled, toggleCompareProducts } = useContext(CompareProductsContext);
  const { t } = useTranslation();

  return (
    <button
      className={`${styles.toggleContainer} ${
        toggled && styles.isToggledStyle
      }`}
      style={{ backgroundColor: toggled || isToggled ? "#CCEADE" : "#e2e7e9" }}
      onClick={() => (onClick ? onClick() : toggleCompareProducts(!toggled))}
      title={t("compareProducts")}
    >
      <div
        className={`${styles.toggleHandle} ${
          (toggled || isToggled) && styles.toggled
        }`}
      >
        <FontAwesomeIcon
          icon={icon ? icon : faWindowRestore}
          color={"#333333"}
        />
      </div>
    </button>
  );
};

export default ToggleButton;
