import { FlexLayout } from "components/Layouts/FlexLayout";
import { Paragraph } from "components/Typography/Paragraph/Paragraph";
import { CompareProductsContext } from "contexts/useCompareProducts";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ToggleButton from "./ToggleButton/ToggleButton";
import useIsMobile from "hooks/useIsMobile";
import styles from "./styles.module.scss";
import Button from "components/Button/Button";
import NoStyleButton from "components/Button/NoStyleButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/pro-regular-svg-icons";

interface Props {
  showCompareWarning?: boolean;
}

const CompareProducts = ({ showCompareWarning }: Props) => {
  const { t } = useTranslation();
  const {
    toggled,
    productsToCompare,
    setShowCompareView,
    showCompareView,
    toggleCompareProducts
  } = useContext(CompareProductsContext);
  const isMobile = useIsMobile();

  const getCorrectLabel = () => {
    if (showCompareWarning) {
      return t("compareProductsWarning");
    }
    if (productsToCompare.length === 0) return t("noProductsChosen");
    if (productsToCompare.length === 1)
      return t("onlyOneAddedPleasePickAtleastOneMore");
    else {
      return t("productsAdded", { numberOfProducts: productsToCompare.length });
    }
  };

  useEffect(() => {
    if (showCompareView) window.scrollTo(0, 0);
  }, [showCompareView]);

  const compareProducts = () => {
    setShowCompareView(true);
  };

  return (
    <div>
      <FlexLayout
        horizontalAlign="flex-end"
        direction="row"
        verticalAlign="center"
      >
        <Paragraph>{t("compareProducts")}</Paragraph>
        <ToggleButton />
      </FlexLayout>
      <div
        tabIndex={-1}
        className={`${styles.compareProductsContainer} ${
          toggled && styles.toggledContainer
        }`}
      >
        <FlexLayout
          direction="row"
          verticalAlign="center"
          {...(!showCompareWarning && {
            horizontalAlign: "space-between",
            wrap: "wrap"
          })}
        >
          {showCompareWarning &&
          <FontAwesomeIcon
              icon={faInfoCircle}
              color={`var(--color-error)`}
              fontSize={24}
          />
          }
          <Paragraph tabIndex={toggled ? 0 : -1}>{getCorrectLabel()}</Paragraph>
          {!showCompareWarning &&
          <FlexLayout
            direction="row"
            verticalAlign="center"
            gap={32}
            horizontalAlign="space-between"
            customStyle={{ width: isMobile ? "100%" : "auto" }}
          >
            <NoStyleButton onClick={toggleCompareProducts} noTab={!toggled}>
              <Paragraph tabIndex={toggled ? 0 : -1}>{t("cancel")}</Paragraph>
            </NoStyleButton>
            <Button
              tabIndex={toggled ? 0 : -1}
              onClick={() => productsToCompare.length > 1 && compareProducts()}
              text={t("compare")}
              disabled={productsToCompare.length < 2}
              useDisableStyle
              customStyle={{ color: "#fff" }}
            />
          </FlexLayout>
          }
        </FlexLayout>
      </div>
    </div>
  );
};

export default CompareProducts;
