import styles from "./styles.module.scss";

const NoStyleButton = (props: any) => {
  const { children, onClick, noTab, disabled, noPadding, ariaLabel } = props;
  return (
    <button
      className={styles.noStyleButton}
      onClick={onClick}
      type="button"
      tabIndex={noTab ? -1 : 0}
      style={{
        cursor: disabled ? "not-allowed" : "pointer",
        padding: noPadding ? "0px" : "8px",
      }}
      {...(ariaLabel && { "aria-label": ariaLabel })}
    >
      {children}
    </button>
  );
};

export default NoStyleButton;
