import styled from "styled-components";

interface GridTableProps {
  readonly productType: string;
}
export const GridTable = styled.div<GridTableProps>`
  font-size: clamp(0.875rem, 2.5vw, 1rem);
  align-items: center;
  font-family: var(--font-family-news);
  max-width: 830px;
  margin: 0 auto;
  --product-color-primary: var(--${(props) => props.productType}-color);
  --product-color-secondary: var(--${(props) => props.productType}-color-light);
`;

export const GridRow = styled.div`
  display: grid;
  padding: 10px;
  align-items: center;
  min-height: 92px;
  grid-gap: 3px;
  grid-template-columns: 2fr 6ch 2fr 2fr 4ch 2fr;
  @media (max-width: 830px) {
    grid-template-columns: 2fr 6ch 2fr 2fr 4ch;
  }
`;

export const EmbeddableGridRow = styled(GridRow)`
  grid-template-columns: 1fr 1fr 1fr;
  min-height: 62px;
`;

export const GridHeaderRow = styled(GridRow)`
  border-radius: 5px 5px 0 0;
  background-color: var(--product-color-primary);
  border-bottom: 1px solid var(--product-color-secondary);
  position: sticky;
  top: 0px;
`;

export const EmbeddableGridHeaderRow = styled(EmbeddableGridRow)`
  background-color: var(--product-color-primary);
  border-bottom: 1px solid var(--product-color-secondary);
  color: var(--primary-white);
`;

interface RowWrapperProps {
  readonly expanded: boolean;
}
export const RowWrapper = styled.div<RowWrapperProps>`
  border-bottom: 1px #cfeadf solid;
  background-color: ${(props) =>
    props.expanded ? "var(--product-color-primary)" : ""};
  transition: 0.25s;
  max-width: 100vw;
`;

export const OrderRow = styled.div`
  text-align: right;
  padding: 0px 10px 10px 10px;
`;
