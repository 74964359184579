import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagramSquare,
  faLinkedin,
  faTwitterSquare,
  faYoutubeSquare,
} from "@fortawesome/free-brands-svg-icons";
import { ExternalLinkIcon } from "components/Icons/ExternalLinkIcon";
import {
  faSquareRss,
  faSquareEnvelope,
  faSquareQuote,
} from "@fortawesome/pro-solid-svg-icons";
import styles from "./styles.module.scss";

const Footer = () => {
  return (
    <StyledFooter>
      <SectionWrapper>
        <FooterSection>
          <h2>Om Forbrukerrådet</h2>
          <ul>
            <li>
              <a href="/om-strompris" className={styles.a}>
                Om Strømpris
              </a>
            </li>
            <li>
              <a href="mailto:strompris@forbrukerradet.no" className={styles.a}>
                strompris@forbrukerradet.no
              </a>
            </li>
            <li>
              <a href="/datafanger" className={styles.a}>
                Logg inn (for strømselskaper)
              </a>
            </li>
            <li>
              <a
                href="https://uustatus.no/nb/erklaringer/publisert/38a130ff-8710-4dc8-9558-de76ab44eeff"
                rel="noreferrer"
                target="_blank"
                className={styles.a}
              >
                Tilgjengelighetserklæring&nbsp;
                <ExternalLinkIcon />
              </a>
            </li>
            <li>
              <a
                href="https://www.forbrukerradet.no/personvernerklaering/"
                rel="noreferrer"
                target="_blank"
                className={styles.a}
              >
                Personvernerklæring&nbsp;
                <ExternalLinkIcon />
              </a>
            </li>
          </ul>
        </FooterSection>

        <FooterSection>
          <h2>Våre nettsider</h2>
          <ul>
            <li>
              <a
                href="http://www.forbrukerradet.no/"
                rel="noreferrer"
                target="_blank"
                className={styles.a}
              >
                forbrukerradet.no&nbsp;
                <ExternalLinkIcon />
              </a>
            </li>
            <li>
              <a
                href="http://www.finansportalen.no/"
                rel="noreferrer"
                target="_blank"
                className={styles.a}
              >
                finansportalen.no&nbsp;
                <ExternalLinkIcon />
              </a>
            </li>
          </ul>
        </FooterSection>
      </SectionWrapper>
      <SocialMedia>
        <li>
          {" "}
          <a
            href="http://facebook.com/forbrukerradet/"
            title="Lenke til Forbrukerrådet på Facebook"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faFacebookSquare} className="link-icon" />
          </a>
        </li>
        <li>
          {" "}
          <a
            href="http://twitter.com/#!/Forbrukerradet/"
            title="Lenke til Forbrukerrådet på Twitter"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faTwitterSquare} className="link-icon" />
          </a>
        </li>
        <li>
          {" "}
          <a
            href="https://www.instagram.com/forbrukerradet/"
            title="Lenke til Forbrukerrådet på Instagram"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faInstagramSquare} className="link-icon" />
          </a>
        </li>
        <li>
          {" "}
          <a
            href="https://www.youtube.com/channel/UCDzoA3zQkVmHJ10QvTqRKSQ/"
            title="Lenke til Forbrukerrådet på YouTube"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faYoutubeSquare} className="link-icon" />
          </a>
        </li>
        <li>
          {" "}
          <a
            href="https://www.linkedin.com/company/forbrukerradet/"
            title="Lenke til Forbrukerrådet på LinkedIn"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedin} className="link-icon" />
          </a>
        </li>
        <li>
          {" "}
          <a
            href="https://www.forbrukerradet.no/podcast-life-hacks/"
            title="Lenke til Forbrukerrådets podcast"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faSquareQuote} className="link-icon" />
          </a>
        </li>
        <li>
          {" "}
          <a
            href="https://www.forbrukerradet.no/nyhetsvarsling/"
            title="Lenke til Forbrukerrådets nyhetsvarsel"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faSquareEnvelope} className="link-icon" />
          </a>
        </li>
        <li>
          {" "}
          <a
            href="https://www.forbrukerradet.no/feed/"
            title="Lenke til Forbrukerrådets RSS-feed"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faSquareRss} className="link-icon" />
          </a>
        </li>
      </SocialMedia>
      <p>Copyright Forbrukerrådet 2023</p>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  // background-color: #f3f3f3;
  display: flex;
  justify-self: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 320px;
  width: 70vw;
  max-width: 1100px;
  margin-top: 128px;
  @media screen and (max-width: 770px) {
    margin: 0 24px;
  }
  padding-top: 20px;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  width: 100%;
  @media screen and (max-width: 770px) {
    flex-direction: column;
    width: 90%;
  }
  justify-content: space-around;
`;

const FooterSection = styled.div`
  flex: 1;
  width: 100%;
  padding: 0;
  margin: 0 32px;
  @media screen and (max-width: 770px) {
    margin: 0;
  }
  h2 {
    border-bottom: 2px solid var(--underline-color);
    padding-bottom: 8px;
    width: 100%;
    font-size: 1.15rem !important;
    margin: 0;
    @media screen and (max-width: 770px) {
      margin-top: 40px;
    }
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    margin-bottom: 12px;
  }
`;

const SocialMedia = styled.ul`
  width: 100%;
  max-width: 420px;
  display: flex;
  justify-content: space-evenly;
  margin: 40px 24px 8px 24px;
  list-style-type: none;
  padding: 0;

  .link-icon {
    font-size: 32px;
    color: var(--primary-light-blue);

    @media screen and (max-width: 770px) {
      font-size: 26px;
    }
  }

  .link-icon:hover {
    @media (hover: hover) {
      color: var(--primary-dark-blue);
    }
  }
`;

export default Footer;
