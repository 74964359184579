import { FlexLayout } from "components/Layouts/FlexLayout";
import { BlockLink } from "styles/Buttons";
import { TextInput } from "styles/Forms";
import { Spinner } from "styles/Spinner";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useState } from "react";
import styled from "styled-components";
import { Paragraph } from "components/Typography/Paragraph/Paragraph";
import { ActionSuccess } from "../ActionSuccess/ActionSuccess";
import styles from "./styles.module.scss";
import NoStyleButton from "components/Button/NoStyleButton";
import { getProductMsApiHost } from "shared/ApiUtils";

type FormValues = {
  phone: string;
};

const SmsWarning = ({ product }: any) => {
  const { trackEvent } = useMatomo();
  const { t } = useTranslation();
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [detailsSent, setDetailsSent] = useState<boolean>(false);
  const [skipped, setSkipped] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  // prepending 'd-' to product-id in event-name for legacy reasons
  const eventName = `d-${product.productId} - ${product.productName}`;
  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setShowSpinner(true);

    try {
      let response = await fetch(`${getProductMsApiHost()}/strompris/products/${product.productId}/reminders`, {
        body: data.phone,
        mode: "cors",
        headers: {
          "Content-Type": "plain/text"
        },

        method: "post",
      });

      trackEvent({
        category: "ordering",
        action: "sms-notification-requested",
        name: eventName,
      });

      setShowSpinner(false);
      if (response.status === 200) {
        setDetailsSent(true);
      } else {
        setError(true);
      }
    } catch (e) {
      setShowSpinner(false);
      setError(true);
    }
  };

  if (product.addonPriceMinimumFixedForInMonths === 0) return null;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FlexLayout gap={16} direction="row">
        <ActionSuccess isSuccess={detailsSent} />
        <div className={styles.border}>
          {!skipped && !detailsSent && (
            <FlexLayout horizontalAlign={"flex-end"} direction="row">
              <NoStyleButton onClick={() => setSkipped(true)}>
                <Paragraph asLink news size={12}>
                  {t("skip")}
                </Paragraph>
              </NoStyleButton>
            </FlexLayout>
          )}
          {skipped || detailsSent ? (
            <FlexLayout>
              {detailsSent
                ? t("smsWarningWillBeSent")
                : t("youHaveSkippedWarning")}
              {detailsSent && (
                  <FlexLayout horizontalAlign={"flex-start"} direction="row">
                    <Paragraph size={12}>{t("smsWarningWillBeSentMessage")}</Paragraph>
                  </FlexLayout>
              )}
              {!detailsSent && (
                <FlexLayout horizontalAlign={"flex-start"} direction="row">
                  <NoStyleButton onClick={() => setSkipped(false)}>
                    <Paragraph asLink news size={12}>
                      {t("regret")}
                    </Paragraph>
                  </NoStyleButton>
                </FlexLayout>
              )}
            </FlexLayout>
          ) : (
            <FlexLayout>
              <Paragraph size={16} wrap>
                {t("getSmsWarning")}
              </Paragraph>
              <Paragraph size={12}>
                {t("agreementGuaranteesForMonths", {
                  months: product.addonPriceMinimumFixedForInMonths,
                })}
              </Paragraph>
              <Paragraph size={12} wrap>
                {t("forbrukerradetSmsWhenExpires")}
              </Paragraph>
              <FlexLayout gap={1}>
                {!detailsSent && (
                  <fieldset
                    style={{
                      maxWidth: "15rem",
                      padding: 0,
                    }}
                  >
                    <label>
                      <Paragraph
                        htmlFor="phone"
                        size={12}
                        error={!!errors.phone}
                      >
                        {t("yourPhoneNumber")}
                      </Paragraph>
                      <TextInput
                        {...register("phone", {
                          required: {
                            value: true,
                            message: `${t("inputError.required")}`,
                          },
                          pattern: {
                            value: /^[0-9]{8}$/,
                            message: t("inputError.patternPhone"),
                          },
                        })}
                        className={`${errors.phone && styles.errorBorder}`}
                        id="phone"
                        type="tel"
                        inputMode="numeric"
                        style={{ width: "100%" }}
                        placeholder="Mobilnummer"
                        autoComplete="tel-national"
                      />
                    </label>
                    {errors.phone && (
                      <div className={styles.errorField}>
                        {errors?.phone?.message}
                      </div>
                    )}
                  </fieldset>
                )}
              </FlexLayout>
              <div className={styles.margin}>
                {!showSpinner ? (
                  <>
                    {detailsSent && (
                      <Paragraph size={12}>
                        {t("informationForCancelWarnings")}
                      </Paragraph>
                    )}
                    {error && (
                      <div className={styles.marginBottom}>
                        <Paragraph size={12} error news>
                          {t("somethingWentWrong")} {t("pleaseTryAgain")}
                        </Paragraph>
                      </div>
                    )}
                    {!detailsSent && (
                      <>
                        <OrderButton>{t("sendMeSms")}</OrderButton>
                      </>
                    )}
                  </>
                ) : (
                  <Spinner />
                )}
              </div>
            </FlexLayout>
          )}
        </div>
      </FlexLayout>
    </form>
  );
};

const OrderButton = styled(BlockLink)`
  max-width: 100%;
`;

export default SmsWarning;
