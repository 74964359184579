import styled from "styled-components";

export const RouteWrapper = styled.div`
  padding: 0 30px;
  max-width: 1100px;
  margin: 0 auto;
  width: 100vw;
  overflow-wrap: break-word;
  word-wrap: break-word;
`;

export const TextSection = styled.section`
  margin: 0 auto;
  max-width: 700px;
`;

export const Centered = styled.div`
  text-align: center;
`;

export const LeadParagraph = styled.p`
  text-align: center;
  font-size: 1.625rem;

  margin: 0 auto 1em auto;
  @media screen and (max-width: 770px) {
    margin: 0.5em auto 1em auto;
    font-size: 1.25rem;
  }
`;

export const Overlay = styled.div`
  background-color: rgba(255, 255, 255, 0.9);
  position: fixed;
  z-index: 1000;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  text-align: center;
`;
