import { RouteWrapper, TextSection } from "styles/General";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useEffect } from "react";
import { Paragraph } from "components/Typography/Paragraph/Paragraph";
import { FlexLayout } from "./Layouts/FlexLayout";

const About = () => {
  const { trackPageView } = useMatomo();
  useEffect(() => {
    trackPageView({});
  }, [trackPageView]);

  return (
    <RouteWrapper lang="nn-NO" style={{ maxWidth: "700px" }}>
      <FlexLayout
        horizontalAlign="center"
        verticalAlign="center"
        customStyle={{ marginTop: "1.8rem" }}
      >
        <Paragraph news size={32} asHeading>
          Om Strømpris
        </Paragraph>
        <Paragraph size={18} customStyle={{ textAlign: "center" }}>
          Strømpris.no er ei gratis teneste frå Forbrukarrådet som gjer det
          enkelt for deg å samanlikne prisar og finne dei beste avtalane.
        </Paragraph>
        <TextSection>
          <p>
            Når du har funne ein avtale på Strømpris, kan du klikke deg vidare
            på lenka til straumselskapet. Vi rådar deg til å sjekke at avtalen
            hos selskapet stemmer overeins med informasjon på Strømpris.no før
            du bind deg. Det tek vanlegvis 3-4 minutt å byte straumavtale og ca
            14 dagar før ny avtale trer i kraft.
          </p>
          <p>
            Straumselskapa er forplikta til å rapportere inn avtalane sine til
            Strømpris.no. Plikta har heimel i forskrift om rapporteringsplikt
            for kraftleveringsavtalar. Rapporteringa skjer av selskapa via ei
            eiga innlogging. Selskapa er sjølve ansvarlege for at avtalane er
            korrekt rapporterte.
          </p>
          <p>
            OBS! Du inngår ikkje avtale med Forbrukarrådet eller Strømpris.no
            når du vel ei straumavtale i denne tenesta, men med straumselskapet
            som tilbyr avtalen.
          </p>
        </TextSection>
      </FlexLayout>
    </RouteWrapper>
  );
};

export default About;
