import {ReactElement, useContext, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {SlideDown} from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare, faInfoCircle,} from "@fortawesome/pro-regular-svg-icons";

import styles from "./styles.module.scss";

import {
  getFeeMandatory,
} from "shared/classificators";
import useIsMobile from "hooks/useIsMobile";
import {useTranslation} from "react-i18next";
import {formatMoney} from "../../../shared/formatter";
import {ConditionsTable} from "./ProductDetails/ConditionsTable/ConditionsTable";
import {CalculationsTable} from "./ProductDetails/CalculationTable/CalculationsTable";
import useOnScreen from "hooks/useOnScreen";
import {useMatomo} from "@jonkoops/matomo-tracker-react";
import Ordering from "./ProductDetails/Ordering/Ordering";
import loadable from "@loadable/component";
import InformationChip from "./informationChip/InformationChip";
import ExpandContentButton from "./expandContentButton/ExpandContentButton";
import {GoNextButton} from "./goNextButton/GoNextButton";
import {FlexLayout} from "components/Layouts/FlexLayout";
import {CompareProductsContext} from "contexts/useCompareProducts";
import CompareProductsHeader from "../CompareProducts/compareProductsHeader/CompareProductsHeader";
import {
  displayPrice,
  displaySpotPrice,
  dotToComma,
  getPurchaseDisplayPrice,
  isSpotPrice,
  onlyThreeDecimals,
  oreToKroner
} from "utils/priceUtils";
import {periodToMonths} from "hooks/useProducts/staticsCalculator";
import {PriceType, ProductType} from "shared/types";

const PDFDownloadButton = loadable(
  () => import("./ProductDetails/Ordering/PdfDownload/PDFDownloadButton")
);

interface Props {
  product: any | undefined;
  productType: string;
  selectedProductId?: string;
}

function Product({
  product,
  productType,
  selectedProductId,
}: Props): ReactElement {
  const [expanded, setExpanded] = useState(false);
  const [hasBeenExpanded, setHasBeenExpanded] = useState(false);
  const [showOrdering, setShowOrdering] = useState<boolean>(false);
  const [showSmsAndOrder, setShowSmsAndOrder] = useState<boolean>(false);
  const { trackEvent } = useMatomo();
  const { toggled, setSelectedProductId, setCanFocusPdfButton, isProductComparable } = useContext(
    CompareProductsContext
  );
  const eventName = `${product.productId} - ${product.productName}`;
  const isMobile = useIsMobile();

  const toggleExpanded = () => {
    setExpanded(!expanded);
    setShowOrdering(false);
    setShowSmsAndOrder(false);

    if (!hasBeenExpanded) {
      setHasBeenExpanded(true);
      PDFDownloadButton.preload();
    }

    if (
      expanded &&
      selectedProductId &&
      selectedProductId === product.productId
    ) {
      setSelectedProductId();
    }
  };

  const scrollTarget = useRef<HTMLDivElement | null>(null);
  const scrollTargetOnScreen = useOnScreen(scrollTarget);

  useEffect(() => {
    if (selectedProductId && selectedProductId === product.productId) {
      setShowSmsAndOrder(true);
      setExpanded(true);
      setHasBeenExpanded(true);

      let scrollTimeout: any;
      const scrollEventListener = () => {
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(function () {
          setCanFocusPdfButton(true);
          window.removeEventListener("scroll", scrollEventListener);
        }, 100);
      };

      window.addEventListener("scroll", scrollEventListener);
      if (scrollTarget.current && !scrollTargetOnScreen) {
        scrollTarget.current.scrollIntoView({
          behavior: "smooth",
        });
      }
      setSelectedProductId(undefined);
    }
  }, [selectedProductId, scrollTarget, scrollTargetOnScreen]); //eslint-disable-line

  const { t } = useTranslation();

  const getColumnThreeContent = (product: any) => {
    if (product.activeComplaint) {
      return (
        <FlexLayout direction="row" verticalAlign="center">
          <FontAwesomeIcon
            icon={faInfoCircle}
            style={{ verticalAlign: "middle" }}
            color="var(--color-error)"
            title={t("activeComplaintWarning")}
          />
          {t("noPrice")}
        </FlexLayout>
      );
    }

    if (product.productType === ProductType.PURCHASE) {
      return (
        <FlexLayout direction="row" verticalAlign="center">
          <FontAwesomeIcon
            icon={faInfoCircle}
            style={{ verticalAlign: "middle" }}
            color="var(--color-error)"
            title={t("purchaseNoPriceWarning")}
          />
          {t("noPrice")}
        </FlexLayout>
      );
    }

    if (product.priceType === PriceType.ETTERSKUDDVIS) {
      return (
        <FlexLayout direction="row" verticalAlign="center">
          <FontAwesomeIcon
            icon={faInfoCircle}
            style={{ verticalAlign: "middle" }}
            color="var(--color-error)"
            title={t("postPeriodPricedWarning")}
          />
          {t("noPrice")}
        </FlexLayout>
      );
    }

    if (product.priceType === PriceType.FORESPORSEL) {
      return "Pris kun på forespørsel";
    }

    if (product.productType === ProductType.PLUS && product.priceType === PriceType.SPOT) {
      return displaySpotPrice(oreToKroner(product.effectiveAddon));
    }

    if (product.productType === ProductType.PLUS && product.priceType === PriceType.KW) {
      return displayPrice(oreToKroner(product.finalPriceAddon));
    }

    return formatMoney(product.finalPriceMonthly)
  };

  const getInformationChips = () => {
    return (
      <div className={styles.informationView}>
        {product.expired && (
          <InformationChip
            information={t("expired")}
            tipId={`product-tooltip-${product.productId}`}
            tipText={t("expiredWarning")}
            canTab
            severe
          />
        )}
        {product.productType === ProductType.SPOT && (
          <InformationChip
            information={t("spotPrice")}
            tipId={`product-tooltip-${product.productId}`}
            tipText={t("tags.spot")}
            canTab
            severe
          />
        )}
        {product.productType === ProductType.HOURLY_SPOT && (
          <InformationChip
            information={t("timeSpot")}
            tipId={`product-tooltip-${product.productId}`}
            tipText={t("tags.timespot")}
            canTab
          />
        )}
        {isSpotPrice(product) && (
          <InformationChip
            information={t(`termsGuaranteesForMonths`, {
              months: product.addonPriceMinimumFixedForInMonths,
            })}
            severe={product.addonPriceMinimumFixedForInMonths === 1}
            tipId={`product-tooltip-${product.productId}`}
            tipText={
              isSpotPrice(product)
                ? t("agreementGuaranteeInformation", {
                    months: product.addonPriceMinimumFixedForInMonths,
                  })
                : t("agreementGuaranteeInformationNonSpot", {
                    months: product.addonPriceMinimumFixedForInMonths,
                  })
            }
            canTab
          />
        )}
        { product.finalContractBreachFee > 0 &&
            <InformationChip
                information={t("highBreachFee")}
                tipId={`product-tooltip-${product.productId}`}
                tipText={t("breachFeeWarning")}
                severe
            />
        }
        <InformationChip
            { ...(product.agreementTime != null && product.agreementTime > 0 ? {
                information: t(`agreementTimeInMonths`, {
                  months: periodToMonths(
                    product.agreementTime,
                    product.agreementTimeUnits
                  )}),
                severe: true
            }
            : {
                information: t("noAgreementTime"),
                tipId: `product-tooltip-${product.productId}`,
                tipText: t("noBreachFee"),
            })}
        />
        {product.cabinProduct && (
          <InformationChip information={t("cabinProduct")} />
        )}
        {product.memberships.length > 0 && (
          <InformationChip information={t("membership")} />
        )}
        {product.feeType === "forskudd" && (
          <InformationChip
            information={t("preInvoice")}
            severe
            danger
            tipId={`product-tooltip-${product.productId}`}
            tipText={t("advancePaymentWarning")}
            withWarning
            canTab
          />
        )}
        {getFeeMandatory(product) === "feeMandatoryEfaktura" && (
          <InformationChip
            information={t("feeMandatoryEfaktura")}
            severe
            tipId={`feeMandatoryEfaktura`}
            tipText={t("feeMandatoryEfakturaWarning")}
            canTab
          />
        )}
        {(product.billingFrequencyUnits === "years" ||
          (product.billingFrequencyUnits === "months" &&
            product.billingFrequency > 11) ||
          (product.billingFrequencyUnits === "days" &&
            product.billingFrequency > 364)) && (
          <InformationChip information={t("invoiceOncePerYear")} severe />
        )}
      </div>
    );
  };

  const getColumnTwoContent = () => {
    if (productType === "spot") {
      return `${dotToComma(onlyThreeDecimals(product.effectiveAddon))} ${t(
        "orePerKwt"
      )}`;
    }
    else if (productType === "fixed") {
      return `${dotToComma(onlyThreeDecimals(product.finalPriceAddon))} ${t(
        "orePerKwt"
      )}`;
    } else if (productType === "custom") {
      return t(`termsGuaranteesForMonthsShort`, {
        months: product.addonPriceMinimumFixedForInMonths,
      });
    }
    else if (productType === "plus") {
      return getPurchaseDisplayPrice(product);
    }

  };

  const productUrlWithProtocol = /^((http|https):\/\/)/.test(product.orderUrl)
    ? product.orderUrl
    : "//" + product.orderUrl;

  const goBack = () => {
    if (showSmsAndOrder) {
      setShowSmsAndOrder(false);
      setShowOrdering(true);
    } else {
      setShowOrdering(false);
    }
  };

  const goNext = () => {
    if (showOrdering) {
      setShowOrdering(false);
      setShowSmsAndOrder(true);
    } else if (showSmsAndOrder) {
      window.open(productUrlWithProtocol, "_blank");
    } else {
      setShowOrdering(true);
    }
  };

  return (
    <div className={styles.marginTop}>
      {toggled && isProductComparable(product) && (
        <CompareProductsHeader product={product} scrollTarget={scrollTarget} />
      )}
      <div
        className={`${styles.cardLayout} ${isMobile && styles.mobileCardStyle}`}
        onClick={() => (!expanded ? toggleExpanded() : null)}
        style={{ cursor: !expanded ? "pointer" : "default" }}
      >
        <div
          ref={scrollTarget}
          className={styles.cardHeader}
          style={{
            marginBottom: !showOrdering && !showSmsAndOrder ? "24px" : "0px",
          }}
        >
          {!showOrdering && !showSmsAndOrder && (
            <>
              <div>
                <div style={{ wordBreak: "break-word" }}>
                  {product.productName}
                </div>
                <SupplierName>{product.organizationName}</SupplierName>
              </div>
              <div>{getColumnTwoContent()}</div>
              <div className={styles.totalPrice}>
                {getColumnThreeContent(product)}
              </div>
            </>
          )}
        </div>
        <FlexLayout>
          <div className={styles.cardContent}>
            {!showOrdering && !showSmsAndOrder ? (
              getInformationChips()
            ) : (
              <div />
            )}
          </div>
          <FlexLayout direction="row" horizontalAlign="flex-end">
            <ExpandContentButton
              expand={() => toggleExpanded()}
              isExpanded={expanded}
            />
          </FlexLayout>
        </FlexLayout>
        {hasBeenExpanded && (
          <DetailsCollapsible closed={!expanded}>
            {showOrdering ? (
              <ConditionsTable product={product} />
            ) : showSmsAndOrder ? (
              <Ordering
                product={product}
                goBackToCalculationPage={() => {
                  setShowOrdering(false);
                  setShowSmsAndOrder(false);
                }}
              />
            ) : (
              <CalculationsTable product={product} />
            )}
            <GoNextButton
              goNext={() => goNext()}
              product={product}
              withBackButton={showOrdering || showSmsAndOrder}
              onBackClick={() => goBack()}
              scrollTarget={scrollTarget}
              trackEvent={(event: any) => trackEvent(event)}
              eventName={eventName}
              scrollTargetOnScreen={scrollTargetOnScreen}
              withIcon
              customIcon={showSmsAndOrder && faArrowUpRightFromSquare}
              buttonText={
                showOrdering
                  ? t("goNextWithAgreement")
                  : showSmsAndOrder
                  ? t("finishOrderAtOrganizationPage")
                  : t("goToOrder")
              }
            />
          </DetailsCollapsible>
        )}
      </div>
    </div>
  );
}

const DetailsCollapsible = styled(SlideDown)`
  transition-duration: 0.15s;
`;

const SupplierName = styled.div`
  font-size: 0.9em;
  font-family: var(--font-family-book);
`;

export default Product;
