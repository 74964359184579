import Product from "components/Products/product/Product";
import ProductTableHeader from "components/Products/productTableHeader/ProductTableHeader";
import {GridTable} from "components/Products/styles";
import {CompareProductsContext} from "contexts/useCompareProducts";
import {useContext, useState} from "react";

interface IProductTableContent {
  orderedProducts: any;
  title: string | null;
  products: any;
  productType: string;
  priceType?: string | null;
}

const ProductTableContent = ({
  orderedProducts,
  title,
  products,
  productType,
  priceType,
}: IProductTableContent) => {
  const { selectedProductId } = useContext(CompareProductsContext);
  const [reversedOrder, setReversedOrder] = useState(false);
  const toggleReverseOrder = () => {
    setReversedOrder(!reversedOrder);
  }

  return (
    <div>
      <div style={{ marginTop: "10px" }}>
        {title && <h2>{title}</h2>}
        <GridTable productType={productType}>
          <ProductTableHeader  reversedOrder={reversedOrder} toggleReversedOrder={toggleReverseOrder} productType={productType} priceType={priceType} />
          {products &&
              (reversedOrder ? orderedProducts.toReversed() : orderedProducts).map((product: any, index: number) => {
              return (
                <Product
                  key={product.productId}
                  product={product}
                  productType={productType}
                  selectedProductId={selectedProductId}
                />
              );
            })}
        </GridTable>
      </div>
    </div>
  );
};

export default ProductTableContent;
