import styled from "styled-components";
import useIsMobile from "hooks/useIsMobile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { FlexLayout } from "components/Layouts/FlexLayout";
import { useTranslation } from "react-i18next";
import { Paragraph } from "components/Typography/Paragraph/Paragraph";
import styles from "./styles.module.scss";
import ReactTooltip from "react-tooltip";
import TooltipView from "components/Tooltip/TooltipView";
import { useEffect } from "react";
import Tooltip from "components/Tooltip/Tooltip";

interface ProductDescriptionProps {
  selectedProductType: string;
}

interface WrapperProps {
  color: string;
  isMobile?: boolean;
}

function ProductDescription({ selectedProductType }: ProductDescriptionProps) {
  const isMobile = useIsMobile();
  const { t: title } = useTranslation("translation", {
    keyPrefix: `${selectedProductType}-tab-description`,
  });
  const { t: tooltip } = useTranslation("translation", {
    keyPrefix: `tooltips`,
  });

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [selectedProductType]);

  return (
    <Wrapper color={`var(--${selectedProductType}-color)`}>
      <Content
        color={`var(--${selectedProductType}-color)`}
        isMobile={isMobile}
      >
        {selectedProductType === "custom" && (
          <FlexLayout>
            <FlexLayout direction="row" verticalAlign="center">
              <FontAwesomeIcon
                icon={faInfoCircle}
                color={`var(--color-error)`}
                fontSize={24}
              />
              <Paragraph>{title("line1")}</Paragraph>
            </FlexLayout>
            <div className={styles.moveLeft}>
              <ul>
                <li>
                  <Paragraph news>{title("line2")}</Paragraph>
                </li>
                <li>
                  <Paragraph news>{title("line3")}</Paragraph>
                </li>
              </ul>
            </div>
          </FlexLayout>
        )}
        {selectedProductType === "fixed" && (
          <div className={styles.moveLeft}>
            <ul>
              <li>
                <Paragraph>{title("line1")}</Paragraph>
              </li>
              <li>
                <Paragraph>
                  {title("line2.main")}{" "}
                  <span className={styles.bold}>{title("line2.tooltip1")}</span>
                  , {title("line2.end")}
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  {title("line3.main")}{" "}
                  <span className={styles.bold}>{title("line3.tooltip1")}</span>
                  , og{" "}
                  <span className={styles.bold}>{title("line3.tooltip2")}</span>
                  .
                </Paragraph>
              </li>
            </ul>
          </div>
        )}
        {selectedProductType === "spot" && (
          <FlexLayout>
            <div className={styles.moveLeft}>
              <ul>
                <li>
                  <Paragraph>
                    <span>{title("line1.main")} </span>
                    <Tooltip
                      content={
                        <TooltipView
                          title={tooltip("priceOfElectricity.title")}
                          text={tooltip("priceOfElectricity.text")}
                        />
                      }
                    >
                      <span className={styles.tooltip}>
                        {tooltip("priceOfElectricity.title").toLowerCase()}
                      </span>{" "}
                    </Tooltip>
                    og{" "}
                    <Tooltip
                      content={
                        <TooltipView
                          title={tooltip("companySurcharge.title")}
                          text={tooltip("companySurcharge.text")}
                        />
                      }
                    >
                      <span className={styles.tooltip}>
                        {title("line1.tooltip2")}
                      </span>
                    </Tooltip>
                    .
                  </Paragraph>
                </li>
                <li>
                  <Paragraph>{title("line2")}</Paragraph>
                </li>
                <li>
                  <Paragraph>
                    <Tooltip
                      content={
                        <TooltipView
                          title={tooltip(
                            "guaranteedAgreements.alternativeTitle"
                          )}
                          text={tooltip("guaranteedAgreements.text")}
                        />
                      }
                    >
                      <span className={styles.tooltip}>
                        {title("line3.tooltip1")}
                      </span>{" "}
                    </Tooltip>
                    {title("line3.main")}{" "}
                    <Tooltip
                      content={
                        <TooltipView
                          title={tooltip("companySurcharge.title")}
                          text={tooltip("companySurcharge.text")}
                        />
                      }
                    >
                      <span className={styles.tooltip}>
                        {title("line3.tooltip2")}
                      </span>{" "}
                    </Tooltip>
                    {title("line3.end")}.
                  </Paragraph>
                </li>
              </ul>
            </div>
            <Paragraph>{title("extra")}</Paragraph>
          </FlexLayout>
        )}
        {selectedProductType === "plus" && (
            <FlexLayout>
                <ul className={styles.moveLeft}>
                  <li>
                    <Paragraph>
                      {title("line1.main")}
                    </Paragraph>
                  </li>
                  <li>
                  <Paragraph>
                    {title("line2.main")}
                  </Paragraph>
                  </li>
                  <li>
                    <Paragraph>
                      {title("line3.main")}
                    </Paragraph>
                  </li>
                </ul>
            </FlexLayout>
        )}
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  background-color: white;
  width: 100%;
  //box-shadow: 0 0px 3px 2px rgba(0, 0, 0, 0.2);
  //clip-path: inset(-8px -8px 0px -8px);
  z-index: 1;
  justify-content: center;
`;

const Content = styled.div<WrapperProps>`
  border-width: ${(props) => (props.isMobile ? "0px 2px 2px 2px" : "2px")};
  border-radius: 0 0 5px 5px;
  border-style: solid;
  border-color: ${(props) => props.color};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //gap: 20px;
  width: 100%;
  padding: 8px;
`;

export default ProductDescription;
