import create from "zustand";

type Store = {
  selectedLocalGrid: number;
  setLocalGrid: (id: number, persist: true) => void;

  selectedProductType: string;
  selectType: (type: string) => void;

  yearlyConsumption: number;
  setYearlyConsumption: (consumption: number, persist: boolean) => void;
  consumptionCalculatorSubmitted: boolean;
  setConsumptionCalculatorSubmitted: (submitted: boolean) => void;

  filterShowMembershipRequired: boolean;
  setFilterShowMembershipRequired: (show: boolean) => void;
  filterShowShortSpot: boolean;
  setFilterShowShortSpot: (show: boolean) => void;
  filterShowMediumSpot: boolean;
  setFilterShowMediumSpot: (show: boolean) => void;
  filterShowNewCustomersOnly: boolean;
  setFilterShowNewCustomersOnly: (show: boolean) => void;
  filterShowCabinProducts: boolean;
  setFilterShowCabinProducts: (show: boolean) => void;
};

function initialLocalGridId() {
  try {
    return parseInt(window.localStorage.getItem("localgrid") || "5");
  } catch (e) {
    console.error(e);
    return 5;
  }
}

function initialYearlyConsumption() {
  try {
    return parseInt(
      window.localStorage.getItem("yearlyConsumption") || "16000"
    );
  } catch (e) {
    console.error(e);
    return 16000;
  }
}

function consumptionCalculatorSubmittedInitial() {
  try {
    return (
      window.localStorage.getItem("yearlyConsumption") !== null &&
      window.localStorage.getItem("municipality") !== null
    );
  } catch (e) {
    console.error(e);
    return false;
  }
}

const useStore = create<Store>(
  (set): Store => ({
    selectedLocalGrid: initialLocalGridId(),
    setLocalGrid: (id: number, persist: boolean) => {
      set({ selectedLocalGrid: id });
      if (persist) {
        try {
          window.localStorage.setItem("localgrid", id.toString());
        } catch (e) {
          console.error(e);
        }
      }
    },

    selectedProductType: "spot",
    selectType: (type: string) => set({ selectedProductType: type }),

    consumptionCalculatorSubmitted: consumptionCalculatorSubmittedInitial(),
    setConsumptionCalculatorSubmitted: (submitted: boolean) =>
      set({ consumptionCalculatorSubmitted: submitted }),

    yearlyConsumption: initialYearlyConsumption(),
    setYearlyConsumption: (consumption: number, persist: boolean) => {
      set({ yearlyConsumption: consumption });
      if (persist) {
        try {
          window.localStorage.setItem(
            "yearlyConsumption",
            consumption.toString()
          );
        } catch (e) {
          console.error(e);
        }
      }
    },
    filterShowMembershipRequired: false,
    setFilterShowMembershipRequired: (show: boolean) =>
      set({ filterShowMembershipRequired: show }),
    filterShowShortSpot: false,
    setFilterShowShortSpot: (show: boolean) =>
      set({ filterShowShortSpot: show }),
    filterShowMediumSpot: true,
    setFilterShowMediumSpot: (show: boolean) =>
      set({ filterShowMediumSpot: show }),
    filterShowNewCustomersOnly: false,
    setFilterShowNewCustomersOnly: (hide: boolean) =>
      set({ filterShowNewCustomersOnly: hide }),
    filterShowCabinProducts: false,
    setFilterShowCabinProducts: (show: boolean) =>
      set({ filterShowCabinProducts: show }),
  })
);

export default useStore;
