import { ReactElement, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import useStore from "store/store";
import { BlockLink } from "styles/Buttons";
import SlideDown from "react-slidedown";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import {
  faCircleInfo,
  faMinus,
  faPlus,
} from "@fortawesome/pro-solid-svg-icons";
import Tooltip from "./Tooltip/Tooltip";
import TooltipView from "./Tooltip/TooltipView";
import { Paragraph } from "./Typography/Paragraph/Paragraph";
import { FlexLayout } from "./Layouts/FlexLayout";
import NoStyleButton from "./Button/NoStyleButton";

function Filters(): ReactElement {
  const filterShowMembershipRequired = useStore(
    (state) => state.filterShowMembershipRequired
  );
  const filterShowNewCustomersOnly = useStore(
    (state) => state.filterShowNewCustomersOnly
  );
  const filterShowShortSpot = useStore((state) => state.filterShowShortSpot);
  const filterShowMediumSpot = useStore((state) => state.filterShowMediumSpot);
  const filterShowCabinProducts = useStore(
    (state) => state.filterShowCabinProducts
  );
  const setFilterShowMembershipRequired = useStore(
    (state) => state.setFilterShowMembershipRequired
  );
  const setFilterShowShortSpot = useStore(
    (state) => state.setFilterShowShortSpot
  );
  const setFilterShowMediumSpot = useStore(
    (state) => state.setFilterShowMediumSpot
  );
  const setFilterShowNewCustomersOnly = useStore(
    (state) => state.setFilterShowNewCustomersOnly
  );
  const setFilterShowCabinProducts = useStore(
    (state) => state.setFilterShowCabinProducts
  );
  const selectedProductType = useStore((state) => state.selectedProductType);

  const { t } = useTranslation();
  const { trackEvent } = useMatomo();

  const trackFilter = (state: boolean, name: string) => {
    trackEvent({
      category: "filters",
      action: `filter-${state === true ? "enabled" : "disabled"}`,
      name: name,
    });
  };

  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    trackEvent({
      category: "filters",
      action: `filters-${expanded === true ? "closed" : "opened"}`,
    });
    setExpanded(!expanded);
  };

  return (
    <Wrapper>
      <FiltersButton
        onClick={toggleExpanded}
        productType={selectedProductType}
        expanded={expanded}
      >
        <FilterHeader>Filtrer søket</FilterHeader>
        <FilterOpenClose>
          <FontAwesomeIcon icon={expanded ? faMinus : faPlus} />
          {expanded ? (
            <FilterLabel>Lukk filter</FilterLabel>
          ) : (
            <FilterLabel>Åpne filter</FilterLabel>
          )}
        </FilterOpenClose>
      </FiltersButton>
      <SlideDown closed={!expanded}>
        <FiltersWrapper
          productType={selectedProductType}
          expanded={expanded}
          onKeyUp={(e) => {
            if (e.key === "Enter" && expanded) {
              toggleExpanded();
            }
          }}
        >
          {selectedProductType === "spot" && (
            <>
              <Tooltip
                content={
                  <TooltipView
                    title={t("tooltips.guaranteedAgreements.title")}
                    text={t("tooltips.guaranteedAgreements.text")}
                  />
                }
              >
                <NoStyleButton noTab>
                  <FlexLayout direction="row" align="center" gap={8}>
                    <Paragraph dashed link>
                      VILKÅR GARANTERES
                    </Paragraph>
                    <FontAwesomeIcon
                      size="lg"
                      icon={faCircleInfo}
                      color="#05426A"
                      tabIndex={-1}
                    />
                  </FlexLayout>
                </NoStyleButton>
              </Tooltip>

              <CheckboxWrapper>
                <input
                  type="checkbox"
                  checked={filterShowMediumSpot}
                  onChange={(e) => {
                    trackFilter(e.target.checked, "show-medium-spot");
                    setFilterShowMediumSpot(e.target.checked);
                  }}
                />
                {t("filters.showMediumSpot")}
              </CheckboxWrapper>

              <CheckboxWrapper>
                <input
                  type="checkbox"
                  checked={filterShowShortSpot}
                  onChange={(e) => {
                    trackFilter(e.target.checked, "show-short-spot");
                    setFilterShowShortSpot(e.target.checked);
                  }}
                />
                {t("filters.showShortSpot")}
              </CheckboxWrapper>
              <br />
            </>
          )}
          <label>
            <b>VIS AVTALER</b>
          </label>
          <CheckboxWrapper>
            <input
              type="checkbox"
              checked={filterShowNewCustomersOnly}
              onChange={(e) => {
                trackFilter(e.target.checked, "show-new-customers-only");
                setFilterShowNewCustomersOnly(e.target.checked);
              }}
            />
            {t("filters.showNewCustomersOnly")}
          </CheckboxWrapper>
          <CheckboxWrapper>
            <input
              type="checkbox"
              checked={filterShowMembershipRequired}
              onChange={(e) => {
                trackFilter(e.target.checked, "show-membership-required");
                setFilterShowMembershipRequired(e.target.checked);
              }}
            />
            {t("filters.showMembershipRequired")}
          </CheckboxWrapper>
          <CheckboxWrapper>
            <input
              type="checkbox"
              checked={filterShowCabinProducts}
              onChange={(e) => {
                trackFilter(e.target.checked, "show-cabin-products");
                setFilterShowCabinProducts(e.target.checked);
              }}
            />
            {t("filters.showCabinProducts")}
          </CheckboxWrapper>
          <ButtonWrapper>
            <UpdateFiltersButton
              onClick={() => {
                toggleExpanded();
              }}
            >
              Oppdater søk
            </UpdateFiltersButton>
          </ButtonWrapper>
        </FiltersWrapper>
      </SlideDown>
    </Wrapper>
  );
}

const FilterHeader = styled.label`
  text-align: left;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 1.125rem;
  font-family: "ClanOT-Book";
  cursor: pointer;
`;

const FilterOpenClose = styled.label`
  text-align: center;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 60px;
  text-decoration: solid;
  font-size: 1rem;
  cursor: pointer;
  @media screen and (min-width: 450px) {
    text-align: right;
    padding-right: 32px;
  }
`;

const FilterLabel = styled.label`
  margin-left: 8px;
  font-size: 1rem;
  cursor: pointer;
  font-family: "ClanOT-Book", sans-serif;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  min-height: 3em;
`;

const Wrapper = styled.div`
  width: 100%;
`;

interface WrapperProps {
  productType: string;
  expanded: boolean;
}

const FiltersButton = styled.button<WrapperProps>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: left;
  cursor: pointer;
  padding: 1rem;
  height: 4em;
  color: var(--primary-black);
  background-color: ${(props) => (props.expanded ? "white" : "#f3f3f3")};
  border: 1.5px solid
    ${(props) => (props.expanded ? "var(--custom-color)" : "#f3f3f3")};
  border-radius: ${(props) => (props.expanded ? "5px 5px 0 0" : "5px")};
  border-width: ${(props) => (props.expanded ? "2px 2px 0 2px" : "2px")};
`;

const FiltersWrapper = styled.div<WrapperProps>`
  border: 2px solid var(--custom-color);
  border-radius: ${(props) => (props.expanded ? "0 0 5px 5px" : "5px")};
  border-width: ${(props) => (props.expanded ? "0 2px 2px 2px" : "2px")};
  padding: 1rem;
  display: flex;
  flex-direction: column;
  & > * + * {
    margin-top: 1.125rem;
  }
`;

const UpdateFiltersButton = styled(BlockLink)`
  font: inherit !important;
  display: flex;
  width: 100%;
  max-width: 420px;
  justify-content: center;
  align-items: center;
`;

const CheckboxWrapper = styled.label`
  display: grid;
  grid-template-columns: 1em auto;
  grid-gap: 1em;
  align-items: center;
  input[type="checkbox"] {
    font: inherit !important;
    color: currentColor !important;
    width: 1.65em;
    height: 1.65em;
    border: 0.1em solid white;
    border-radius: 5px;
    transform: translateY(-0.095em);
    display: grid;
    place-content: center;
    accent-color: var(--primary-dark-blue);
  }
`;

export default Filters;
