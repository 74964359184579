import { useTranslation, Trans } from "react-i18next";
import {
  feeMandatoryApplied,
  FEE_TYPE_BEFORE,
  FEE_TYPE_PERIOD,
  getFeeMandatory,
} from "shared/classificators";
import {
  formatMoneyDec,
  formatPeriod,
  formatPeriodFrequency,
  toSpacedNumber,
} from "shared/formatter";
import { Paragraph } from "components/Typography/Paragraph/Paragraph";
import { FlexLayout } from "components/Layouts/FlexLayout";
import { InfoIcon } from "components/Icons/InfoIcon";
import styles from "./styles.module.scss";

interface Props {
  product: any;
  staticRender?: boolean;
}

export const ConditionsTable = ({ product, staticRender = false }: Props) => {
  const { t } = useTranslation();

  return (
    <FlexLayout gap={16} customStyle={{ marginTop: "16px" }}>
      <Paragraph size={16}>
        <Trans
          i18nKey="agreementAndOtherInfo"
          values={{
            productName: product.productName,
            organizationName: product.organizationName,
          }}
        />
      </Paragraph>
      <FlexLayout>
        <FlexLayout
          direction="row"
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Paragraph size={14}>{t("detailsCustomerGroups")}</Paragraph>
          <Paragraph size={14} news right>
            {t(`detailsCustomerGroups_${product.applicableToCustomersType}`)}
          </Paragraph>
        </FlexLayout>

        {product.memberships && product.memberships.length > 0 && (
          <FlexLayout
            direction="row"
            verticalAlign="center"
            horizontalAlign="space-between"
          >
            <Paragraph size={14}>{t("memberships")}</Paragraph>
            <Paragraph size={14} news right>
              {product.memberships.join(", ")}
            </Paragraph>
          </FlexLayout>
        )}

        <FlexLayout
          direction="row"
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Paragraph size={14}>{t("feeMandatory")}</Paragraph>
          <Paragraph size={14} news right>
            {feeMandatoryApplied(product) && (
              <span>
                <InfoIcon color="#D94740" /> &nbsp;
              </span>
            )}
            <span>{t(getFeeMandatory(product))}</span>
          </Paragraph>
        </FlexLayout>

        <FlexLayout
          direction="row"
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Paragraph size={14}>{t("detailsModalPayment")}</Paragraph>
          <Paragraph className="feeType" size={14} news right>
            {(product.feeType === FEE_TYPE_BEFORE ||
              product.feeType === FEE_TYPE_PERIOD) && (
              <span>
                <InfoIcon color="#D94740" />
                &nbsp;
              </span>
            )}
            {t(`feeType.${product.feeType}`)}
          </Paragraph>
        </FlexLayout>

        {Boolean(product.maxKWYear && product.maxKWYear > 0) && (
          <FlexLayout
            direction="row"
            verticalAlign="center"
            horizontalAlign="space-between"
          >
            <Paragraph size={14}>{t("maxKWYear")}</Paragraph>
            <Paragraph size={14} news right>
              {toSpacedNumber(product.maxKWYear)} kWt
            </Paragraph>
          </FlexLayout>
        )}

        <FlexLayout
          direction="row"
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Paragraph size={14}>{t("billingFrequency")}</Paragraph>
          <Paragraph size={14} news right>
            {formatPeriodFrequency(
              product.billingFrequency,
              product.billingFrequencyUnits
            )}
          </Paragraph>
        </FlexLayout>

        {Boolean(product.agreementTime && product.agreementTime > 0) && (
          <FlexLayout
            direction="row"
            verticalAlign="center"
            horizontalAlign="space-between"
          >
            <Paragraph size={14}>{t("agreementTime")}</Paragraph>
            <Paragraph size={14} news right>
              {formatPeriod(product.agreementTime, product.agreementTimeUnits)}
            </Paragraph>
          </FlexLayout>
        )}

        {Boolean(
          product.addonPriceMinimumFixedFor &&
            product.addonPriceMinimumFixedFor > 0 &&
            staticRender
        ) && (
          <FlexLayout
            direction="row"
            verticalAlign="center"
            horizontalAlign="space-between"
          >
            <Paragraph size={14}>{t("addonPriceMinimumFixedFor")}</Paragraph>
            <Paragraph size={14} news right>
              {formatPeriod(
                product.addonPriceMinimumFixedFor,
                product.addonPriceMinimumFixedForUnits
              )}
            </Paragraph>
          </FlexLayout>
        )}

        <FlexLayout
          direction="row"
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Paragraph size={14}>{t("standardAlert")}</Paragraph>
          <Paragraph size={14} news right>
            {product.standardAlert
              ? t(`standardAlert_${product.standardAlert}`)
              : t("undisclosed")}
          </Paragraph>
        </FlexLayout>

        {product.finalFeeMail !== null && (
          <FlexLayout
            direction="row"
            verticalAlign="center"
            horizontalAlign="space-between"
          >
            <Paragraph size={14}>{t("feeMail")}</Paragraph>
            <Paragraph size={14} news right>
              {product.finalFeeMail > 0 && (
                <span>
                  <InfoIcon color="#D94740" />
                  &nbsp;
                </span>
              )}
              {formatMoneyDec(product.finalFeeMail, false)} {t("nokSmall")}
            </Paragraph>
          </FlexLayout>
        )}

        {product.otherConditions !== null && (
          <FlexLayout
            direction="row"
            verticalAlign="flex-start"
            horizontalAlign="space-between"
          >
            <Paragraph size={14}>{t("otherConditions")}</Paragraph>
            <div className={styles.otherConditions}>
              <Paragraph
                size={14}
                news
                customStyle={{ wordWrap: "break-word", overflowWrap: "break-word", wordBreak: "break-word", hyphens: "auto" }}
              >
                {product.otherConditions}
              </Paragraph>
            </div>
          </FlexLayout>
        )}
      </FlexLayout>
    </FlexLayout>
  );
};
