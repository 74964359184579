export function compareNames(a: any, b: any) {
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  }
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  }
  return 0;
}

export function comparePrices(a: any, b: any) {
  if (a.product.finalPriceMonthly < b.product.finalPriceMonthly) {
    return -1;
  }
  if (a.product.finalPriceMonthly > b.product.finalPriceMonthly) {
    return 1;
  }
  return 0;
}