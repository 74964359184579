import {ReactElement, useMemo} from "react";

import EmbeddableProductTableHeader from "./EmbeddableProductTableHeader";
import EmbeddableProduct from "./EmbeddableProduct";

import {GridTable} from "./styles";
import {orderProducts} from "hooks/useProducts/productOrderer";
import useFilterFunction from "./useFilterFunction";
import {IProducts} from "shared/types";

interface Props {
  productType: string;
  products: any[] | undefined;
  searchTerm: string;
}

function EmbeddableProductTable({
  productType,
  products,
  searchTerm,
}: Props): ReactElement {
  const filterFunction = useFilterFunction();

  const filteredProducts = useMemo(() => {
    if (products) {
      return products.filter((product: IProducts) =>
          filterFunction(product, searchTerm)
      );
    } else {
      return [];
    }
  }, [products, searchTerm, filterFunction]);

  const orderedProducts: any[] = orderProducts(filteredProducts);

  const top10 = orderedProducts.slice(0, 10);

  return (
    <>
      {orderedProducts.length !== 0 && (
        <div>
          <GridTable style={{ margin: 0 }} productType={productType}>
            <EmbeddableProductTableHeader />
            {products &&
              top10.map((product, index) => (
                <EmbeddableProduct
                  key={index}
                  product={product}
                  productType={productType}
                  searchTerm={searchTerm}
                />
              ))}
          </GridTable>
        </div>
      )}
    </>
  );
}

export default EmbeddableProductTable;
