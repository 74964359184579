import ProductTableContent from "./ProductTableContent/ProductTableContent";

interface IProductTableContent {
  orderedProducts: any;
  title: string | null;
  products: any;
  productType: string;
  priceType?: string | null;
}

const ProductTableContextContainer = ({
  orderedProducts,
  title,
  products,
  productType,
  priceType,
}: IProductTableContent) => {
  return (
    <ProductTableContent
      orderedProducts={orderedProducts}
      title={title}
      products={products}
      priceType={priceType}
      productType={productType}
    />
  );
};

export default ProductTableContextContainer;
