import SlideDown from "react-slidedown";
import styled from "styled-components";
import { useState } from "react";
import { ExpandIcon } from "components/Icons/ExpandIcon";
import "./style.css";

interface QuestionAnswerProps {
  question: string;
  answer: string[];
  index: number;
}

const QuestionAnswer = ({ question, answer, index }: QuestionAnswerProps) => {
  const [closed, setClosed] = useState(true);
  const handleKeypress = (e: { charCode: number }) => {
    if (e.charCode === 13) {
      setClosed(!closed);
    }
  };
  return (
    <Wrapper>
      <Question
        tabIndex={0}
        onClick={() => setClosed(!closed)}
        onKeyPress={handleKeypress}
      >
        <span>{question}</span>
        <span style={{ paddingLeft: "10px" }}>
          <ExpandIcon closed={closed} />
        </span>
      </Question>

      {answer.map((p, key) => {
        return (
          <SlideDown closed={closed}>
            <AnswerParagraph
              key={key}
              dangerouslySetInnerHTML={{ __html: p }}
            />
          </SlideDown>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-bottom: 1px solid var(--secondary-gray-30-no-opacity);
  .react-slidedown {
    transition-duration: 0.1s;
    transition-timing-function: ease-in-out;
  }
`;
const Question = styled.h2`
  display: grid;
  cursor: pointer;
  grid-template-columns: 1fr min-content;
  grid-template-rows: 1fr;
  margin-top: 1.5rem;
  margin-bottom: 1.313rem;
  font-size: 1rem !important;
  align-items: center;
  font-weight: 450;
  line-height: 1.5rem;
`;
const AnswerParagraph = styled.p`
  font-size: 0.875rem;
  margin-top: 0;
  margin-bottom: 1.313rem;
`;

export default QuestionAnswer;
