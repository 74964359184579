import { comparePrices } from "components/CompareProducts/ResultView/utils";
import { useState } from "react";
import {PLUS_ID} from "../shared/constants";
import {ProductType} from "shared/types";

export const useCompareProducts = () => {
  const [toggled, setToggled] = useState<boolean>(false);
  const [productsToCompare, setProductsToCompare] = useState<any>([]);
  const [showCompareView, setShowCompareView] = useState<boolean>(false);
  const [selectedProductId, setSelectedProductId] = useState<string>();
  const [canFocusPdfButton, setCanFocusPdfButton] = useState<boolean>(false);
  const [containsPlusProducts, setContainsPlusProduct] = useState<boolean>(false);

  const addProductToCompare = (product: any, targetRef: any) => {
    if (!isProductComparable(product)) {
      return;
    }

    const newProduct = {
      product: product,
      targetRef: targetRef,
      isCurrent: false,
    };
    const newProducts = [...productsToCompare, newProduct];
    const sorted = newProducts.sort(comparePrices);
    setProductsToCompare(sorted);
    if (ProductType.PLUS === product.productType) {
      setContainsPlusProduct(true);
    }
  };

  const deleteProductToCompare = (productId: number) => {
    const indexOfProduct = productsToCompare.findIndex(
      (productObject: any) => productObject.product.productId === productId
    );
    const newProducts = [...productsToCompare];
    newProducts.splice(indexOfProduct, 1);
    setProductsToCompare(newProducts);
    updateContainsPlusProduct(newProducts);
  };

  const clearProductsToCompare = () => {
    setProductsToCompare([]);
    setContainsPlusProduct(false);
  };

  const addCurrentProductToCompare = (product: any) => {
    const newProduct = {
      product,
      isCurrent: true,
    };
    const indexOfCurrentProduct = productsToCompare.findIndex(
      (productObject: any) => productObject.isCurrent
    );
    const newProducts = [...productsToCompare];
    if (indexOfCurrentProduct >= 0) {
      newProducts.splice(indexOfCurrentProduct, 1);
    }
    const sorted = [...newProducts, newProduct].sort(comparePrices);
    setProductsToCompare(sorted);
    if (ProductType.PLUS === product.productType) {
      setContainsPlusProduct(true);
    }
  };

  const removeCurrentProductToCompare = () => {
    const indexOfCurrentProduct = productsToCompare.findIndex(
      (productObject: any) => productObject.isCurrent
    );
    if (indexOfCurrentProduct >= 0) {
      const newProducts = [...productsToCompare];
      newProducts.splice(indexOfCurrentProduct, 1);
      setProductsToCompare(newProducts);
      updateContainsPlusProduct(newProducts);
    }
  };

  const hasCurrentProduct = () => {
    const index = productsToCompare.findIndex(
      (productObject: any) => productObject.isCurrent
    );
    return index >= 0;
  };

  const limitReach = () => productsToCompare.length >= 5;

  const isProductComparable = (product: any) => {
    return isProductTypeComparable(product.productType);
  };

  const updateContainsPlusProduct = (products : any) => {
    setContainsPlusProduct(products.some((productObject: any) =>
        ProductType.PLUS === productObject.product.productType));
  };

  const isProductTypeComparable = (productType: string) => {
    // TODO: remove this when we have single productTypes..
    if (PLUS_ID === productType) {
      productType = ProductType.PLUS;
    }

    if (productsToCompare.length === 0) {
      return true;
    }

    if (ProductType.PLUS === productType) {
      return containsPlusProducts;
    }

    return !containsPlusProducts;
  };

  const toggleCompareProducts = () => {
    if (toggled) {
        clearProductsToCompare();
    }
    setToggled(!toggled);
  }

  return {
    toggled,
    setToggled,
    toggleCompareProducts,
    productsToCompare,
    addProductToCompare,
    deleteProductToCompare,
    showCompareView,
    setShowCompareView,
    selectedProductId,
    setSelectedProductId,
    clearProductsToCompare,
    addCurrentProductToCompare,
    hasCurrentProduct,
    removeCurrentProductToCompare,
    canFocusPdfButton,
    setCanFocusPdfButton,
    limitReach,
    isProductComparable,
    isProductTypeComparable,
    containsPlusProducts
  };
};
