import HistoricPrices from "./historicPrices/HistoricPrices";
import { RouteWrapper } from "styles/General";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useEffect } from "react";
import TooltipView from "components/Tooltip/TooltipView";
import Tooltip from "components/Tooltip/Tooltip";
import loadable from "@loadable/component";
import { Spinner } from "styles/Spinner";
import DownloadHistoricPrices from "./DownloadHistoricPrices";
import { Paragraph } from "components/Typography/Paragraph/Paragraph";
import styles from "./styles.module.scss";
import norway from "images/norway.png";
import { FlexLayout } from "components/Layouts/FlexLayout";

const CurrentPrices = loadable(
  () => import(/* webpackPrefetch: true */ "./CurrentPrices"),
  {
    fallback: (
      <div
        style={{
          display: "flex",
          height: "250px",
          backgroundColor: "white",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    ),
  }
);

function Spotprices() {
  const { trackPageView } = useMatomo();
  useEffect(() => {
    trackPageView({});
  }, [trackPageView]);

  return (
    <RouteWrapper style={{ maxWidth: "700px" }}>
      <FlexLayout
        horizontalAlign="center"
        verticalAlign="center"
        customStyle={{ marginTop: "1.8rem" }}
      >
        <Paragraph news size={32} asHeading>
          Spotpriser
        </Paragraph>
        <Paragraph size={18} customStyle={{ textAlign: "center" }}>
          Under finner du strømprisene innenfor de aktuelle prisområdene hentet
          fra strømbørsen Nord Pool. Det finnes{" "}
          <Tooltip
            content={
              <TooltipView
                title={"Prisområder"}
                text={
                  "Norge er delt inn i fem strømregioner. Regionene er som vist på kartet Øst (også kalt N01), Sør (N02), Vest (N05), Midt (N03) og Nord (N04)."
                }
                customContent={
                  <FlexLayout>
                    <img
                      src={norway}
                      alt="Kart over Norges prisområder for strøm, Norges vassdrags- og energidirektorat"
                    />
                    <Paragraph size={12}>Kilde: NVE</Paragraph>
                  </FlexLayout>
                }
              />
            }
          >
            <span className={styles.tooltip}>fem forskjellige prisområder</span>
          </Tooltip>{" "}
          i Norge.
        </Paragraph>
      </FlexLayout>
      <CurrentPrices />
      <HistoricPrices />
      <DownloadHistoricPrices />
    </RouteWrapper>
  );
}

export default Spotprices;
