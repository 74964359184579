import NotificationBox from "components/NotificationBox/NotificationBox";
import { useTranslation } from "react-i18next";
import {
  feeMandatoryApplied,
  FEE_TYPE_BEFORE,
  FEE_TYPE_PERIOD,
  getFeeMandatoryWarningText,
} from "shared/classificators";

type Props = {
  product: any;
};

export function notificationBoxData(product: any, t: any): any[] {
  let boxes = [];

  if (product.expired) {
    boxes.push({
      text: t("expiredWarning"),
      severity: "info",
    });
  }

  if (
    product.feeType === FEE_TYPE_BEFORE ||
    product.feeType === FEE_TYPE_PERIOD
  ) {
    boxes.push({
      text: t("advancePaymentWarning"),
      severity: "severe",
    });
  }

  if (feeMandatoryApplied(product)) {
    boxes.push({
      text: getFeeMandatoryWarningText(product),
      severity: "notice",
    });
  }

  if (
    Boolean(
      product.finalContractBreachFee && product.finalContractBreachFee > 0
    )
  ) {
    boxes.push({
      text: t("breachFeeWarning"),
      severity: "notice",
    });
  }

  return boxes;
}

const NotificationBoxes = ({ product }: Props) => {
  const { t } = useTranslation();
  const boxes = notificationBoxData(product, t);
  return (
    <>
      {boxes.map((el, index) => (
        <NotificationBox text={el.text} severity={el.severity} key={index} />
      ))}
    </>
  );
};

export default NotificationBoxes;
