import { Routes, Route, Outlet, useMatch, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import ReactModal from "react-modal";

import "./App.css";
import Header from "components/Header";
import CompareProducts from "components/CompareProducts/CompareProducts";
import Home from "components/home/Home";
import About from "components/About";
import FAQ from "components/FAQ";
import Footer from "components/Footer";
import Spotprices from "components/Spotprices";

import Overpris from "components/Overpris";
/* import FormWrapper from "components/Overpris/Form/FormWrapper";
import ZipCode from "components/Overpris/Form/ZipCode";
import InvoicePeriod from "components/Overpris/Form/InvoicePeriod";
import Consumption from "components/Overpris/Form/Consumption";
import InvoiceAmount from "components/Overpris/Form/InvoiceAmount"; */

import NoMatch from "components/NoMatch";
import Glossary from "components/Glossary";
import Embeddable from "components/Embeddable";
import loadable from "@loadable/component";
import { useEffect, useRef } from "react";
import { getProductsByLocalGridId } from "hooks/useProducts/useProducts";
import styled from "styled-components";
import News from "components/news";

const queryClient = new QueryClient();
ReactModal.setAppElement("#root");

const InvoiceAnalysis = loadable(
  () => import("components/Overpris/InvoiceAnalysis")
);

export default function App() {
  let overprisRouteMatched = useMatch("/fakturasjekk/*");
  const hasPreloadedOverpris = useRef(false);

  useEffect(() => {
    if (hasPreloadedOverpris.current === false && overprisRouteMatched) {
      InvoiceAnalysis.preload();
      hasPreloadedOverpris.current = true;
    }
  }, [overprisRouteMatched]);

  useEffect(() => {
    try {
      const localGrid = parseInt(
        window.localStorage.getItem("localgrid") || "5"
      );
      queryClient.prefetchQuery(["products", localGrid], () =>
        getProductsByLocalGridId(localGrid)
      );
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="embeddable" element={<Embeddable />} />
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="sammenlign-avtaler" element={<CompareProducts />} />
          <Route path="nyheter" element={<News />} />
          <Route path="fakturasjekk" element={<Overpris />} />
          <Route
            path="fakturasjekk*"
            element={<Navigate replace to="/fakturasjekk" />}
          />

          {/*
          da
          <Route path="fakturasjekk/steg" element={<FormWrapper />}>
            <Route path="1" element={<ZipCode />} />
            <Route path="2" element={<InvoicePeriod />} />
            <Route path="3" element={<Consumption />} />
            <Route path="4" element={<InvoiceAmount />} />
          */}
          {/*
          </Route>
          <Route path="/fakturasjekk/resultat" element={<InvoiceAnalysis />} />
          */}

          <Route path="spotpriser" element={<Spotprices />} />
          <Route path="om-strompris" element={<About />} />
          <Route path="ofte-stilte-sporsmal" element={<FAQ />} />
          <Route path="ordliste" element={<Glossary />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

function Layout() {
  return (
    <div className="App">
      <Jumpbutton href="#main">Hopp til hovedinnhold</Jumpbutton>
      <Header />

      <main id="main">
        <Outlet />
      </main>

      <Footer />
    </div>
  );
}

const Jumpbutton = styled.a`
  width: fit-content;
  display: grid;
  padding: 0 16px;
  height: 3em;
  position: absolute;
  left: -2000px;
  top: 0px;
  text-align: center;
  align-items: center;
  color: var(--primary-white);
  background-color: var(--primary-dark-blue);
  &:focus {
    left: 0px;
    -moz-appearance: none;
    -webkit-appearance: none;
  }
`;
