import ConsumptionCalculator from "components/ConsumptionCalculator";
import PageIntro from "components/PageIntro";
import { CompareProductsContext } from "contexts/useCompareProducts";
import { useContext, useEffect, useRef } from "react";
import useStore from "store/store";
import ResultView from "../ResultView/ResultView";

interface ICalculatorAndResult {
  isSuccess: boolean;
  submitted: boolean;
}
const CalculatorAndResult = ({
  isSuccess,
  submitted,
}: ICalculatorAndResult) => {
  const setSubmitted = useStore(
    (state) => state.setConsumptionCalculatorSubmitted
  );
  const { showCompareView } = useContext(CompareProductsContext);
  const scrollTarget = useRef<HTMLDivElement | null>(null);
  let scrollPerformed = useRef(true);
  useEffect(() => {
    if (isSuccess && !scrollPerformed.current && scrollTarget.current) {
      //scrollTarget.current.scrollIntoView({ behavior: "smooth" });
      scrollPerformed.current = true;
    }
  });
  const onCalculatorSubmit = () => {
    scrollPerformed.current = false;
    setSubmitted(true);
  };
  return (
    <>
      {!showCompareView && (
        <>
          <PageIntro title="Sammenlign avtaler" />
          <ConsumptionCalculator
            onClick={onCalculatorSubmit}
            submitted={submitted}
            setSubmitted={setSubmitted}
            persist={true}
          />
        </>
      )}
      {isSuccess && submitted ? (
        <ResultView scrollTarget={scrollTarget} />
      ) : (
        <div></div>
      )}
    </>
  );
};

export default CalculatorAndResult;
