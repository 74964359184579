import React from "react";
import { useTranslation } from "react-i18next";
// import logo from "./frlogo.png";
import {
  getFeeMandatory,
  isProductKwPrice,
  isProductSpotprice,
} from "shared/classificators";
import {
  formatMoneyDec,
  formatPeriod,
  formatOreDec,
  formatPeriodFrequency,
  toSpacedNumber,
} from "shared/formatter";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

import ClanOTBook from "fonts/ClanOT-Book.ttf";
import ClanOTMedium from "fonts/ClanOT-Medium.ttf";
import { notificationBoxData } from "components/Products/product/ProductDetails/NotificationBoxes";
import {PriceType, ProductType} from "shared/types";

Font.register({
  family: "ClanOT-Book",
  src: ClanOTBook,
});
Font.register({
  family: "ClanOT-Medium",
  src: ClanOTMedium,
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    fontFamily: "ClanOT-Book",
    fontSize: "12px",
  },
  header: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  body: {
    fontSize: "14px",
  },
  section: {
    margin: 10,
    padding: 10,
  },
  leftColumn: {
    flexDirection: "column",
    fontFamily: "ClanOT-Medium",
    width: "300px",
  },
  rightColumn: {
    flexDirection: "column",
    width: "250px",
  },
  headerContainer: {
    flexDirection: "row",
  },
  logoContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
  },
  logo: {
    height: "40px",
  },
  notificationBox: {
    marginBottom: 10,
    fontFamily: "ClanOT-Medium",
  },
  severe: {
    border: "3px solid rgb(178, 1, 33)",
    backgroundColor: "rgba(178, 1, 33, 0.1)",
    padding: 10,
    paddingBottom: 5,
  },
  notice: {
    border: "3px solid rgb(234, 187, 93)",
    backgroundColor: "rgba(234, 187, 93, 0.1)",
    padding: 10,
    paddingBottom: 5,
  },
});

const PDFReceipt = ({ product }) => {
  const { t } = useTranslation();

  const now = new Date();

  let notificationBoxes = notificationBoxData(product, t);
  const productInfo = [
    ["Navn på avtalen:", product.productName],
    ["Avtaletype:", product.productType],
    ["Navn på strømselskap:", product.organizationName],
    ["Dato:", now.toLocaleDateString()],
  ];

  let showPrice = true;
  if (product.productType === ProductType.PURCHASE) {
    showPrice = false;
    notificationBoxes.push({
      text: t("purchaseNoPriceWarning"),
      severity: "severe",
    });
  } else if (product.priceType === PriceType.ETTERSKUDDVIS) {
    showPrice = false;
    notificationBoxes.push({
      text: t("postPeriodPricedWarning"),
      severity: "notice",
    });
  } else if (product.priceType === PriceType.FORESPORSEL) {
    showPrice = false;
    notificationBoxes.push({
      text: t("priceAtRequestWarning"),
      severity: "notice",
    });
  }

  const price = [
    ...(Boolean(
      isProductSpotprice(product) &&
        product.productType !== ProductType.PURCHASE &&
        showPrice
    )
      ? [["Påslag:", `${formatOreDec(product.finalAddonPrice)} øre/kWt`]]
      : []),
    ...(Boolean(isProductKwPrice(product) && showPrice)
      ? [["Strømpris:", `${formatOreDec(product.finalKwPrice)} øre/kWt`]]
      : []),
    ...(Boolean(product.productType === ProductType.PLUS &&
                      product.priceType === PriceType.SPOT)
        ? [["Påslag / Avslag (salg):", `${formatOreDec(product.currentPrice.purchaseAddonPrice)} øre/kWt`]]
        : []),
    ...(Boolean(product.productType === ProductType.PLUS &&
        product.priceType === PriceType.KW)
        ? [["Fastpris per kWt (salg):", `${formatOreDec(product.currentPrice.purchaseKwPrice)} øre/kWt`]]
        : []),
    ...(showPrice
      ? [["Månedlig kostnad:", `${formatMoneyDec(product.finalMonthlyFee)} kr`]]
      : []),
    ...(product.agreementTime
      ? [
          [
            "Bindingstid:",
            formatPeriod(product.agreementTime, product.agreementTimeUnits),
          ],
        ]
      : []),
    ...(product.productType !== ProductType.FIXED
      ? [
          [
            "Vilkår garanteres:",
            formatPeriod(
              product.addonPriceMinimumFixedFor,
              product.addonPriceMinimumFixedFor
            ),
          ],
        ]
      : []),
  ];

  const conditions = [
    [
      `${t("detailsCustomerGroups")}`,
      t(`detailsCustomerGroups_${product.applicableToCustomersType}`),
    ],
    [`${t("feeMandatory")}`, t(getFeeMandatory(product))],
    ...(Boolean(product.maxKWYear && product.maxKWYear > 0)
      ? [[t("maxKWYear"), `${toSpacedNumber(product.maxKWYear)} kWt`]]
      : []),
    [
      t("billingFrequency"),
      formatPeriodFrequency(
        product.billingFrequency,
        product.billingFrequencyUnits
      ),
    ],
    ...(Boolean(product.finalFeeMail !== null && product.finalFeeMail > 0)
      ? [[`${t("feeMail")}`, `${formatMoneyDec(product.finalFeeMail, false)} kr`]]
      : []),
    [
      `${t("standardAlert")}`,
      product.standardAlert
        ? t(`standardAlert_${product.standardAlert}`)
        : t("undisclosed"),
    ],
    ...(Boolean(product.otherConditions)
      ? [[`${t("otherConditions")}`, product.otherConditions]]
      : []),
  ];

  // Image fungerer ikke med pdfkit i resolutions (som er nødvendig for å få det til å kjøre).
  // Fjerner bilde enn så lenge.

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.logoContainer}>
            <Text style={styles.header}>
              Bevis på strømavtalen du valgte på strømpris.no
            </Text>
            {/*<Image style={styles.logo} src={logo}></Image>*/}
          </View>
        </View>

        <View style={styles.section}>
          {productInfo.map((p, index) => (
            <View style={styles.headerContainer} key={index}>
              <Text style={styles.leftColumn}>{p[0]}</Text>
              <Text style={styles.rightColumn}>{p[1]}</Text>
            </View>
          ))}
        </View>

        <View style={styles.section}>
          {price.map((p, index) => (
            <View style={styles.headerContainer} key={index}>
              <Text style={styles.leftColumn}>{p[0]}</Text>
              <Text style={styles.rightColumn}>{p[1]}</Text>
            </View>
          ))}
        </View>

        <View style={styles.section}>
          {conditions.map((p, index) => (
            <View style={styles.headerContainer} key={index}>
              <Text style={styles.leftColumn}>{p[0]}</Text>
              <Text style={styles.rightColumn}>{p[1]}</Text>
            </View>
          ))}
        </View>

        <View style={styles.section}>
          {notificationBoxes.map((el, index) => (
            <View style={styles.notificationBox}>
              <Text style={styles[el.severity]} key={index}>
                {el.text}
              </Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default PDFReceipt;
