import { faArrowDown, faArrowUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Paragraph } from "components/Typography/Paragraph/Paragraph";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

interface IExpand {
  expand: () => void;
  isExpanded: boolean;
}

const ExpandContentButton = ({ expand, isExpanded }: IExpand) => {
  const { t } = useTranslation();
  return (
    <button
      onClick={() => expand()}
      className={styles.expandContentButton}
      tabIndex={0}
    >
      <Paragraph size={16}>{isExpanded ? t("close") : t("open")}</Paragraph>
      <FontAwesomeIcon
        icon={!isExpanded ? faArrowDown : faArrowUp}
        className={styles.arrowIcon}
        style={{ verticalAlign: "middle" }}
        transform={"grow-4"}
        color="#333333"
      />
    </button>
  );
};

export default ExpandContentButton;
