import GlossaryTerm from "./GlossaryTerm";
import { useState } from "react";
import { terms } from "data/terms.js";
import { RouteWrapper } from "styles/General";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useEffect } from "react";
import { FlexLayout } from "components/Layouts/FlexLayout";
import { Paragraph } from "components/Typography/Paragraph/Paragraph";
import styles from "./styles.module.scss";
import NoStyleButton from "components/Button/NoStyleButton";

const Glossary = () => {
  const { trackPageView } = useMatomo();
  useEffect(() => {
    trackPageView({});
  }, [trackPageView]);

  const [data] = useState(terms);

  const scrollToTerm = (id: string) => {
    const el = document.getElementById(id);
    if (el) {
      el?.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <RouteWrapper>
      <FlexLayout
        horizontalAlign="center"
        verticalAlign="center"
        customStyle={{ marginTop: "1.8rem", maxWidth: "1100px" }}
      >
        <Paragraph news size={32} asHeading>
          Ordliste
        </Paragraph>
        <Paragraph textAlign="center" size={18} customStyle={{maxWidth: "700px"}}>
          Her gir vi deg forklaringen på ord og uttrykk du møter i markedsføring
          og salg av strøm, fra A til Å.
        </Paragraph>
        <FlexLayout>
          <FlexLayout
            horizontalAlign="center"
            verticalAlign="center"
            customStyle={{ marginTop: "3rem", marginBottom: "1rem" }}
          >
            <Paragraph type="h2">Hele ordlisten</Paragraph>
          </FlexLayout>
          <div className={styles.allWordsGrid}>
            {data.map((word: any) => {
              return (
                <NoStyleButton
                  onClick={() => scrollToTerm(word.term)}
                  noPadding
                >
                  <Paragraph asLink color="var(--primary-link)" size={14}>
                    {word.term}
                  </Paragraph>
                </NoStyleButton>
              );
            })}
          </div>
        </FlexLayout>
        <FlexLayout customStyle={{ marginTop: "3rem" }} gap={8}>
          {data.map((x, index) => (
            <GlossaryTerm
              term={x.term}
              explanation={x.description}
              key={index}
              id={x.term}
            />
          ))}
        </FlexLayout>
      </FlexLayout>
    </RouteWrapper>
  );
};

export default Glossary;
