import { faMinus, faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  closed: boolean;
}

export const ExpandIcon = ({ closed }: Props) => {
  return (
    <FontAwesomeIcon
      icon={closed ? faPlus : faMinus}
      color="#333"
      font-size="1.3rem"
    />
  );
};
