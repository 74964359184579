export function isLocal() {
  return !process.env.NODE_ENV || process.env.NODE_ENV === "development";
}

export function getProductMsApiHost(): string {
  return getApiHost("strom-product-ms");
}

export function getSpotpriceMsApiHost(): string {
  return getApiHost("strom-spotprice-ms");
}

export const getSpotpriceApiToken = () => {
  const now = new Date();
  const apiToken = btoa(String(Math.pow(now.getTime() / 1000, 2)));
  return encodeURIComponent(apiToken);
}

function getApiHost(service: string) {
  if (isLocal()) {
    if (process.env.REACT_APP_LOCAL_API === 'true') {
      // for local development we can choose to use local api (which is exposed on separate ports)
      return `http://localhost:${getLocalApiPort(service)}/${service}`;
    } else {
      return `https://teststrom.no/${service}`;
    }
  } else {
    return `https://${window.location.hostname}/${service}`;
  }
}

function getLocalApiPort(service: string) {
  if (service === 'strom-spotprice-ms') {
    return 8280;
  }
  return 8080;
}
